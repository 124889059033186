
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter, Offcanvas } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, FileMedicalFill, Coin, Tree, Globe2, GeoAlt,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, HeartFill, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, Circle, List, CaretLeftFill } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NationalScienceFoundation from './NationalScienceFoundation';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import Outbreaks from './Outbreaks';
import TravelNotices from './TravelNotices';
import StatesModal from './StatesModal';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnOversightAndReform from './HouseCommitteeOnOversightAndReform';
import SecuritiesAndExchangeCommissionSpeechesAndStatments from './SecuritiesAndExchangeCommissionSpeechesAndStatments';
import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import HouseCommitteeOnArmedServices from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnTheBudget from './HouseCommitteeOnTheBudget';
import HouseCommitteeOnEducationAndLabor from './HouseCommitteeOnEducationAndLabor';
import HouseFloor from './HouseFloor';
import FoodRecalls from './FoodRecalls';
import FoodSafety from './FoodSafety';
import HouseCommitteeOnEnergyAndCommerce from './HouseCommitteeOnEnergyAndCommerce';
import HouseCommitteeOnEthics from './HouseCommitteeOnEthics';
import CongressionalHearings from './CongressionalHearings';
import HouseCommitteeOnFinacialServices from './HouseCommitteeOnFinacialServices';
import HouseCommitteeOnForeignAffairs from './HouseCommitteeOnForeignAffairs';
import HouseCommitteeOnHomelandSecurity from './HouseCommitteeOnHomelandSecurity';
import HouseCommitteeOnTheJudiciary from './HouseCommitteeOnTheJudiciary';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import HouseCommitteeOnHouseAdministration from './HouseCommitteeOnHouseAdministration';
import CongressionalBudgetOffice from './CongressionalBudgetOffice';
import PresidentialProclamations from './PresidentialProclamations';
import ExecutiveOrders from './ExecutiveOrders';
import SecuritiesAndExchangeCommissionPressReleases from './SecuritiesAndExchangeCommissionPressReleases';
import CDCNewsroom from './CDCNewsroom';
import HouseCommitteeOnIntelligence from './HouseCommitteeOnIntelligence';
import UNTopStories from './UNTopStories';

import
 {supremeCourtSearch, whiteHouseSearch, billFeedModal, 
  createHouseFloorToday, createSenateFloorToday, createTrendingBills,
  createNationalAeronauticsAndSpaceAdministration, 
  createEnvironmentalProtectionAgency, createDepartmentOfHomelandSecurity,
  createDepartmentOfVeteransAffairs, createDepartmentOfEducation,
  createDepartmentOfHousingAndUrbanDevelopment, 
  createDepartmentOfHealthAndHumanServices, createDepartmentOfLabor
, createDepartmentOfCommerce, createDepartmentOfAgriculture,
createDepartmentOfTheInterior, createDepartmentOfJustice,
createDepartmentOfTransportation, createDepartmentOfDefense,
createDepartmentOfTreasury, createDepartmentOfEnergy,
createDepartmentOfState, createBillsEnrolledMobile,
createBillsEnrolled, createNewLaws, createGAOReports,
createExecutiveOrders, createPresidentialProclamations, createCongressionalHearings,
createCongressionalBudgetOfficeMobile, createCongressionalBudgetOffice,
createHouseCommitteeOversightAndReform,
createHouseCommitteeOnNaturalResources,
createHouseCommitteeOnTheJudiciary,
createHouseCommitteeOnHouseAdministration,
createHouseCommitteeOnHomelandSecurity,
createHouseCommitteeOnForeignAffairs,
createHouseCommitteeOnFinancialServices,
createHouseCommitteeOnEthics,
createHouseCommitteeOnEnergyAndCommerce,
createHouseCommitteeOnEducationAndLabor,
createHouseCommitteeOnTheBudget,
createHouseCommitteeOnArmedServices,
createHouseCommitteeOnAppropriations,
createHouseCommitteeOnAgriculture,
createReports,
createBillFeedTwoMobile,
createBillFeedTwo,
createBillFeed, createWhiteHouseFeedMobile
, createWhiteHouseFeed, createWhiteHouseDocuments, 
createBillsPresentedFeed, createCourtAppealsMobile,
createCourtAppeals, createUSCourtNewsFeedMobile,
createUSCourtNewsFeed, saveToCollection,
createFullBillFeed, ListItem, Speak, feedText, variableTest, myMethod, myMethod2, createEconomicIndicators, 
createSecuritiesAndExchangeCommissionPressReleases, createNationWeatherServices, createCDCNewsroom,
createSecuritiesAndExchangeCommissionSpeechesAndStatements, createNationalScienceFoundation, createHouseCommitteeOnIntelligence,
 createUNTopStories, createCDCOutbreaks, createCDCTravelNotices, createFoodRecalls, createFoodSafety, createAlabamaBills, createAlaskaBills} from './Functions'
import HomeMobile from './HomeMobile';

import EconomicIndicators from './EconomicIndicators';
import Alaska from './AlaskaBills'
import AlaskaHomeMobile from './AlaskaHomeMobile';









var login = 0
var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;









  







var response = '';

function feedText2()
{
 
    var billTitle = [];
    var billTitle2 = [];
    var billTitle3 = [];
    var secTitle = [];
    var doCTitle = [];
    var weatherTitle = [];
    var epaTitle = [];
    var doSTitle = [];
    var unTitle = [];
    var cdcTitle = [];
    var doHAndHTitle = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
      /*Three Branches */
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/bills.xml');
      let feed2 = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.whitehouse.gov/feed/');
      let feed3 = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.uscourts.gov/news/rss');
      /*Economy */
      let sec = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.sec.gov/news/pressreleases.rss');
      let departmentOfCommerce = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.commerce.gov/feeds/news');
      /*Environment */
      let weather = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.weather.gov/rss_page.php?site_name=nws');
      let epa = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/environment-xml/epa.xml');
       /*Foreign Affairs */
       let doS = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.state.gov/rss-feed/press-releases/feed/');
       let un = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://news.un.org/feed/subscribe/en/news/all/rss.xml');
       /*Health */
       let cdc = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://tools.cdc.gov/podcasts/feed.asp?feedid=183');
       let doHandH = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.hhs.gov/rss/news.xml');
       feed.items.forEach(item => {
  
  
  
        
        billTitle.push(item.title.replace(/\&nbsp;/g, ''));
        
        
       
        
  
        
  
  
  
  
  
      });

      feed2.items.forEach(item => {
  
  
  
        
        billTitle2.push(item.title.replace(/\&nbsp;/g, ''));
        
        
       
        
  
        
  
  
  
  
  
      });

      feed3.items.forEach(item => {
  
  
  
        
        billTitle3.push(item.title.replace(/\&nbsp;/g, ''));
        
        
       
        
  
        
  
  
  
  
  
      });

      sec.items.forEach(item => {
  
        secTitle.push(item.title.replace(/\&nbsp;/g, ''));
      });

      departmentOfCommerce.items.forEach(item => {
  
        doCTitle.push(item.title.replace(/\&nbsp;/g, ''));
        
      });

      weather.items.forEach(item => {
  
        weatherTitle.push(item.title.replace(/\&nbsp;/g, ''));
      });

      epa.items.forEach(item => {
  
        epaTitle.push(item.title.replace(/\&nbsp;/g, ''));
      });

      doS.items.forEach(item => {
  
        doSTitle.push(item.title.replace(/\&nbsp;/g, ''));
      });

      un.items.forEach(item => {
  
        unTitle.push(item.title.replace(/\&nbsp;/g, ''));
      });
      cdc.items.forEach(item => {
  
        cdcTitle.push(item.title.replace(/\&nbsp;/g, ''));
      });
      doHandH.items.forEach(item => {
  
        doHAndHTitle.push(item.title.replace(/\&nbsp;/g, ''));
      });
      
  
  
      response = ("Today's recent Government updates. Congress introduced the bill, " + billTitle[0] + 
      ". The white house released the statement: " + billTitle2[0] + "... and US Courts published, " + billTitle3[0] +
      "..., Economy, S.E.C...., " + secTitle[0] + ', Department of Commerce..., ' + doSTitle[0] 
      + ",...Environment..., National Weather Service Update..., " + weatherTitle[0] + '..., EPA..., ' + epaTitle[0]
     + "..., Foreign Affairs...., Department of State...., " + doSTitle[0] + "..., United Nations....," + unTitle[0]
     + "...., Health...., CDC...., " + cdcTitle[0] + "...., Department of Health and Human Services...., " + doHAndHTitle[0])
      
  
  
  
  

    })();
  
 
  }

  class PlayUpdateButtonMobile extends React.Component { 
    constructor(props) {
      super(props);
      this.state = {
        isCardView: false,
      }
    } 
    
    render() {
      return (
        <Button  id='navbarUpdateButtonMobile' size='lg'  onClick={()=>{this.setState({ isCardView: !this.state.isCardView }); Speak(response);  }}  >
          { this.state.isCardView                                                   
            ? <PauseCircle  />
            : <PlayCircle   />
          }
          &nbsp;&nbsp;Update (Beta)
        </Button>
      );
    }
    
  }


 
 


  //Play Update Button Change Icon code
class PlayUpdateButton extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      isCardView: false,
    }
  } 
  
  render() {
    return (
      <Button id='navbarCollectionButtonDesktop' size={10}  onClick={()=>{this.setState({ isCardView: !this.state.isCardView }); Speak(response);  }}  >
        { this.state.isCardView                                                   
          ? <PauseCircle  />
          : <PlayCircle />
        }
        &nbsp;&nbsp;Update (Beta)
      </Button>
    );
  }
  
}
export const MobileBackButton2 = () =>   {
  render()
  feedText2()
 
  {
    const [offcanvasIsOpen, setOffcanvasIsOpen] = useState(false)
  
  const [show, setShow] = useState(true);
  
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fullscreen, setFullScreen] = useState(true);

  
  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)


  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)


  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
  const [courtOpinionsModalIsOpen, setCourtOpinionsModalIsOpen] = useState(false)
  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
  const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
  const [secSAndSModalIsOpen, setSECSAndSModalIsOpen] = useState(false)
  const [economicIndicatorsModalIsOpen, setEconomicIndicatorsModalIsOpen] = useState(false)
  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [trendingBillsOptionOne, setTrendingBillsOptionOneModalIsOpen ] = useState(false)
  const [trendingBillsOptionTwo, setTrendingBillsOptionTwoModalIsOpen ] = useState(false)
  const [trendingBillsOptionThree, setTrendingBillsOptionThreeModalIsOpen ] = useState(false)
  const [trendingBillsOptionFour, setTrendingBillsOptionFourModalIsOpen ] = useState(false)
  const [trendingBillsOptionFive, setTrendingBillsOptionFiveModalIsOpen ] = useState(false)
  const [trendingBillsOptionSix, setTrendingBillsOptionSixModalIsOpen ] = useState(false)
  const [trendingBillsOptionSeven, setTrendingBillsOptionSevenModalIsOpen ] = useState(false)
  const [trendingBillsOptionEight, setTrendingBillsOptionEightModalIsOpen ] = useState(false)
  const [trendingBillsOptionNine, setTrendingBillsOptionNineModalIsOpen ] = useState(false)
  const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
  const [trendingBillsOptionEleven, setTrendingBillsOptionElevenModalIsOpen ] = useState(false)
  const [trendingBillsOptionTweleve, setTrendingBillsOptionTweleveModalIsOpen ] = useState(false)
  const [trendingBillsOptionThirteen, setTrendingBillsOptionThirteenModalIsOpen ] = useState(false)
  const [trendingBillsOptionFourteen, setTrendingBillsOptionFourteenModalIsOpen ] = useState(false)
  const [trendingBillsOptionFifteen, setTrendingBillsOptionFifteenModalIsOpen ] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
    const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
    const [houseCommitteeOnAppropriationsModalIsOpen, setHouseCommitteeOnAppropriationsModalIsOpen] = useState(false)
    const [houseCommitteeOnArmedServicesModalIsOpen, setHouseCommitteeOnOnArmedServicesModalIsOpen] = useState(false)
    const [houseCommitteeOnTheBudgetModalIsOpen, setHouseCommitteeOnTheBudgetModalIsOpen] = useState(false)
    const [houseCommitteeOnEnergyAndCommerceModalIsOpen, setHouseCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
    const [houseCommitteeOnEducationAndLaborModalIsOpen, setHouseCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
    const [houseCommitteeOnEthicsModalIsOpen, setHouseCommitteeOnEthicsModalIsOpen] = useState(false)
    const [houseCommitteeOnFinancialServicesModalIsOpen, setHouseCommitteeOnFinancialServicesModalIsOpen] = useState(false)
    const [houseCommitteeOnForeignAffairsModalIsOpen, setHouseCommitteeOnForeignAffairsModalIsOpen] = useState(false)
    const [houseCommitteeOnHomelandSecurityModalIsOpen, setHouseCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
    const [houseCommitteeOnHouseAdministrationModalIsOpen, setHouseCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
    const [houseCommitteeOnTheJudiciaryModalIsOpen, setHouseCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
    const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
    const [houseCommitteeOnOversightAndReformModalIsOpen, setHouseCommitteeOnOversightAndReformModalIsOpen] = useState(false)
    const [congressionalBudgetOfficeModalIsOpen, setCongressionalBudgetOfficeModalIsOpen] = useState(false)
    const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
    const [cdcNewsroomModalIsOpen, setCDCNewsroomModalIsOpen] = useState(false)
    const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
    const [unTopStoriesModalIsOpen, setUNTopStoriesModalIsOpen] = useState(false)
    const [houseCommitteeOnIntelligenceModalIsOpen, setHouseCommitteeOnIntelligenceModalIsOpen] = useState(false)
    const [travelNoticesModalIsOpen, setTravelNoticesModalIsOpen] = useState(false)
  
    const [foodRecallsModalIsOpen, setFoodRecallsModalIsOpen] = useState(false)
    const [foodSafetyModalIsOpen, setFoodSafetyModalIsOpen] = useState(false)

    const [outbreaksModalIsOpen, setOutbreaksModalIsOpen] = useState(false)
    const [congressionalHearingsModalIsOpen, setCongressionalHearingsModalIsOpen] = useState(false)

    const [houseFloorModalIsOpen, setHouseFloorModalIsOpen] = useState(false)



  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [donateModalIsOpen, setDonateModalIsOpen] = useState(false)
  const [secPressModalIsOpen, setSECPressModalIsOpen] = useState(false)


  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsnModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
  const [alertModalIsOpen, setAlertModalIsOpen] = useState(true)

  const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
  function checkLogin()
{
  if (login == 0)
  {
    setAboutModalIsOpen(true)
    login++;
  }

  else
  setAboutModalIsOpen(false)
}


var branch = "all";









  
  
  

{
  


  return (
    
    <>
    
    <Button id='backButtonMobile'  onClick={(e) => {
              e.preventDefault();
       
              window.history.back()
            }}><CaretLeftFill id='offCanvasIcon' size={30} ></CaretLeftFill></Button>
    
  



       
    </>

     
    
  );





                    }
                  }
                }




export default MobileBackButton2;
