import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

import BatchFeedsMobile from './BatchFeedsMobile';
import { CreateFeedAPI, CreateFeedAPICommittee, CreateFeedAPIDepartmentUS, CreateFeedAPIOpinions, CreateFeedAPIRecentCache } from './Functions';
import GAOReports from './GAOReports';
import GenerateFeed from './GenerateFeed';

import NewCarousels from './NewCarousels';
import HouseCommitteeOnFinacialServices from './HouseCommitteeOnFinacialServices';
import GenerateFeedCommittee from './GenerateFeedCommittee';
import DepartmentOfTreasuryDocs from './DepartmentOfTreasuryDocs';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import DepartmentOfCommerceDocuments from './DepartmentOfCommerceDocuments';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import GenerateFeedDepartment from './GenerateFeedDepartment';
import DepartmentOfLaborDocs from './DepartmentOfLaborDocs';
import DepartmentOfLabor from './DepartmentOfLabor';
import { ChevronRight, House, List, Pen, People } from 'react-bootstrap-icons';
import NavbarSearchButton from './TestNavBar';
import MobileTopNavbar from './TestNavbarComponent';
import HomelandSecurityDocuments from './HomelandSecurityDocuments';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import GenerateFeedAudio from './GenerateFeedAudio';
import GenerateFeedOpinions from './GenerateFeedOpinions';
import FullUSCourtNews from './FullUSCourtNews';
import CourtAppealsSecondCircuit from './CourtAppealsSecondCircuit';
import Outbreaks from './Outbreaks';
import DepartmentOfHousingAndUrbanDevelopmentDocs from './DepartmentOfHousingAndUrbanDevelopmentDocs';
import DepartmentOfEnergyDocs from './DepartmentOfEnergyDocs';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const TestBatch = () => {
  // Define the feeds array here directly in the page


const executiveFeeds = [
  {
      id: 'whiteHouseBriefing',
      name: 'White House Briefing Room',
      apiParams: '&skip=0&schema=united_states_of_america&table=the_white_house&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'White House Briefing Room',
      buttonName: '',
      feedBranch: 'Executive',
      feedTopic: 'N/A',
      displayType: 'carousel',
      group: 'executive',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Executive news',
      groupLink: '/executive',
      elementId: 'wh',
  },
  {
      id: 'presidentialActions',
      name: 'Presidential Actions',
      apiParams: '&skip=0&schema=united_states_of_america&table=the_white_house&order_by=created_at&filter_column=category&filter_string=Presidential%20Actions',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'Presidential Actions',
      buttonName: 'Document',
      feedBranch: 'Executive',
      feedTopic: 'N/A',
      displayType: 'carousel',
      group: 'executive',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Executive news',
      groupLink: '/executive',
      elementId: 'pa',
  },
  {
      id: 'presidentialDocuments',
      name: 'Presidential Documents',
      apiParams: '&skip=0&schema=united_states_of_america&table=compilation_of_presidential_documents&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'Presidential Documents',
      buttonName: 'Document',
      feedBranch: 'Executive',
      feedTopic: 'N/A',
      displayType: 'carousel',
      group: 'executive',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Executive news',
      groupLink: '/executive',
      elementId: 'whdocs',
  },
  {
      id: 'executiveOrders',
      name: 'Executive Orders',
      apiParams: '&skip=0&schema=united_states_of_america&table=president_executive_orders&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'Executive Orders',
      buttonName: '',
      feedBranch: 'Executive',
      feedTopic: 'N/A',
      displayType: 'carousel',
      group: 'executive',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Executive news',
      groupLink: '/executive',
      elementId: 'executiveOrders',
  },
  {
      id: 'presidentialProclamations',
      name: 'Presidential Proclamations',
      apiParams: '&skip=0&schema=united_states_of_america&table=presidential_proclamation&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'Presidential Proclamations',
      buttonName: 'Document',
      feedBranch: 'Executive',
      feedTopic: 'N/A',
      displayType: 'carousel',
      group: 'executive',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Executive news',
      groupLink: '/executive',
      elementId: 'presidentialProclomations2',
  }
];

const judicialFeeds = [
  {
      id: 'supremeCourtOpinions',
      name: 'Supreme Court Opinions',
      apiParams: '&skip=0&schema=united_states_of_america&table=supreme_court_opinions&order_by=created_at',
      apiFunction: CreateFeedAPIOpinions,
      generateFunction: GenerateFeedOpinions,
      modalTitle: 'Supreme Court Opinions',
      buttonName: 'Doc',
      feedBranch: 'Judicial',
      feedTopic: 'N/A',
      displayType: 'carousel',
      group: 'judicial',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Judicial news',
      groupLink: '/judicial',
      elementId: 'courtOpinions',
  },
  {
      id: 'supremeCourtArguments',
      name: 'Supreme Court Arguments',
      apiParams: '&skip=0&schema=united_states_of_america&table=supreme_court_arguments&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeedAudio,
      modalTitle: 'Supreme Court Arguments',
      buttonName: 'Document',
      feedBranch: 'Judicial',
      feedTopic: 'N/A',
      displayType: 'carousel',
      group: 'judicial',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Judicial news',
      groupLink: '/judicial',
      elementId: 'courtArguments',
  },
  {
      id: 'usCourtNews',
      name: 'U.S. Court News',
      apiParams: '&skip=0&schema=united_states_of_america&table=uscourts&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: FullUSCourtNews,
      modalTitle: 'U.S. Court News',
      buttonName: 'Docs',
      feedBranch: 'Judicial',
      feedTopic: 'N/A',
      displayType: 'carousel',
      group: 'judicial',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Judicial news',
      groupLink: '/judicial',
      elementId: 'courtNews',
  },
  {
      id: 'usCourtOfAppeals',
      name: 'U.S. Court of Appeals',
      apiParams: '&skip=0&schema=united_states_of_america&table=court_of_appeals&order_by=created_at&filter_column=collection_name&filter_string=Court%20of%20Appeals%20for%20the%20Thirteenth%20Circuit',
      apiFunction: CreateFeedAPI,
      generateFunction: CourtAppealsSecondCircuit,
      modalTitle: 'U.S. Court of Appeals',
      buttonName: '',
      feedBranch: 'Judicial',
      feedTopic: 'N/A',
      displayType: 'carousel',
      group: 'judicial',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Judicial news',
      groupLink: '/judicial',
      elementId: 'courtAppeals',
  }
];


const defenseFeeds = [
  {
      id: 'defenseDepartmentNews',
      name: 'Department of Defense News',
      apiParams: '&skip=0&schema=united_states_of_america&table=defense&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'Department of Defense News',
      buttonName: '',
      feedBranch: 'Executive',
      feedTopic: 'Defense',
      displayType: 'carousel',
      group: 'defense',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Defense news',
      groupLink: '/defense',
      elementId: 'defenseDepartment2',
  },
  {
      id: 'defenseDepartmentDocuments',
      name: 'Department of Defense Documents',
      apiParams: '&skip=0&schema=united_states_of_america&table=defense&order_by=created_at&filter_column=agency&filter_string=Defense%20Department',
      apiFunction: CreateFeedAPI,
      generateFunction: FederalRegisterDefenseDepartment,
      modalTitle: 'Department of Defense Documents',
      buttonName: 'Document',
      feedBranch: 'Executive',
      feedTopic: 'Foreign Affairs',
      displayType: 'carousel',
      group: 'defense',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Defense news',
      groupLink: '/defense',
      elementId: 'frDefense',
  },
  {
      id: 'homelandSecurityDocuments',
      name: 'Department of Homeland Security Documents',
      apiParams: '&skip=0&schema=united_states_of_america&table=homeland_security&order_by=created_at&filter_column=agency&filter_string=Homeland%20Security%20Department',
      apiFunction: CreateFeedAPI,
      generateFunction: HomelandSecurityDocuments,
      modalTitle: 'Department of Homeland Security Documents',
      buttonName: 'Document',
      feedBranch: 'Executive',
      feedTopic: 'N/A',
      displayType: 'carousel',
      group: 'defense',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Defense news',
      groupLink: '/defense',
      elementId: 'homelandDocs',
  }
];

const electionsFeeds = [
  {
      id: 'federalElectionCommission',
      name: 'Federal Election Commission',
      apiParams: '&skip=0&schema=united_states_of_america&table=federal_election_commission&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'Federal Election Commission',
      buttonName: 'Doc',
      feedBranch: 'N/A',
      feedTopic: 'Elections',
      displayType: 'carousel',
      group: 'elections',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Elections news',
      groupLink: '/election',
      elementId: 'fec',
  },
  {
      id: 'electionAssistanceCommission',
      name: 'Election Assistance Commission',
      apiParams: '&skip=0&schema=united_states_of_america&table=election_assistance_commission&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'Election Assistance Commission',
      buttonName: 'Doc',
      feedBranch: 'Legislative',
      feedTopic: 'N/A',
      displayType: 'carousel',
      group: 'elections',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Elections news',
      groupLink: '/election',
      elementId: 'eac',
  }
];

const economyFeeds = [
  {
      id: 'secPressReleases',
      name: 'SEC Press Releases',
      apiParams: '&skip=0&schema=united_states_of_america&table=sec_updates&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'SEC Press Releases',
      buttonName: 'Document',
      feedBranch: 'Executive',
      feedTopic: 'Economy',
      displayType: 'carousel',
      group: 'economy',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Economy news',
      groupLink: '/economy',
      elementId: 'secPR',
  },
  {
      id: 'secSpeechesStatements',
      name: 'SEC Speeches and Statements',
      apiParams: '&skip=0&schema=united_states_of_america&table=sec_speeches_statements&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'SEC Speeches and Statements',
      buttonName: 'Document',
      feedBranch: 'Executive',
      feedTopic: 'Economy',
      displayType: 'carousel',
      group: 'economy',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Economy news',
      groupLink: '/economy',
      elementId: 'sec',
  },
  {
      id: 'departmentOfCommerce',
      name: 'Department of Commerce',
      apiParams: '&skip=0&schema=united_states_of_america&table=commerce&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: DepartmentOfCommerce,
      modalTitle: 'Department of Commerce',
      buttonName: '',
      feedBranch: 'N/A',
      feedTopic: 'Economy',
      displayType: 'carousel',
      group: 'economy',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Economy news',
      groupLink: '/economy',
      elementId: 'commerceDepartment',
  },
  {
      id: 'departmentOfTreasury',
      name: 'Department of Treasury',
      apiParams: '&skip=0&schema=united_states_of_america&table=treasury&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: DepartmentOfTreasury,
      modalTitle: 'Department of Treasury',
      buttonName: '',
      feedBranch: 'N/A',
      feedTopic: 'Economy',
      displayType: 'carousel',
      group: 'economy',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Economy news',
      groupLink: '/economy',
      elementId: 'treasuryDepartment',
  },
  {
      id: 'departmentOfLabor',
      name: 'Department of Labor',
      apiParams: '&skip=0&schema=united_states_of_america&table=labor&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: DepartmentOfLabor,
      modalTitle: 'Department of Labor',
      buttonName: '',
      feedBranch: 'N/A',
      feedTopic: 'Economy',
      displayType: 'carousel',
      group: 'economy',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Economy news',
      groupLink: '/economy',
      elementId: 'laborDepartment',
  }
];

const environmentFeeds = [
  {
      id: 'nationalWeatherServiceUpdate',
      name: 'National Weather Service Update',
      apiParams: '&skip=0&schema=united_states_of_america&table=national_weather_service_update&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'National Weather Service Update',
      buttonName: 'Document',
      feedBranch: 'N/A',
      feedTopic: 'Environment',
      displayType: 'carousel',
      group: 'environment',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Environment news',
      groupLink: '/environment',
      elementId: 'nws',
  },
  {
      id: 'epa',
      name: 'EPA',
      apiParams: '&skip=0&schema=united_states_of_america&table=epa&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'EPA',
      buttonName: 'Document',
      feedBranch: 'N/A',
      feedTopic: 'Environment',
      displayType: 'carousel',
      group: 'environment',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Environment news',
      groupLink: '/environment',
      elementId: 'epa',
  },
  // {
  //     id: 'nationalScienceFoundation',
  //     name: 'National Science Foundation',
  //     apiParams: '&skip=0&schema=united_states_of_america&table=national_science_foundation&order_by=created_at',
  //     apiFunction: CreateFeedAPI,
  //     generateFunction: GenerateFeed,
  //     modalTitle: 'National Science Foundation',
  //     buttonName: 'Document',
  //     feedBranch: 'Executive',
  //     feedTopic: 'Science & Tech',
  //     displayType: 'carousel',
  //     group: 'environment',
  //     groupDisplayName: '',
  //     showGroupButton: true,
  //     groupButtonText: 'View all Environment news',
  //     groupLink: '/environment',
  //     elementId: 'nsf',
  // },
  {
      id: 'departmentOfAgriculture',
      name: 'Department of Agriculture',
      apiParams: '&skip=0&schema=united_states_of_america&table=environment&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeedDepartment,
      modalTitle: 'Department of Agriculture',
      buttonName: '',
      feedBranch: 'Executive',
      feedTopic: 'Environment',
      displayType: 'carousel',
      group: 'environment',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Environment news',
      groupLink: '/environment',
      elementId: 'agricultureDepartment',
  },
  {
      id: 'departmentOfInteriorNews',
      name: 'Department of the Interior News',
      apiParams: '&skip=0&schema=united_states_of_america&table=department_of_interior_news&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'Department of the Interior News',
      buttonName: 'Document',
      feedBranch: 'Executive',
      feedTopic: 'Environment',
      displayType: 'carousel',
      group: 'environment',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Environment news',
      groupLink: '/environment',
      elementId: 'interiorNews',
  },
  // {
  //     id: 'departmentOfHousingAndUrbanDevelopment',
  //     name: 'Department of Housing and Urban Development',
  //     apiParams: '&skip=0&schema=united_states_of_america&table=housing_and_human_services&order_by=created_at',
  //     apiFunction: CreateFeedAPI,
  //     generateFunction: DepartmentOfHousingAndUrbanDevelopment,
  //     modalTitle: 'Department of Housing and Urban Development',
  //     buttonName: 'Document',
  //     feedBranch: 'Executive',
  //     feedTopic: 'Environment',
  //     displayType: 'carousel',
  //     group: 'environment',
  //     groupDisplayName: '',
  //     showGroupButton: true,
  //     groupButtonText: 'View all Environment news',
  //     groupLink: '/environment',
  //     elementId: 'housingAndUrbanDevelopmentDepartment',
  // }
];

const foreignAffairsFeeds = [
  {
      id: 'departmentOfState',
      name: 'Department of State',
      apiParams: '&skip=0&schema=united_states_of_america&table=state&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'Department of State',
      buttonName: '',
      feedBranch: 'Executive',
      feedTopic: 'N/A',
      displayType: 'carousel',
      group: 'foreign_affairs',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Foreign Affairs news',
      groupLink: '/foreignaffairs',
      elementId: 'stateDepartment',
  },
  {
      id: 'departmentOfDefense',
      name: 'Department of Defense',
      apiParams: '&skip=0&schema=united_states_of_america&table=defense&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'Department of Defense',
      buttonName: '',
      feedBranch: 'Executive',
      feedTopic: 'Foreign Affairs',
      displayType: 'carousel',
      group: 'foreign_affairs',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Foreign Affairs news',
      groupLink: '/foreignaffairs',
      elementId: 'defenseDepartment',
  },
  {
      id: 'houseCommitteeOnArmedServices',
      name: 'House Committee on Armed Services',
      apiParams: '&skip=0&schema=united_states_of_america&table=committee_on_armed_services_meeting_feed&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'House Committee on Armed Services',
      buttonName: 'Document',
      feedBranch: 'Legislative',
      feedTopic: 'N/A',
      displayType: 'carousel',
      group: 'foreign_affairs',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Foreign Affairs news',
      groupLink: '/foreignaffairs',
      elementId: 'houseComitteeOnArmedServices',
  }
];

const healthFeeds = [
  {
      id: 'healthAndHumanServices',
      name: 'Department of Health and Human Services',
      apiParams: '&skip=0&schema=united_states_of_america&table=health_and_human_services&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'Health and Human Services News',
      buttonName: 'Document',
      feedBranch: 'Executive',
      feedTopic: 'Health',
      displayType: 'carousel',
      group: 'health',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Health news',
      groupLink: '/health',
      elementId: 'healthAndHumanServicesDepartment',
  },
  {
      id: 'cdcNewsroom',
      name: 'CDC Newsroom',
      apiParams: '&skip=0&schema=united_states_of_america&table=cdc_newsroom&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'CDC Newsroom',
      buttonName: 'Document',
      feedBranch: 'Executive',
      feedTopic: 'Health',
      displayType: 'carousel',
      group: 'health',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Health news',
      groupLink: '/health',
      elementId: 'cdcNewsroom',
  },
  {
      id: 'cdcDocuments',
      name: 'CDC Documents',
      apiParams: '&table_name=health_and_human_services&agency=Centers%20for%20Disease%20Control%20and%20Prevention',
      apiFunction: CreateFeedAPIDepartmentUS,
      generateFunction: Outbreaks,
      modalTitle: 'Centers for Disease Control and Prevention Documents',
      buttonName: 'Document',
      feedBranch: 'Executive',
      feedTopic: 'Health',
      displayType: 'carousel',
      group: 'health',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Health news',
      groupLink: '/health',
      elementId: 'travelNotices',
  },
  {
      id: 'foodRecalls',
      name: 'Food Recalls',
      apiParams: '&skip=0&schema=united_states_of_america&table=food_recalls&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'Food Recalls',
      buttonName: 'Document',
      feedBranch: 'Executive',
      feedTopic: 'Health',
      displayType: 'carousel',
      group: 'health',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Health news',
      groupLink: '/health',
      elementId: 'foodRecalls',
  }
];

const infrastructureFeeds = [
  {
      id: 'housingAndUrbanDevelopmentNews',
      name: 'Department of Housing and Urban Development News',
      apiParams: '&skip=0&schema=united_states_of_america&table=housing_and_urban_development&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: DepartmentOfHousingAndUrbanDevelopment,
      modalTitle: 'Department of Housing and Urban Development News',
      buttonName: 'Document',
      feedBranch: 'Executive',
      feedTopic: 'Infrastructure',
      displayType: 'carousel',
      group: 'infrastructure',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Infrastructure news',
      groupLink: '/infrastructure',
      elementId: 'housingAndUrbanDevelopmentDepartment2',
  },
  {
      id: 'housingAndUrbanDevelopmentDocuments',
      name: 'Department of Housing and Urban Development Documents',
      apiParams: '&skip=0&schema=united_states_of_america&table=housing_and_urban_development&order_by=created_at&filter_column=collection_name&filter_string=Department%20of%20Housing%20And%20Urban%20Development%20Documents',
      apiFunction: CreateFeedAPI,
      generateFunction: DepartmentOfHousingAndUrbanDevelopmentDocs,
      modalTitle: 'Department of Housing and Urban Development Documents',
      buttonName: 'Document',
      feedBranch: 'Executive',
      feedTopic: 'Infrastructure',
      displayType: 'carousel',
      group: 'infrastructure',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Infrastructure news',
      groupLink: '/infrastructure',
      elementId: 'housingDocs',
  },
  {
      id: 'departmentOfEnergyDocuments',
      name: 'Department of Energy Documents',
      apiParams: '&skip=0&schema=united_states_of_america&table=energy&order_by=created_at&filter_column=agency&filter_string=Energy%20Department',
      apiFunction: CreateFeedAPI,
      generateFunction: DepartmentOfEnergyDocs,
      modalTitle: 'Department of Energy Documents',
      buttonName: 'Document',
      feedBranch: 'Executive',
      feedTopic: 'N/A',
      displayType: 'carousel',
      group: 'infrastructure',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Infrastructure news',
      groupLink: '/infrastructure',
      elementId: 'energyDepartment',
  },
  {
      id: 'departmentOfTransportationDocuments',
      name: 'Department of Transportation Documents',
      apiParams: '&skip=0&schema=united_states_of_america&table=transportation&order_by=created_at&filter_column=agency&filter_string=Transportation%20Department',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'Department of Transportation Documents',
      buttonName: 'Documents',
      feedBranch: 'Executive',
      feedTopic: 'N/A',
      displayType: 'carousel',
      group: 'infrastructure',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Infrastructure news',
      groupLink: '/infrastructure',
      elementId: 'transDepartment',
  }
];


const justiceFeeds = [
  {
      id: 'departmentOfJusticeNews',
      name: 'Department of Justice News',
      apiParams: '&skip=0&schema=united_states_of_america&table=justice&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'Department of Justice News',
      buttonName: 'Document',
      feedBranch: 'Executive',
      feedTopic: 'N/A',
      displayType: 'carousel',
      group: 'justice',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Justice news',
      groupLink: '/justice',
      elementId: 'justiceNews',
  },
  {
      id: 'departmentOfJusticeDocuments',
      name: 'Department of Justice Documents',
      apiParams: '&skip=0&schema=united_states_of_america&table=justice&order_by=created_at&filter_column=agency&filter_string=Justice%20Department',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'Department of Justice Documents',
      buttonName: 'Documents',
      feedBranch: 'Executive',
      feedTopic: 'N/A',
      displayType: 'carousel',
      group: 'justice',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Justice news',
      groupLink: '/justice',
      elementId: 'justiceDocs',
  }
];


const scienceTechFeeds = [
  {
      id: 'nationalScienceFoundation',
      name: 'National Science Foundation',
      apiParams: '&skip=0&schema=united_states_of_america&table=national_science_foundation&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'National Science Foundation',
      buttonName: 'Document',
      feedBranch: 'Executive',
      feedTopic: 'Science & Tech',
      displayType: 'carousel',
      group: 'science_tech',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Science & Tech news',
      groupLink: '/science&tech',
      elementId: 'nsf2',
  },
  {
      id: 'nasa',
      name: 'NASA',
      apiParams: '&skip=0&schema=united_states_of_america&table=nasa&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'NASA',
      buttonName: 'Document',
      feedBranch: 'Executive',
      feedTopic: 'Science & Tech',
      displayType: 'carousel',
      group: 'science_tech',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Science & Tech news',
      groupLink: '/science&tech',
      elementId: 'nasa2',
  },
  {
      id: 'nasaJPLNews',
      name: 'NASA JPL News',
      apiParams: '&skip=0&schema=united_states_of_america&table=nasa_jpl&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'NASA JPL News',
      buttonName: 'Document',
      feedBranch: 'Executive',
      feedTopic: 'Science & Tech',
      displayType: 'carousel',
      group: 'science_tech',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Science & Tech news',
      groupLink: '/science&tech',
      elementId: 'nasaJPL',
  },
  {
      id: 'nistNews',
      name: 'NIST News',
      apiParams: '&skip=0&schema=united_states_of_america&table=nist&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'NIST News',
      buttonName: '',
      feedBranch: 'N/A',
      feedTopic: 'Science and Technology',
      displayType: 'carousel',
      group: 'science_tech',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Science & Tech news',
      groupLink: '/science&tech',
      elementId: 'nist',
  },
  {
      id: 'gaoScienceAndTech',
      name: 'Government Accountability Office Science and Technology',
      apiParams: '&skip=0&schema=united_states_of_america&table=goa_science_and_technology&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'Government Accountability Office Science and Technology',
      buttonName: '',
      feedBranch: 'N/A',
      feedTopic: 'Science and Technology',
      displayType: 'carousel',
      group: 'science_tech',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Science & Tech news',
      groupLink: '/science&tech',
      elementId: 'gaoScienceAndTech',
  },
  // {
  //     id: 'houseCommitteeOnScienceSpaceAndTechnology',
  //     name: 'House Committee on Science, Space, and Technology',
  //     apiParams: '&skip=0&schema=united_states_of_america&table=committee_on_science_space_and_technology_meeting_feed',
  //     apiFunction: CreateFeedAPI,
  //     generateFunction: HouseCommitteeOnScienceSpaceAndTechnology,
  //     modalTitle: 'House Committee on Science, Space, and Technology',
  //     buttonName: 'Document',
  //     feedBranch: 'N/A',
  //     feedTopic: 'Science and Technology',
  //     displayType: 'carousel',
  //     group: 'science_tech',
  //     groupDisplayName: '',
  //     showGroupButton: true,
  //     groupButtonText: 'View all Science & Tech news',
  //     groupLink: '/science&tech',
  //     elementId: 'committeeOnScienceSpaceAndTech',
  // }
];


const regulationsFeeds = [
  {
      id: 'regulationsNews',
      name: 'News',
      apiParams: '&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'Regulations News',
      buttonName: 'Document',
      feedBranch: 'N/A',
      feedTopic: 'Regulations',
      displayType: 'carousel',
      group: 'regulations',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Regulations news',
      groupLink: '/regulations',
      elementId: 'regulations',
  },
  {
    id: 'regulationsNews2',
    name: 'Proposed Rules',
    apiParams: '&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Proposed%20Rule',
    apiFunction: CreateFeedAPI,
    generateFunction: GenerateFeed,
    modalTitle: 'Regulations News',
    buttonName: 'Document',
    feedBranch: 'N/A',
    feedTopic: 'Regulations',
    displayType: 'carousel',
    group: 'regulations',
    groupDisplayName: '',
    showGroupButton: true,
    groupButtonText: 'View all Regulations news',
    groupLink: '/regulations',
    elementId: 'regulations',
},
{
  id: 'regulationsNews3',
  name: 'Supporting & Related Materials',
  apiParams: '&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Supporting%20%26%20Related%20Material',
  apiFunction: CreateFeedAPI,
  generateFunction: GenerateFeed,
  modalTitle: 'Regulations News',
  buttonName: 'Document',
  feedBranch: 'N/A',
  feedTopic: 'Regulations',
  displayType: 'carousel',
  group: 'regulations',
  groupDisplayName: '',
  showGroupButton: true,
  groupButtonText: 'View all Regulations news',
  groupLink: '/regulations',
  elementId: 'regulations',
},
{
  id: 'regulationsNews4',
  name: 'Notices',
  apiParams: '&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Notice',
  apiFunction: CreateFeedAPI,
  generateFunction: GenerateFeed,
  modalTitle: 'Regulations News',
  buttonName: 'Document',
  feedBranch: 'N/A',
  feedTopic: 'Regulations',
  displayType: 'carousel',
  group: 'regulations',
  groupDisplayName: '',
  showGroupButton: true,
  groupButtonText: 'View all Regulations news',
  groupLink: '/regulations',
  elementId: 'regulations',
}
];

const immigrationFeeds = [
  {
      id: 'iceNews',
      name: 'ICE News',
      apiParams: '&schema=united_states_of_america&table=immigration&order_by=created_at&filter_column=agency&filter_string=U.S.%20Immigration%20and%20Customs%20Enforcement',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      modalTitle: 'ICE News',
      buttonName: 'Document',
      feedBranch: 'N/A',
      feedTopic: 'Immigration',
      displayType: 'carousel',
      group: 'immigration',
      groupDisplayName: '',
      showGroupButton: true,
      groupButtonText: 'View all Immigration news',
      groupLink: '/immigration',
      elementId: 'ice',
  }
];



const recentsItems = [
  {
    id: 'recentItems0',
    name: '',
    apiParams: 'https://api.govglance.org/posts/recent/schema?limit=1&skip=0&schema=united_states_of_america',
    apiFunction: CreateFeedAPIRecentCache,
    generateFunction: GenerateFeed,
    modalTitle: 'Recent Updates',
    buttonName: 'Doc',
    feedBranch: 'N/A',
    feedTopic: 'N/A',
    displayType: 'carousel',
    group: 'recents_all',
    groupDisplayName: '',
    showGroupButton: true,
    groupButtonText: 'View all Recents',
    groupLink: '/us/recents',
    elementId: 'recent'
  },
  {
    id: 'recentItems1',
    name: '',
    apiParams: 'https://api.govglance.org/posts/recent/schema?limit=1&skip=1&schema=united_states_of_america',
    apiFunction: CreateFeedAPIRecentCache,
    generateFunction: GenerateFeed,
    modalTitle: 'Recent Updates',
    buttonName: 'Doc',
    feedBranch: 'N/A',
    feedTopic: 'N/A',
    displayType: 'carousel',
    group: 'recents_all',
    groupDisplayName: '',
    showGroupButton: true,
    groupButtonText: 'View all Recents',
    groupLink: '/us/recents',
    elementId: 'recent1'
  },
  {
    id: 'recentItems2',
    name: '',
    apiParams: 'https://api.govglance.org/posts/recent/schema?limit=1&skip=2&schema=united_states_of_america',
    apiFunction: CreateFeedAPIRecentCache,
    generateFunction: GenerateFeed,
    modalTitle: 'Recent Updates',
    buttonName: 'Doc',
    feedBranch: 'N/A',
    feedTopic: 'N/A',
    displayType: 'carousel',
    group: 'recents_all',
    groupDisplayName: '',
    showGroupButton: true,
    groupButtonText: 'View all Recents',
    groupLink: '/us/recents',
    elementId: 'recent2'
  },
  {
    id: 'recentItems3',
    name: '',
    apiParams: 'https://api.govglance.org/posts/recent/schema?limit=1&skip=3&schema=united_states_of_america',
    apiFunction: CreateFeedAPIRecentCache,
    generateFunction: GenerateFeed,
    modalTitle: 'Recent Updates',
    buttonName: 'Doc',
    feedBranch: 'N/A',
    feedTopic: 'N/A',
    displayType: 'carousel',
    group: 'recents_all',
    groupDisplayName: '',
    showGroupButton: true,
    groupButtonText: 'View all Recents',
    groupLink: '/us/recents',
    elementId: 'recent3'
  }
];

  const feeds = [
    {
      id: 'billUpdates',
      name: 'Bill Updates',
      displayType: 'normal',
      apiParams: '&skip=0&schema=united_states_of_america&table=congressional_bills&order_by=action_date',
      feedBranch: 'Legislative',
      feedTopic: 'N/A',
      buttonName: 'Bill',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
    },
    {
      id: 'billsEnrolled',
      name: 'Bills Enrolled (Sent to President)',
      displayType: 'normal',
      apiParams: '&skip=0&schema=united_states_of_america&table=congressional_bills_enrolled&order_by=date_issued',
      feedBranch: 'Legislative',
      feedTopic: 'N/A',
      buttonName: 'Bill',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
    },
    {
      id: 'newLaws',
      name: 'Laws',
      displayType: 'normal',
      apiParams: '&skip=0&schema=united_states_of_america&table=public_and_private_laws&order_by=date_issued',
      feedBranch: 'Legislative',
      feedTopic: 'N/A',
      buttonName: 'Law',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
    },
    {
      id: 'congressionalHearings',
      name: 'Congressional Hearings',
      displayType: 'normal',
      apiParams: '&skip=0&schema=united_states_of_america&table=congressional_hearings&order_by=created_at',
      feedBranch: 'Legislative',
      feedTopic: 'N/A',
      buttonName: 'Hearing',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
    },
    {
      id: 'gaoReports',
      name: 'Government Accountability Office Reports',
      displayType: 'normal',
      apiParams: '&skip=0&schema=united_states_of_america&table=government_accountability_office_reports&order_by=created_at',
      feedBranch: 'Legislative',
      feedTopic: 'N/A',
      buttonName: 'Document',
      apiFunction: CreateFeedAPI,
      generateFunction: GAOReports,
    },
    {
      id: 'congressionalReports',
      name: 'Congressional Reports',
      displayType: 'normal',
      apiParams: '&skip=0&schema=united_states_of_america&table=congressional_reports&order_by=created_at',
      feedBranch: 'Legislative',
      feedTopic: 'N/A',
      buttonName: 'Report',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
    },
  ];
  const legislativeFeeds = [
    {
        id: 'billUpdates',
        name: 'Bill Updates',
        apiParams: '&skip=0&schema=united_states_of_america&table=congressional_bills&order_by=action_date',
        apiFunction: CreateFeedAPI,
        generateFunction: GenerateFeed,
        modalTitle: 'Bill Updates',
        buttonName: 'Bill',
        feedBranch: 'Legislative',
        feedTopic: 'N/A',
        displayType: 'carousel',
        group: 'legislative',
        groupDisplayName: '',
        showGroupButton: true,
        groupButtonText: 'View all Legislative news',
        groupLink: '/legislative',
        elementId: 'bills2',
    },
    {
        id: 'congressionalHearings',
        name: 'Congressional Hearings',
        apiParams: '&skip=0&schema=united_states_of_america&table=congressional_hearings&order_by=created_at',
        apiFunction: CreateFeedAPI,
        generateFunction: GenerateFeed,
        modalTitle: 'Congressional Hearings',
        buttonName: 'Hearing',
        feedBranch: 'Legislative',
        feedTopic: 'N/A',
        displayType: 'carousel',
        group: 'legislative',
        groupDisplayName: '',
        showGroupButton: true,
        groupButtonText: 'View all Legislative news',
        groupLink: '/legislative',
        elementId: 'congressionalHearings',
    },
    {
        id: 'billsEnrolled',
        name: 'Bills Enrolled (Sent to President)',
        apiParams: '&skip=0&schema=united_states_of_america&table=congressional_bills_enrolled&order_by=date_issued',
        apiFunction: CreateFeedAPI,
        generateFunction: GenerateFeed,
        modalTitle: 'Bills Enrolled',
        buttonName: 'Bill',
        feedBranch: 'Legislative',
        feedTopic: 'N/A',
        displayType: 'carousel',
        group: 'legislative',
        groupDisplayName: '',
        showGroupButton: true,
        groupButtonText: 'View all Legislative news',
        groupLink: '/legislative',
        elementId: 'billsEnrolled',
    },
    {
        id: 'laws',
        name: 'Laws',
        apiParams: '&skip=0&schema=united_states_of_america&table=public_and_private_laws&order_by=date_issued',
        apiFunction: CreateFeedAPI,
        generateFunction: GenerateFeed,
        modalTitle: 'Laws',
        buttonName: 'Law',
        feedBranch: 'Legislative',
        feedTopic: 'N/A',
        displayType: 'carousel',
        group: 'legislative',
        groupDisplayName: '',
        showGroupButton: true,
        groupButtonText: 'View all Legislative news',
        groupLink: '/legislative',
        elementId: 'newLaws',
    },
    {
        id: 'gaoReports',
        name: 'Government Accountability Office Reports',
        apiParams: '&skip=0&schema=united_states_of_america&table=government_accountability_office_reports&order_by=created_at',
        apiFunction: CreateFeedAPI,
        generateFunction: GAOReports,
        modalTitle: 'Government Accountability Office Reports',
        buttonName: 'Document',
        feedBranch: 'Legislative',
        feedTopic: 'N/A',
        displayType: 'carousel',
        group: 'legislative',
        groupDisplayName: '',
        showGroupButton: true,
        groupButtonText: 'View all Legislative news',
        groupLink: '/legislative',
        elementId: 'gaoReports',
    },
    {
        id: 'congressionalReports',
        name: 'Congressional Reports',
        apiParams: '&skip=0&schema=united_states_of_america&table=congressional_reports&order_by=created_at',
        apiFunction: CreateFeedAPI,
        generateFunction: GenerateFeed,
        modalTitle: 'Congressional Reports',
        buttonName: 'Report',
        feedBranch: 'Legislative',
        feedTopic: 'N/A',
        displayType: 'carousel',
        group: 'legislative',
        groupDisplayName: '',
        showGroupButton: true,
        groupButtonText: 'View all Legislative news',
        groupLink: '/legislative',
        elementId: 'reports',
    },
];
  return (
<>  



  <MobileView>

  <div id='homebg'>
  <Button  id='navbarMenuButton' ><List className="d-flex align-items-center justify-content-center" id="offCanvasIcon"
          color="white"
          size={20}></List></Button>
    
<h5 id='branchHeadingMobile'> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                              <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
                              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
                            </svg>  Recents <ChevronRight id='clickChevron'></ChevronRight></h5>
                            <div id='homeRecentSpacer'></div>

  
        <NewCarousels feeds={recentsItems}/>

        <h5 id='branchHeadingMobile'>
          <House/> TESTSTATE
          <ChevronRight id='clickChevron'></ChevronRight>
        </h5>
   
{/*Legislative Mobile Carousel*/}
<br>
</br>
<br></br>
<br></br>
<h5 id='branchHeadingMobile'> <Pen></Pen> Legislative<ChevronRight id='clickChevron'></ChevronRight></h5>
 

<NewCarousels feeds={legislativeFeeds}/>
<br></br>

<h5 id='branchHeadingMobile' ><People></People> Executive<ChevronRight id='clickChevron'></ChevronRight></h5>
<NewCarousels feeds={executiveFeeds}/>
</div>
  </MobileView>
  </>
  )

};

export default TestBatch;
