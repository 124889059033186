
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import {Lightbulb, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Tree, FileMedical, FileMedicalFill, Person, ChevronRight  } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import { render } from '@testing-library/react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import DesktopNavbar from './DesktopNavbar';
import HealthMobile from './HealthMobile';
import { CreateFeedAPI, CreateFeedAPIDepartmentUS, CreateFeedItem, CreateFeedItemBillWithDescription, CreateFeedItemMaterials } from './Functions';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfJusticeDocs from './DepartmentOfJusticeDocs';
import JusticeTopicMobile from './JusticeTopicMobile';
import AlcoholTobaccoFireArms from './AlcoholTobaccoFireArms';
import AntitrustDivision from './AntitrustDivision';
import DrugEnforcementAdministration from './DrugEnforcementAdministration';
import ExecutiveOfficeForImmigrationReview from './ExecutiveOfficeForImmigrationReview';
import FederalBureauOfInvestigation from './FederalBureauOfInvestigation';
import UnitedStatesMarshalsService from './UnitedStatesMarshalsService';
import ParoleCommission from './ParoleCommission';
import PrisonBureau from './PrisonBureau';
import NationalInstituteOfJustice from './NationalInstituteOfJustice';
import NationalInstituteOfCorrections from './NationalInstituteOfCorrections';
import JuvenileJusticeAndDellinquencyPreventionOfficee from './JuvenileJusticeAndDellinquencyPreventionOffice';
import JusticeProgramsOffice from './JusticeProgramsOffice';
import ForeignClaimsSettlement from './ForeignClaimsSettlement';
import HouseCommitteeOnTheJudiciary from './HouseCommitteeOnTheJudiciary';
import GenerateFeed from './GenerateFeed';
import GenerateFeedDepartment from './GenerateFeedDepartment';
import SpendingMobile from './SpendingMobile';

var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;

export const Spending = () =>
 {
   render()
   {
 

  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
  const [immigrationModalIsOpen, setImmigrationModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
  const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
  const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
  const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
  const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
  const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
  const [justiceModalIsOpen, setJusticeModalIsOpen] = useState(false)
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };

  var mobileCarouselInterval = 60000;
  const CustomModal = ({ isOpen, onClose, title, content }) => (
    <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
      <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
      {content}
    </Modal>
  );
  return(
    <>
      <BrowserView>
        <div id='homePage'>
        <div id='homebg'>
        <DesktopNavbar/>
      
               <div id='sectionHeading'>      
               <Row>
              <h3 id='branchHeading' >  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#FFFFFF"><path d="M444-144v-80q-51-11-87.5-46T305-357l74-30q8 36 40.5 64.5T487-294q39 0 64-20t25-52q0-30-22.5-50T474-456q-78-28-114-61.5T324-604q0-50 32.5-86t87.5-47v-79h72v79q72 12 96.5 55t25.5 45l-70 29q-8-26-32-43t-53-17q-35 0-58 18t-23 44q0 26 25 44.5t93 41.5q70 23 102 60t32 94q0 57-37 96t-101 49v77h-72Z"/></svg> <b> Spending</b></h3> 
              
              </Row>
              </div> 
         
              <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />
              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                    <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='whBreifingFeedHeading' ><b>Recent Awards	</b></h4>
                          <div id='spendingAwards'></div>
                  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=recent_awards&order_by=start_date', 'spendingAwards', 
                            'Recent Awards', 'N/A', 
                            'Spending', 'Document', GenerateFeed)}

                         
                        </div>
                      </Col>

                    <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='whBreifingFeedHeading' ><b>DOGE Contract Cancellations	</b></h4>
                          <div id='dogeSavings'></div>
                  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=doge_savings&order_by=uploaded_on&filter_column=saving_type&filter_string=Contract', 'dogeSavings', 
                            'DOGE Contract Cancellations', 'N/A', 
                            'Spending', 'Document', GenerateFeed)}
                  
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

            
                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                    <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='whBreifingFeedHeading' ><b>DOGE Grant Cancellations	</b></h4>
                          <div id='dogeSavings2'></div>
                  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=doge_savings&order_by=uploaded_on&filter_column=saving_type&filter_string=Grant', 'dogeSavings2', 
                            'DOGE Grant Cancellations', 'N/A', 
                            'Spending', 'Document', GenerateFeed)}

                         
                        </div>
                      </Col>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='whBreifingFeedHeading' ><b>DOGE Real Estate Cancellations	</b></h4>
                          <div id='dogeSavings3'></div>
                  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=doge_savings&order_by=annual_lease&filter_column=saving_type&filter_string=Real%20Estate', 'dogeSavings3', 
                            'DOGE Real Estate Cancellations', 'N/A', 
                            'Spending', 'Document', GenerateFeed)}

                         
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>


       
            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
   <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>About</Nav.Link>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/privacy', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>
      


            




</div>
         </div>
          

              </BrowserView>

              <MobileView>

              <SpendingMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default Spending;