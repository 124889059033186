import React, { useState } from 'react';
import { Col, Row, Button, Modal, Carousel } from 'react-bootstrap';
import { ChevronRight } from 'react-bootstrap-icons';
import { Link, useHistory } from 'react-router-dom';


const handleHapticsClick = async (callback) => {

  callback();
  window.scrollTo(0, 0);
};

const BatchFeedsMobile = ({ feeds }) => {
  const history = useHistory();
  const [modalState, setModalState] = useState({});

  const openModal = (id) => setModalState({ ...modalState, [id]: true });
  const closeModal = (id) => setModalState({ ...modalState, [id]: false });

  // Function to handle "View More" click
  const handleFeedClick = (apiParams, feedName, feedBranch, feedTopic, feedButtonName) => {
    const urlParams = new URLSearchParams(apiParams);
    const schema = urlParams.get('schema') || 'default_schema';
    const table = urlParams.get('table') || 'default_table';
  
    history.push({
      pathname: '/feed',
      state: {
        apiURL: apiParams,
        feedName,
        feedBranch,
        feedTopic,
        feedButtonName,
        schema,
        table,
      },
    });
  };
  

  const renderedGroups = new Set();

  return (
    <>
      {feeds.map((feed) => {
        const {
          id,
          name,
          apiParams,
          modalTitle,
          buttonName,
          feedBranch,
          feedTopic,
          apiFunction,
          generateFunction,
          displayType,
          group,
          groupDisplayName,
          groupLink,
        } = feed;

        // Render normal feeds
        if (displayType === 'normal') {
          return (
            <div key={id} id={`feed-${id}`} >
              <Col>
                <h5
                  id='chevronHeading'
                  onClick={() => handleFeedClick(apiParams, name, feedBranch, feedTopic, buttonName)}
                >
                  {name}
                </h5>

                <div id={id} className="courtNewsMobile">
                  <div id={`feed-${id}`}></div>
                  {apiFunction(apiParams, `feed-${id}`, name, feedBranch, feedTopic, buttonName, generateFunction)}
                </div>

                <Modal
                  id={`fullBillModal-${id}`}
                  show={modalState[id]}
                  size="xl"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  onHide={() => closeModal(id)}
                >
                  <Modal.Header closeButton>
                    <h3>{modalTitle}</h3>
                  </Modal.Header>
                  {generateFunction && (
                    <generateFunction
                      url={apiParams}
                      name={name}
                      branch={feedBranch}
                      topic={feedTopic}
                      buttonName={buttonName}
                    />
                  )}
                </Modal>
              </Col>
            </div>
          );
        }

        // Render grouped carousel feeds
        if (displayType === 'carousel' && !renderedGroups.has(group)) {
          renderedGroups.add(group);
          const groupFeeds = feeds.filter((item) => item.group === group);

          return (
            <div key={group} id={`carousel-${group}`} className="carouselGroupContainer">
              <Link
                onClick={() => handleHapticsClick(() => {})}
                id="pageLink"
                to={groupLink}
                className="carouselGroupTitleLink"
              >
                <h5 id="chevronHeading">
                  {groupDisplayName}
                  <ChevronRight id="clickChevron" />
                </h5>
              </Link>

              <Carousel controls={false}>
                {groupFeeds.map((feed) => {
                  const {
                    id,
                    name,
                    apiParams,
                    modalTitle,
                    buttonName,
                    feedBranch,
                    feedTopic,
                    apiFunction,
                    generateFunction,
                  } = feed;

                  return (
                    <Carousel.Item key={id} interval={3000}>
                      <div id="legislativeConMobile">
                        <Col>
                          <Row id="mobileFeedHeadingRow">
                            <h5
                              onClick={() => handleFeedClick(apiParams, name, feedBranch, feedTopic, buttonName)}
                              id="billFeedHeadingMobileHome"
                            >
                              {name}
                            </h5>
                          </Row>
                          <div id={id} className="courtNewsMobile">
                            <div id={`feed-${id}`}></div>
                            {apiFunction(apiParams, `feed-${id}`, name, feedBranch, feedTopic, buttonName, generateFunction)}
                          </div>
                        </Col>
                      </div>

                      <Modal
                        id={`fullBillModal-${id}`}
                        show={modalState[id]}
                        size="xl"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        onHide={() => closeModal(id)}
                      >
                        <Modal.Header closeButton>
                          <h3>{modalTitle}</h3>
                        </Modal.Header>
                        {generateFunction && (
                          <generateFunction
                            url={apiParams}
                            name={name}
                            branch={feedBranch}
                            topic={feedTopic}
                            buttonName={buttonName}
                          />
                        )}
                      </Modal>
                    </Carousel.Item>
                  );
                })}

                <Carousel.Item key={`${group}-view-all`} interval={3000}>
                  <div id="legislativeConMobile">
                    <Col>
                      <div id="viewFullPageButton" className="text-center">
                        <Button
                          id="viewFullLegislativePageButtonMobile"
                          size="lg"
                          href={groupLink}
                        >
                          View all {groupDisplayName} news
                        </Button>
                      </div>
                    </Col>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          );
        }

        return null;
      })}
    </>
  );
};

export default BatchFeedsMobile;
