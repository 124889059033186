import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

import BatchFeedsMobile from './BatchFeedsMobile';
import { CreateFeedAPI, CreateFeedAPIDepartmentUS } from './Functions';
import GAOReports from './GAOReports';
import GenerateFeed from './GenerateFeed';
import GenerateFeedDepartment from './GenerateFeedDepartment';
import NewCarousels from './NewCarousels';


const TestBatch2 = () => {
  // Define the feeds array here directly in the page
  const feeds = [
    {
      id: 'healthAndHumanServicesNews',
      name: 'Health and Human Services News',
      apiParams: '&skip=0&schema=united_states_of_america&table=health_and_human_services&order_by=created_at',
      modalTitle: 'Health and Human Services News',
      buttonName: 'Document',
      feedBranch: 'Executive',
      feedTopic: 'Health',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      displayType: 'carousel',
      group: 'healthAndHumanServices',
      groupDisplayName: 'Health and Human Services',
      groupLink: '/departmentofhealthandhuman',
    },
    {
      id: 'healthAndHumanServicesDocuments',
      name: 'Health and Human Services Documents',
      apiParams: '&skip=0&schema=united_states_of_america&table=health_and_human_services&order_by=created_at&filter_column=agency&filter_string=Health%20and%20Human%20Services%20Department',
      modalTitle: 'Health and Human Services Documents',
      buttonName: 'N/A',
      feedBranch: 'Executive',
      feedTopic: 'N/A',
      apiFunction: CreateFeedAPI,
      generateFunction: GenerateFeed,
      displayType: 'carousel',
      group: 'healthAndHumanServices',
      groupDisplayName: 'Health and Human Services',
      groupLink: '/departmentofhealthandhuman',
    },
    {
      id: "defAq",
      name: "Agency for Healthcare Research and Quality Documents",
      apiParams: "&table_name=health_and_human_services&agency=Agency%20for%20Healthcare%20Research%20and%20Quality",
      modalTitle: "Agency for Healthcare Research and Quality Documents",
      buttonName: "Doc",
      feedBranch: "Executive",
      feedTopic: "Health",
      apiFunction: CreateFeedAPIDepartmentUS,
      generateFunction: GenerateFeed,
      displayType: "carousel",
      group: "agencyForHealthcareResearch",
      groupDisplayName: "Agency for Healthcare Research and Quality",
      groupLink: "/healthcare",
    },
    {
      id: "defTsd",
      name: "Agency for Toxic Substances and Disease Registry Documents",
      apiParams: "&table_name=health_and_human_services&agency=Agency%20for%20Toxic%20Substances%20and%20Disease%20Registry",
      modalTitle: "Agency for Toxic Substances and Disease Registry Documents",
      buttonName: "Doc",
      feedBranch: "Executive",
      feedTopic: "Health",
      apiFunction: CreateFeedAPIDepartmentUS,
      generateFunction: GenerateFeed,
      displayType: "carousel",
      group: "agencyForToxicSubstances",
      groupDisplayName: "Agency for Toxic Substances and Disease Registry",
      groupLink: "/toxic-substances",
    },
    {
      id: "defAa",
      name: "Aging Administration Documents",
      apiParams: "&table_name=health_and_human_services&agency=Aging%20Administration",
      modalTitle: "Aging Administration Documents",
      buttonName: "Doc",
      feedBranch: "Executive",
      feedTopic: "Health",
      apiFunction: CreateFeedAPIDepartmentUS,
      generateFunction: GenerateFeed,
      displayType: "carousel",
      group: "agingAdministration",
      groupDisplayName: "Aging Administration",
      groupLink: "/aging-administration",
    },
    {
      id: "defCdc",
      name: "Centers for Disease Control and Prevention Documents",
      apiParams: "&table_name=health_and_human_services&agency=Centers%20for%20Disease%20Control%20and%20Prevention",
      modalTitle: "Centers for Disease Control and Prevention Documents",
      buttonName: "Doc",
      feedBranch: "Executive",
      feedTopic: "Health",
      apiFunction: CreateFeedAPIDepartmentUS,
      generateFunction: GenerateFeed,
      displayType: "carousel",
      group: "centersForDiseaseControl",
      groupDisplayName: "Centers for Disease Control and Prevention",
      groupLink: "/cdc",
    },
    {
      id: "defCfa",
      name: "Children and Families Administration Documents",
      apiParams: "&table_name=health_and_human_services&agency=Children%20and%20Families%20Administration",
      modalTitle: "Children and Families Administration Documents",
      buttonName: "Doc",
      feedBranch: "Executive",
      feedTopic: "Health",
      apiFunction: CreateFeedAPIDepartmentUS,
      generateFunction: GenerateFeed,
      displayType: "carousel",
      group: "childrenAndFamiliesAdministration",
      groupDisplayName: "Children and Families Administration",
      groupLink: "/children-families",
    },
    {
      id: "defCla",
      name: "Community Living Administration Documents",
      apiParams: "&table_name=health_and_human_services&agency=Community%20Living%20Administration",
      modalTitle: "Community Living Administration Documents",
      buttonName: "Doc",
      feedBranch: "Executive",
      feedTopic: "Health",
      apiFunction: CreateFeedAPIDepartmentUS,
      generateFunction: GenerateFeed,
      displayType: "carousel",
      group: "communityLivingAdministration",
      groupDisplayName: "Community Living Administration",
      groupLink: "/community-living",
    },
    {
      id: "defFda",
      name: "Food and Drug Administration Documents",
      apiParams: "&table_name=health_and_human_services&agency=Food%20and%20Drug%20Administration",
      modalTitle: "Food and Drug Administration Documents",
      buttonName: "Doc",
      feedBranch: "Executive",
      feedTopic: "Health",
      apiFunction: CreateFeedAPIDepartmentUS,
      generateFunction: GenerateFeed,
      displayType: "carousel",
      group: "foodAndDrugAdministration",
      groupDisplayName: "Food and Drug Administration",
      groupLink: "/fda",
    },
    {
      id: "defHcfa",
      name: "Health Care Finance Administration Documents",
      apiParams: "&table_name=health_and_human_services&agency=Health%20Care%20Finance%20Administration",
      modalTitle: "Health Care Finance Administration Documents",
      buttonName: "Doc",
      feedBranch: "Executive",
      feedTopic: "Health",
      apiFunction: CreateFeedAPIDepartmentUS,
      generateFunction: GenerateFeed,
      displayType: "carousel",
      group: "healthCareFinanceAdministration",
      groupDisplayName: "Health Care Finance Administration",
      groupLink: "/health-care-finance",
    },
    {
      id: "defHrsa",
      name: "Health Resources and Services Administration Documents",
      apiParams: "&table_name=health_and_human_services&agency=Health%20Resources%20and%20Services%20Administration",
      modalTitle: "Health Resources and Services Administration Documents",
      buttonName: "Doc",
      feedBranch: "Executive",
      feedTopic: "Health",
      apiFunction: CreateFeedAPIDepartmentUS,
      generateFunction: GenerateFeed,
      displayType: "carousel",
      group: "healthResourcesAndServicesAdministration",
      groupDisplayName: "Health Resources and Services Administration",
      groupLink: "/health-resources",
    },
    {
      id: "defIhs",
      name: "Indian Health Service Documents",
      apiParams: "&table_name=health_and_human_services&agency=Indian%20Health%20Service",
      modalTitle: "Indian Health Service Documents",
      buttonName: "Doc",
      feedBranch: "Executive",
      feedTopic: "Health",
      apiFunction: CreateFeedAPIDepartmentUS,
      generateFunction: GenerateFeed,
      displayType: "carousel",
      group: "indianHealthService",
      groupDisplayName: "Indian Health Service",
      groupLink: "/indian-health",
    },
    {
      id: "defNih",
      name: "National Institutes of Health Documents",
      apiParams: "&table_name=health_and_human_services&agency=National%20Institutes%20of%20Health",
      modalTitle: "National Institutes of Health Documents",
      buttonName: "Doc",
      feedBranch: "Executive",
      feedTopic: "Health",
      apiFunction: CreateFeedAPIDepartmentUS,
      generateFunction: GenerateFeed,
      displayType: "carousel",
      group: "nationalInstitutesOfHealth",
      groupDisplayName: "National Institutes of Health",
      groupLink: "/nih",
    },
    {
      id: "defNlm",
      name: "National Library of Medicine Documents",
      apiParams: "&table_name=health_and_human_services&agency=National%20Library%20of%20Medicine",
      modalTitle: "National Library of Medicine Documents",
      buttonName: "Doc",
      feedBranch: "Executive",
      feedTopic: "Health",
      apiFunction: CreateFeedAPIDepartmentUS,
      generateFunction: GenerateFeed,
      displayType: "carousel",
      group: "nationalLibraryOfMedicine",
      groupDisplayName: "National Library of Medicine",
      groupLink: "/national-library-medicine",
    },
    {
      id: "defPsc",
      name: "Program Support Center Documents",
      apiParams: "&table_name=health_and_human_services&agency=Program%20Support%20Center",
      modalTitle: "Program Support Center Documents",
      buttonName: "Doc",
      feedBranch: "Executive",
      feedTopic: "Health",
      apiFunction: CreateFeedAPIDepartmentUS,
      generateFunction: GenerateFeed,
      displayType: "carousel",
      group: "programSupportCenter",
      groupDisplayName: "Program Support Center",
      groupLink: "/program-support-center",
    },
  ];
  
  return (
<>  



  <MobileView>
  <div id='homebg'>

  <BatchFeedsMobile feeds={feeds} />


  </div>

  </MobileView>
  </>
  )

};

export default TestBatch2;
