import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Coin, Tree, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import AddNoteModal from './AddNoteModal';
import Axios from 'axios'
import { render } from '@testing-library/react';

import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import SecuritiesAndExchangeCommissionPressReleases from './SecuritiesAndExchangeCommissionPressReleases';
import SecuritiesAndExchangeCommissionSpeechesAndStatments from './SecuritiesAndExchangeCommissionSpeechesAndStatments';
import BureauOfEconomicAnalysis from './BureauOfEconomicAnalysis';
import EconomicIndicators from './EconomicIndicators';
import HouseCommitteeOnTheBudget from './HouseCommitteeOnTheBudget';
import HouseCommitteeOnEnergyAndCommerce from './HouseCommitteeOnEnergyAndCommerce';
import HouseCommitteeOnEducationAndLabor from './HouseCommitteeOnEducationAndLabor';
import HouseCommitteeOnFinacialServices from './HouseCommitteeOnFinacialServices';
import CongressionalBudgetOffice from './CongressionalBudgetOffice';
import HouseCommitteeOnSmallBusiness from './HouseCommitteeOnSmallBusiness';
import { createBillFeedTwoMobile, createBIllItem, Speak, CreateFeedItem, CreateFeedItemEconomicIndicators, CreateFeedItemExecutiveOffice, CreateFeedAPI, CreateFeedItemNoDate, CreateFeedItemMaterials, CreateFeedAPIRecentCacheEconomy } from './Functions';
import DesktopNavbar from './DesktopNavbar';
import GDPUpdates from './GDPUpdates';
import InflationUpdates from './InflationUpdates';
import WageGrowth from './WageGrowth';
import BureauOfLaborStatistics from './BureauOfLaborStatistics';
import IRSDocuments from './IRSDocuments';
import GovernmentContracts from './GovernmentContracts';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import CouncilOfEconomicAdvisers from './CouncilOfEconomicAdvisers';
import NationalEconomicCouncil from './NationalEconomicCouncil';
import OfficeOfManagmentAndBudget from './OfficeOfManagmentAndBudget';
import SenateBankingHousingUrbanCommittee from './SenateBankingHousingUrbanCommittee';
import SenateCommerceScienceCommittee from './SenateCommerceScienceCommittee';
import SenateEnergyAndNRCommittee from './SenateEnergyAndNRCommittee';
import SenateFinanceCommittee from './SenateFinanceCommittee';
import SenateSmallBusinessCommittee from './SenateSmallBusinessCommittee';
import SenateBudgetCommittee from './SenateBudgetCommittee';
import HouseCommitteeOnEducationAndLaborMaterials from './HouseCommitteeOnEducationAndLaborMaterials';
import HouseCommitteeOnEnergyAndCommerceMaterials from './HouseCommitteeOnEnergyAndCommerceMaterials';
import HouseCommitteeOnFinacialServicesMaterials from './HouseCommitteeOnFinacialServicesMaterials';
import HouseCommitteeOnHouseAdministrationMaterials from './HouseCommitteeOnHouseAdministrationMaterials';
import HouseCommitteeOnIntelligenceMaterials from './HouseCommitteeOnIntelligenceMaterials';
import HouseCommitteeOnInterstateAndForeignCommerceMaterials from './HouseCommitteeOnInterstateAndForeignCommerceMaterials';
import HouseCommitteeOnSmallBusinessMaterials from './HouseCommitteeOnSmallBusinessMaterials';
import HouseCommitteeOnTheBudgetMaterials from './HouseCommitteeOnTheBudgetMaterials';
import GenerateFeed from './GenerateFeed';
import DepartmentOfTreasuryDocs from './DepartmentOfTreasuryDocs';
import DepartmentOfLaborDocs from './DepartmentOfLaborDocs';
import DepartmentOfCommerceDocuments from './DepartmentOfCommerceDocuments';
import CensusBureau from './CensusBureau';
import EconomicAnalysisBureau from './EconomicAnalysisBureau';
import EconomicDevelopmentAdministration from './EconomicDevelopmentAdministration';


var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;
const imageName = '/federalReserve.png'; // image path
const agencyName = 'Federal Reserve'; // agency name
const officialURL = 'https://www.federalreserve.gov/'; // official URL
const missionStatement = 'The Federal Reserve System is the central bank of the United States. It performs five general functions to promote the effective operation of the U.S. economy and, more generally, the public interest'; // mission statement
const rssURL = '&skip=0&schema=united_states_of_america&table=federal_reserve&order_by=created_at&filter_column=media_type&filter_string=Speeches%20%26%20Testimony'; // RSS feed URL
const feedName = 'Speeches & Testimony'; // feed name
const topic = 'Economy'; // topic name

const rssURL2 = '&skip=0&schema=united_states_of_america&table=federal_reserve&order_by=created_at&filter_column=media_type&filter_string=Research%20Paper%20Feeds'; // RSS feed URL
const feedName2 = 'Research Paper'; // feed name

const rssURL3 = '&skip=0&schema=united_states_of_america&table=federal_reserve&order_by=created_at&filter_column=media_type&filter_string=Other'; // RSS feed URL
const feedName3 = 'Others'; // feed name

const rssURL4 = '&skip=0&schema=united_states_of_america&table=federal_reserve&order_by=created_at&filter_column=media_type&filter_string=Press%20Releases'; // RSS feed URL
const feedName4 = 'Press Releases'; // feed name

export const EconomyMobile = () =>   {
    render()

    {
      const [show, setShow] = useState(true);

      
      const [fullscreen, setFullScreen] = useState(true);
    
    
      const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
    
      const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
    
    
      const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
      const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
    
    
      const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
      const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
    
      const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
    
      const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
    
      const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
    
      const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
      const [armyModalIsOpen, setArmyModalIsOpen] = useState(false);
  const [papersModalIsOpen, setPapersModalIsOpen] = useState(false);
  const [announcementsModalIsOpen, setAnnouncementsModalIsOpen] = useState(false);
  const [othersModalIsOpen, setOthersModalIsOpen] = useState(false);
    
      const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
      const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
      const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
      const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
      const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
      const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
      const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
      const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
      const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
      const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
      const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
      const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
      const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
      const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
      const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
      const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
      const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
      const [beaModalIsOpen, setBEAModalIsOpen] = useState(false)
      const [secPressModalIsOpen, setSECPressModalIsOpen] = useState(false)
    
      const [gdpUpdatesModalIsOpen, setGDPUpdatesModalIsOpen] = useState(false)
      const [inflationUpdatesModalIsOpen, setInflationUpdatesModalIsOpen] = useState(false)
      const [wageGrowthModalIsOpen, setWageGrowthModalIsOpen] = useState(false)
      const [bureauOfLaborStatisticsModalIsOpen, setBureauOfLaborStatisticsModalIsOpen] = useState(false)
      const [irsDocumentsModalIsOpen, setIRSDocumentsModalIsOpen] = useState(false)
      const [governmentContractsModalIsOpen, setGovernmentContractsModalIsOpen] = useState(false)
    
    
        const [secSAndSModalIsOpen, setSECSAndSModalIsOpen] = useState(false)
      const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
      const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
    
      const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
      const [houseCommitteeOnAppropriationsModalIsOpen, setHouseCommitteeOnAppropriationsModalIsOpen] = useState(false)
      const [houseCommitteeOnArmedServicesModalIsOpen, setHouseCommitteeOnOnArmedServicesModalIsOpen] = useState(false)
      const [houseCommitteeOnTheBudgetModalIsOpen, setHouseCommitteeOnTheBudgetModalIsOpen] = useState(false)
      const [houseCommitteeOnEnergyAndCommerceModalIsOpen, setHouseCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
      const [houseCommitteeOnEducationAndLaborModalIsOpen, setHouseCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
      const [houseCommitteeOnEthicsModalIsOpen, setHouseCommitteeOnEthicsModalIsOpen] = useState(false)
      const [houseCommitteeOnFinancialServicesModalIsOpen, setHouseCommitteeOnFinancialServicesModalIsOpen] = useState(false)
      const [houseCommitteeOnForeignAffairsModalIsOpen, setHouseCommitteeOnForeignAffairsModalIsOpen] = useState(false)
      const [houseCommitteeOnHomelandSecurityModalIsOpen, setHouseCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
      const [houseCommitteeOnHouseAdministrationModalIsOpen, setHouseCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
      const [houseCommitteeOnTheJudiciaryModalIsOpen, setHouseCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
      const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
      const [congressionalBudgetOfficeModalIsOpen, setCongressionalBudgetOfficeModalIsOpen] = useState(false)
      const [houseCommitteeOnSmallBusinessModalIsOpen, setHouseCommitteeOnSmallBusinessModalIsOpen] = useState(false)
      const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
      const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
    
      const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
      const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
    
    
    
      const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
      const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
      const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
    

      const [officeOfManagementModalIsOpen, setOfficeOfManagmentModalIsOpen] = useState(false)
      const [energyMaterialsmMdalIsOpen, setEnergyMaterialsModalIsOpen] = useState(false)
      const [interstateMaterialsModalIsOpen, setInterstateMaterialsModalIsOpen] = useState(false)
      const [budgetMaterialsModalIsOpen, setBudgetMaterialsModalIsOpen] = useState(false)
      const [educationMaterialsModalIsOpen, setEducationMaterialsModalIsOpen] = useState(false)
      const [financialServicesMaterialsModalIsOpen, setFinancialServicesMaterialsModalIsOpen] = useState(false)
      const [smallBusinessMaterialsModalIsOpen, setSmallBusinessMaterialsModalIsOpen] = useState(false)
      const [senateSmallBusinessMaterialsModalIsOpen, setSenateSmallBusinessMaterialsModalIsOpen] = useState(false)
      const [senateCommitteeOnEnergyModalIsOpen, setSenateCommitteeOnEnergyModalIsOpen] = useState(false)
      const [senateBudgetMaterialsModalIsOpen, setSenateBudgetMaterialsModalIsOpen] = useState(false)
      const [senateFinanceMaterialsModalIsOpen, setSenateFinaceMaterialsModalIsOpen] = useState(false)
      const [senateTransportationMaterialsModalIsOpen, setSenateTransportationMaterialsModalIsOpen] = useState(false)
      const [senateCommitteeOnTheBudgetModalIsOpen, setSenateCommitteeOnTheBudgetModalIsOpen] = useState(false)
      const [senateHousingMaterialsModalIsOpen, setSenateHousingMaterialsModalIsOpen] = useState(false)
      const [commerceDocsModalIsOpen, setCommerceDocsModalIsOpen] = useState(false)
      const [censusBureauModalIsOpen, setCensusBureauModalIsOpen] = useState(false)
      const [economicAnalysisModalIsOpen, setEconomicAnalysisModalIsOpen] = useState(false)
      const [ecoDevelopmentModalIsOpen, setEconDevelopmentModalIsOpen] = useState(false)
      const [enforcementsModalIsOpen, setEnforcemnetsModalIsOpen] = useState(false)
      const [
        number1ModalIsOpen, setNumber1ModalIsOpen,
        number2ModalIsOpen, setNumber2ModalIsOpen,
        number3ModalIsOpen, setNumber3ModalIsOpen,
        number4ModalIsOpen, setNumber4ModalIsOpen,
        number5ModalIsOpen, setNumber5ModalIsOpen,
        number6ModalIsOpen, setNumber6ModalIsOpen,
        number7ModalIsOpen, setNumber7ModalIsOpen,
        number8ModalIsOpen, setNumber8ModalIsOpen,
        number9ModalIsOpen, setNumber9ModalIsOpen,
        number10ModalIsOpen, setNumber10ModalIsOpen,
        number11ModalIsOpen, setNumber11ModalIsOpen,
        number12ModalIsOpen, setNumber12ModalIsOpen,
        number13ModalIsOpen, setNumber13ModalIsOpen,
        number14ModalIsOpen, setNumber14ModalIsOpen,
        number15ModalIsOpen, setNumber15ModalIsOpen,
        number16ModalIsOpen, setNumber16ModalIsOpen,
        number17ModalIsOpen, setNumber17ModalIsOpen,
        number18ModalIsOpen, setNumber18ModalIsOpen,
        number19ModalIsOpen, setNumber19ModalIsOpen,
        number20ModalIsOpen, setNumber20ModalIsOpen,
        number21ModalIsOpen, setNumber21ModalIsOpen,
        number22ModalIsOpen, setNumber22ModalIsOpen,
        number23ModalIsOpen, setNumber23ModalIsOpen,
        number24ModalIsOpen, setNumber24ModalIsOpen,
        number25ModalIsOpen, setNumber25ModalIsOpen,
      ] = useState(false);
    
 
  
    return(
       <>
      <MobileTopNavbar/>

       <div id='homebg'>
      
     


           <h5 id='branchHeadingMobile' ><Coin></Coin> Economy</h5>

<Modal id='fullBillModal' show={beaModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setBEAModalIsOpen(false)}>
 <Modal.Header id='billModalHeader' closeButton><h3>Economic Indicators</h3></Modal.Header>
 <EconomicIndicators/>
</Modal>

<br></br>
               
               <h5 id='branchHeadingMobile' onClick={() => window.open('/us/recents/economy', "_self")}> <svg style={{gap: '5px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
                      </svg> Recents <ChevronRight id='clickChevron'></ChevronRight></h5>  
         
                  <Col>
         
                  <Carousel controls={false} >
                  
     
                  
                  <Carousel.Item interval={mobileCarouselInterval}>


<Col>


          


         

            <div class='courtNewsMobile' id='recent3'></div> 
{CreateFeedAPIRecentCacheEconomy('https://api.govglance.org/posts/recent/topic?limit=1&skip=0&topic=economy', 'recent3', 
          'Recents', 'N/A', 
          'N/A', 'Doc', GenerateFeed)}
          </Col>









</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>



<Col>

 

         

            <div class='courtNewsMobile' id='recent1'></div> 
{CreateFeedAPIRecentCacheEconomy('https://api.govglance.org/posts/recent/topic?limit=1&skip=1&topic=economy', 'recent1', 
          'Recents', 'N/A', 
          'N/A', 'Doc', GenerateFeed)}
          </Col>








</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>



<Col>


         

            <div class='courtNewsMobile' id='recent4'></div> 
{CreateFeedAPIRecentCacheEconomy('https://api.govglance.org/posts/recent/topic?limit=1&skip=2&topic=economy', 'recent4', 
          'Recents', 'N/A', 
          'N/A', 'Doc', GenerateFeed)}
          </Col>










</Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>



<Col>


         

            <div class='courtNewsMobile' id='recent5'></div> 
{CreateFeedAPIRecentCacheEconomy('https://api.govglance.org/posts/recent/topic?limit=1&skip=3&topic=economy', 'recent5', 
          'Recents', 'N/A', 
          'N/A', 'Doc', GenerateFeed)}
          </Col>










</Carousel.Item>

                  </Carousel>
                  
                  </Col>

<h5 onClick={() => window.open('/us/sec', "_self")} id='presidentialFeedHeadingMobile'>SEC<ChevronRight id='clickChevron'></ChevronRight></h5>
                      
          
                      <Col>
          
                      <Carousel controls={false} >
                                  
                            
                                  
                                  <Carousel.Item interval={mobileCarouselInterval}>
          
          <div id='legislativeConMobile'>
          
          <Col>
          
          
          <h5 id='presidentialFeedHeadingMobile' onClick={() => setSECPressModalIsOpen(true)}>SEC Press Releases</h5>

<div class='courtNewsMobile' id='secPR'></div>
                   {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=sec_updates&order_by=created_at', 'secPR', 
                       'SEC Press Releases', 'Executive', 
                       'Economy', 'Document', GenerateFeed)}

                     <Modal id='whiteHouseDocsModal' show={secPressModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                     centered onHide={() => setSECPressModalIsOpen(false)}>
                       <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>SEC Press Releases</h3></Modal.Header>
                       <GenerateFeed url='&skip=0&schema=united_states_of_america&table=sec_updates&order_by=created_at' 
                       name='SEC Press Releases' branch='Executive' topic='Economy' buttonName='Document' />
                     </Modal>

            </Col>
          
          
              
            
              </div>
             
          
          
          </Carousel.Item>
          
          <Carousel.Item interval={mobileCarouselInterval}>
          
          <div id='legislativeConMobile'>
          
          <Col>
          <h5 id='presidentialFeedHeadingMobile' onClick={() => setSECSAndSModalIsOpen(true)}>SEC Speeches and Statements</h5>

     <div class='courtNewsMobile' id='sec'></div>
             

             {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=sec_speeches_statements&order_by=created_at', 'sec', 
                 'SEC Speeches and Statements', 'Executive', 
                 'Economy', 'Document', GenerateFeed)}

               <Modal id='whiteHouseDocsModal' show={secSAndSModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
               centered onHide={() => setSECSAndSModalIsOpen(false)}>
                 <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>SEC Speeches and Statements</h3></Modal.Header>
                 <GenerateFeed url='&skip=0&schema=united_states_of_america&table=sec_speeches_statements&order_by=created_at' 
                 name='SEC Speeches and Statements' branch='Executive' topic='Economy' buttonName='Document' />
               </Modal>
          </Col>
          
              
            
              </div>
             
          
          
          </Carousel.Item>
          
      
          
   
          
          <Carousel.Item interval={mobileCarouselInterval}>
          
          <div id='legislativeConMobile'>
          
          <Col>
          <div id='viewFullPageButton'>
          <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/sec' >View all SEC news</Button>
          </div>
          </Col>
          </div>
          </Carousel.Item>   
                                  </Carousel>
                          </Col>    

<h5 onClick={() => window.open('/federalreserve', "_self")} id='presidentialFeedHeadingMobile'>Federal Reserve<ChevronRight id='clickChevron'></ChevronRight></h5>
                      
          
                      <Col>
          
                      <Carousel controls={false} >
                                  
                            
                                  
                                  <Carousel.Item interval={mobileCarouselInterval}>
          
          <div id='legislativeConMobile'>
          
          <Col>
          
          
          <h5 id='presidentialFeedHeadingMobile' onClick={() => setArmyModalIsOpen(true)}>{feedName}</h5>
          
          
          
          
          <div class='courtNewsMobile'id='agency'></div>
          {CreateFeedAPI(rssURL, 'agency', feedName, '', topic, 'Doc', GenerateFeed )}
          
          <Modal id='whiteHouseDocsModal' show={armyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmyModalIsOpen(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
          <GenerateFeed url={rssURL} name={feedName} branch='' topic={topic} buttonName='Doc' />
        </Modal>

            </Col>
          
          
              
            
              </div>
             
          
          
          </Carousel.Item>
          
          <Carousel.Item interval={mobileCarouselInterval}>
          
          <div id='legislativeConMobile'>
          
          <Col>
            <h5 id='presidentialFeedHeadingMobile' onClick={() => setPapersModalIsOpen(true)}>{feedName2}</h5>
            <div class='courtNewsMobile' id='agency2'></div>
          {CreateFeedAPI(rssURL2, 'agency2', feedName2, '', topic, 'Doc', GenerateFeed )}
           
              </Col>
          
              <Modal id='whiteHouseDocsModal' show={papersModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setPapersModalIsOpen(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>{feedName2}</h3></Modal.Header>
          <GenerateFeed url={rssURL2} name={feedName2} branch='' topic={topic} buttonName='Doc' />
        </Modal>
          
          
              
            
              </div>
             
          
          
          </Carousel.Item>
          
      
          
   
          
          <Carousel.Item interval={mobileCarouselInterval}>
          
          <div id='legislativeConMobile'>
          
          <Col>
          <div id='viewFullPageButton'>
          <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/federalreserve' >View all Federal Reserve news</Button>
          </div>
          </Col>
          </div>
          </Carousel.Item>   
                                  </Carousel>
                          </Col>    

<Col>




<h5 id='presidentialFeedHeadingMobile' onClick={() => setGDPUpdatesModalIsOpen(true)}>GPD Updates</h5>
 
 
 
 <div class='courtNewsMobile'id='gdpUpdates'></div>
                        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=gdp&order_by=created_at', 'gdpUpdates', 
                            'GDP Updates', 'Executive', 
                            'Economy', 'Document', GenerateFeed)}

                          <Modal id='whiteHouseDocsModal' show={gdpUpdatesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                          centered onHide={() => setGDPUpdatesModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>GDP Updates</h3></Modal.Header>
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=gdp&order_by=created_at' 
                            name='GDP Updates' branch='Executive' topic='Economy' buttonName='Document' />
                          </Modal>


                 </Col>








          





{/*Inflation Updates */}

             <Col>


        <h5 id='presidentialFeedHeadingMobile' onClick={() => setInflationUpdatesModalIsOpen(true)}>Inflation Updates</h5>
       
                 <div class='courtNewsMobile' id='inflationUpdates'></div>
                        
                        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=inflation_updates&order_by=created_at', 
                        'inflationUpdates', 
                            'Inflation Updates', 'Executive', 
                            'Economy', 'Document', GenerateFeed)}

                          <Modal id='whiteHouseDocsModal' show={inflationUpdatesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                          centered onHide={() => setInflationUpdatesModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Inflation Updates</h3></Modal.Header>
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=inflation_updates&order_by=created_at' 
                            name='Inflation Updates' branch='Executive' topic='Economy' buttonName='Document' />
                          </Modal>


                 </Col>







          
    




{/*Wage Growth */}

             <Col>




         <h5 id='presidentialFeedHeadingMobile' onClick={() => setWageGrowthModalIsOpen(true)}>Wage Growth</h5>
             
                 <div class='courtNewsMobile' id='wageGrowth'></div>
                        
                        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=wage_growth&order_by=created_at', 
                        'wageGrowth', 
                            'Wage Growth', 'Executive', 
                            'Economy', 'Document', GenerateFeed)}

                          <Modal id='whiteHouseDocsModal' show={wageGrowthModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                          centered onHide={() => setWageGrowthModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Wage Growth</h3></Modal.Header>
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=wage_growth&order_by=created_at' 
                            name='Wage Growth' branch='Executive' topic='Economy' buttonName='Document' />
                          </Modal>


                 </Col>



{/*Bureau of Labor Statistics */}







              
                       <Modal id='fullBillModal' show={bureauOfLaborStatisticsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setBureauOfLaborStatisticsModalIsOpen(false)}>
                         <Modal.Header id='billModalHeader' closeButton><h3>Bureau Of Labor Statistics</h3></Modal.Header>
                         <BureauOfLaborStatistics />
                       </Modal>






          
     








          
       


                       <h5 onClick={() => window.open('/departmentofcommerce', "_self")} id='presidentialFeedHeadingMobile'>Department of Commerce<ChevronRight id='clickChevron'></ChevronRight></h5>
                      
          
                      <Col>
          
                      <Carousel controls={false} >
                                  
                            
                                  
                                  <Carousel.Item interval={mobileCarouselInterval}>
          
          <div id='legislativeConMobile'>
          
          <Col>
          
          
          <h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfCommerceModalIsOpen(true)}>News</h5>
          
          
          
          
          <div class='courtNewsMobile' id='commerceDepartment'></div>
          
          {CreateFeedItem('https://www.commerce.gov/feeds/news', 'commerceDepartment', 'Department of Commerce', 'Executive', 'Economy', DepartmentOfCommerce)}
          
          
            </Col>
          
          
              
            
              </div>
             
          
          
          </Carousel.Item>
          
          <Carousel.Item interval={mobileCarouselInterval}>
          
          <div id='legislativeConMobile'>
          
          <Col>
            <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommerceDocsModalIsOpen(true)}>Documents</h5>
            <div class='courtNewsMobile' id='commerceDocs'></div>
                          
                          {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=commerce-department', 'commerceDocs', 'Department of Commerce Documents', 'Executive', 'N/A', DepartmentOfCommerceDocuments)}
          
           
              </Col>
          
              <Modal id='whiteHouseDocsModal' show={commerceDocsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommerceDocsModalIsOpen(false)}>
                        <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Commerce Documents</h3></Modal.Header>
                        <DepartmentOfCommerceDocuments/>
                      </Modal>
          
          
              
            
              </div>
             
          
          
          </Carousel.Item>
          
          <Carousel.Item interval={mobileCarouselInterval}>
          
          <div id='legislativeConMobile'>
          
          <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCensusBureauModalIsOpen(true)}>Census Bureau Documents</h5>
                <div class='courtNewsMobile' id='censusBureau'></div>
                              
                              {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=census-bureau', 'censusBureau', 'Census Bureau Documents', 'Executive', 'N/A', CensusBureau)}
          
               
                  </Col>
          
                  <Modal id='whiteHouseDocsModal' show={censusBureauModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCensusBureauModalIsOpen(false)}>
                            <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Census Bureau Documents</h3></Modal.Header>
                            <CensusBureau />
                          </Modal>
          
          
              
            
              </div>
             
          
          
          </Carousel.Item>
          
          <Carousel.Item interval={mobileCarouselInterval}>
          
          <div id='legislativeConMobile'>
          
          <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setEconomicAnalysisModalIsOpen(true)}>Economic Analysis Bureau Documents</h5>
                <div class='courtNewsMobile' id='economicAnalysis'></div>
                              
                              {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=economic-analysis-bureau', 'economicAnalysis', 'Economic Analysis Bureau Documents', 'Executive', 'N/A', EconomicAnalysisBureau)}
          
               
                  </Col>
          
                  <Modal id='whiteHouseDocsModal' show={economicAnalysisModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setEconomicAnalysisModalIsOpen(false)}>
                            <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Economic Analysis Bureau Documents</h3></Modal.Header>
                            <EconomicAnalysisBureau />
                          </Modal>
          
          
              
            
              </div>
             
          
          
          </Carousel.Item>
          
          <Carousel.Item interval={mobileCarouselInterval}>
          
          <div id='legislativeConMobile'>
          
          <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setEconDevelopmentModalIsOpen(true)}>Economic Development Administration Documents</h5>
                <div class='courtNewsMobile' id='economicDevelopment'></div>
                              
                              {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=economic-development-administration', 'economicDevelopment', 'Economic Development Administration Documents', 'Executive', 'N/A', EconomicDevelopmentAdministration)}
          
               
                  </Col>
          
                  <Modal id='whiteHouseDocsModal' show={ecoDevelopmentModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setEconDevelopmentModalIsOpen(false)}>
                            <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Economic Development Administration Documents</h3></Modal.Header>
                            <EconomicDevelopmentAdministration />
                          </Modal>
          
          
              
            
              </div>
             
          
          
          </Carousel.Item>
          
          <Carousel.Item interval={mobileCarouselInterval}>
          
          <div id='legislativeConMobile'>
          
          <Col>
          <div id='viewFullPageButton'>
          <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/departmentofcommerce' >View all Department of Commerce news</Button>
          </div>
          </Col>
          
          </div>
          
          
          
          </Carousel.Item>
          
                                  
                                  
                                  </Carousel>
          
          
                       
          
                          </Col>
          
          
                  
                          <Modal id='whiteHouseDocsModal' show={departmentOfCommerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfCommerceModalIsOpen(false)}>
                            <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Commerce</h3></Modal.Header>
                            <DepartmentOfCommerce />
                          </Modal>






           
           


          
                          <h5 onClick={() => window.open('/departmentoftreasury', "_self")} id='presidentialFeedHeadingMobile'>Department of Treasury<ChevronRight id='clickChevron'></ChevronRight></h5>

            
<Col>

<Carousel controls={false} >
            
      
            
            <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfTreasuryModalIsOpen(true)}>News</h5>




  <div class='courtNewsMobile' id='treasuryDepartment'></div>

  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=treasury&order_by=created_at', 
                                             'treasuryDepartment', 
                                               'Department of Treasury', 'Executive', 
                                               'N/A', 'Document', GenerateFeed)}
                                <Modal id='whiteHouseDocsModal' show={departmentOfTreasuryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfTreasuryModalIsOpen(false)}>
                          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Treasury</h3></Modal.Header>
                          <GenerateFeed url='&skip=0&schema=united_states_of_america&table=treasury&order_by=created_at' name='Department of Treasury' branch='Executive' topic='N/A' buttonName='Doc'
                />
                        </Modal>

 </Col>








    



</div>



</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setLegislativePageBillsEnrolledModalIsOpen(true)}>Documents</h5>


<div class='courtNewsMobile' id='treasuryDocs'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=treasury-department', 'treasuryDocs', 'Department of Treasury Documents', 'Executive', 'N/A', DepartmentOfTreasuryDocs)}

</Col>

<Modal id='whiteHouseDocsModal' show={legislativePageBillsEnrolledModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setLegislativePageBillsEnrolledModalIsOpen(false)}>
      <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Treasury Documents</h3></Modal.Header>
      <DepartmentOfTreasuryDocs/>
    </Modal>
  





</div>



</Carousel.Item>






<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/departmentoftreasury' >View all Department of Treasury news</Button>
</div>
</Col>

</div>



</Carousel.Item>

            
            
            </Carousel>

    </Col>




            

               <Modal id='whiteHouseDocsModal' show={departmentOfTreasuryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfTreasuryModalIsOpen(false)}>
                 <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Treasury</h3></Modal.Header>
                 <DepartmentOfTreasury />
               </Modal>






            
           


           
            

            
<Col>
<h5 onClick={() => window.open('/departmentofhousing', "_self")} id='presidentialFeedHeadingMobile'>Department of Labor<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false} >
            
      
            
            <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfLaborModalIsOpen(true)}>News</h5>




  <div class='courtNewsMobile' id='laborDepartment'></div>

  {CreateFeedItemNoDate('https://www.dol.gov/rss/releases.xml', 'laborDepartment', 'Department of Labor', 'Executive', 'Economy', DepartmentOfLabor)}


</Col>



<Modal id='whiteHouseDocsModal' show={departmentOfLaborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfLaborModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Labor</h3></Modal.Header>
  <DepartmentOfLabor />
</Modal>



<Modal id='whiteHouseDocsModal' show={departmentOfHousingAndUrbanDevelopmentModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen(false)(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Housing and Urban Development</h3></Modal.Header>
  <DepartmentOfHousingAndUrbanDevelopment />
</Modal>


    



</div>



</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setEnforcemnetsModalIsOpen(true)}>Documents</h5>


<div class='courtNewsMobile' id='laborDocs'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=labor-department', 'laborDocs', 'Department of Labor Documents', 'Executive', 'N/A', DepartmentOfLaborDocs)}

</Col>

<Modal id='whiteHouseDocsModal' show={enforcementsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setEnforcemnetsModalIsOpen(false)}>
      <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Labor Documents</h3></Modal.Header>
      <DepartmentOfLaborDocs/>
    </Modal>
  





</div>



</Carousel.Item>






<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/departmentoflabor' >View all Department of Labor news</Button>
</div>
</Col>

</div>



</Carousel.Item>

            
            
            </Carousel>

    </Col>
    




         
               <Modal id='whiteHouseDocsModal' show={departmentOfLaborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfLaborModalIsOpen(false)}>
                 <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Labor</h3></Modal.Header>
                 <DepartmentOfLabor />
               </Modal>






         
               <Col>




              <h5 id='presidentialFeedHeadingMobile' onClick={() => setCongressionalBudgetOfficeModalIsOpen(true)}>Congressional Budget Office</h5>




             


                 <div class='courtNewsMobile' id='congressionalBudgetOffice'></div>

                 {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=supreme_court_opinions&order_by=created_at', 'congressionalBudgetOffice', 'Congressional Budget Office', 'Legislative', 'Economy', 'Document', CongressionalBudgetOffice)}


                 
               </Col>


               <Col>




<h5 id='presidentialFeedHeadingMobile' onClick={() => setEconomicAdModalIsOpen(true)}>Council of Economic Advisers	</h5>







    <div class='courtNewsMobile' id='councilOfEconomicAd'></div>
                    
                    {CreateFeedItemExecutiveOffice('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive_office_of_the_president/Council_of_Economic_Advisers.xml', 'councilOfEconomicAd', 'Council of Economic Advisers', 'Executive', 'N/A', CouncilOfEconomicAdvisers)}


    
  </Col>

  <Modal id='whiteHouseDocsModal' show={economicAdModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setEconomicAdModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Council of Economic Advisers</h3></Modal.Header>
                  <CouncilOfEconomicAdvisers />
                </Modal>

             



  <Col>




<h5 id='presidentialFeedHeadingMobile' onClick={() => setNationalEconomicModalIsOpen(true)}>National Economic Council	</h5>







    <div class='courtNewsMobile' id='nationalEconomicCouncil'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                            {CreateFeedItemExecutiveOffice('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive_office_of_the_president/National_Economic_Council.xml', 'nationalEconomicCouncil', 'National Economic Council', 'Executive', 'N/A', NationalEconomicCouncil)}


    
  </Col>

  <Modal id='whiteHouseDocsModal' show={nationalEconomicModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNationalEconomicModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Economic Council</h3></Modal.Header>
                  <NationalEconomicCouncil/>
                </Modal>





  <Col>




<h5 id='presidentialFeedHeadingMobile' onClick={() => setOfficeOfManagmentModalIsOpen(true)}>Office of Management and Budget</h5>







    <div class='courtNewsMobile' id='officeOfManagmentAndBudget'></div>
                          
                          {CreateFeedItemExecutiveOffice('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive_office_of_the_president/Office_of_Management_and_Budget.xml', 'officeOfManagmentAndBudget', 'Office of Management and Budget', 'Executive', 'N/A', OfficeOfManagmentAndBudget)}


    
  </Col>



        
             <Col>

            





              <h5 id='presidentialFeedHeadingMobile' onClick={() => setHouseCommitteeOnEnergyAndCommerceModalIsOpen(true)}>House Committee on Energy and Commerce</h5>




             


                 <div class='courtNewsMobile' id='houseCommitteeOnEnergyAndCommmerce'></div>
                 {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=IF00', 'houseCommitteeOnEnergyAndCommmerce', 'House Committee on Energy and Commerce', 'Legislative', 'Economy', HouseCommitteeOnEnergyAndCommerce)}


                
               </Col>

               <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setHouseCommitteeOnEnergyAndCommerceModalIsOpen(true)}>House Committee on Energy and Commerce Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnEnergyAndCommerceMaterials'></div>
                        
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Energy%20and%20Commerce.xml', 'houseCommitteeOnEnergyAndCommerceMaterials', 'House Committee on Energy and Commerce Materials', 'Legislative', 'N/A', HouseCommitteeOnEnergyAndCommerceMaterials)}

                </Col>


                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setInterstateMaterialsModalIsOpen(true)}>House Committee on Interstate and Foreign Commerce Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnInterstateAndForeignCommerceMaterials'></div>
                      


                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Interstate%20and%20Foreign%20Commerce.xml', 'houseCommitteeOnInterstateAndForeignCommerceMaterials', 'House Committee on Interstate and Foreign Commerce Materials', 'Legislative', 'N/A', HouseCommitteeOnInterstateAndForeignCommerceMaterials)}


                </Col>

         



              
                       <Modal id='fullBillModal' show={houseCommitteeOnEnergyAndCommerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnEnergyAndCommerceModalIsOpen(false)}>
                         <Modal.Header id='billModalHeader' closeButton><h3>House Committee on Energy and Commerce</h3></Modal.Header>
                         <HouseCommitteeOnEnergyAndCommerce />
                       </Modal>






            
       


           
            <Col>




            <h5 id='presidentialFeedHeadingMobile' onClick={() => setHouseCommitteeOnTheBudgetModalIsOpen(true)}>House Committee on the Budget</h5>






                 <div class='courtNewsMobile' id='houseCommitteeOnTheBudget'></div>
                 {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=BU00', 'houseCommitteeOnTheBudget', 'House Committee on the Budget', 'Legislative', 'Economy', HouseCommitteeOnTheBudget)}


                 
               </Col>

               <Col>
                    
                    <h5 id='presidentialFeedHeadingMobile' onClick={() => setBudgetMaterialsModalIsOpen(true)}>House Committee on the Budget Materials</h5>
                  <div class='courtNewsMobile'id='houseCommitteeOnTheBudgetMaterials'></div>
                 
                  {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20the%20Budget.xml', 'houseCommitteeOnTheBudgetMaterials', 'House Committee on the Budget Materials', 'Legislative', 'Economy', HouseCommitteeOnTheBudgetMaterials)}


                    </Col>





 
                           <Modal id='fullBillModal' show={houseCommitteeOnTheBudgetModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnTheBudgetModalIsOpen(false)}>
                             <Modal.Header id='billModalHeader' closeButton><h3>House Committee on the Budget</h3></Modal.Header>
                             <HouseCommitteeOnTheBudget/>
                           </Modal>






           
       


           
             <Col>



<h5 id='presidentialFeedHeadingMobile' onClick={() => setHouseCommitteeOnEducationAndLaborModalIsOpen(true)}>House Committee on Education and Labor</h5>




    


                 <div class='courtNewsMobile' id='houseCommitteeOnEducationAndLabor'></div>

                 {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=ED00', 'houseCommitteeOnEducationAndLabor', 'House Committee on Education and Labor', 'Legislative', 'Economy', HouseCommitteeOnEducationAndLabor)}

                 
               </Col>

               <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setEducationMaterialsModalIsOpen(true)}>House Committee on Education and Labor Materials</h5>
                          <div class='courtNewsMobile'id='houseCommitteeOnEducationAndLaborMaterials'></div>
                          
                          {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Education%20and%20the%20Workforce.xml', 'houseCommitteeOnEducationAndLaborMaterials', 'House Committee on Education and Labor Materials', 'Legislative', 'N/A', HouseCommitteeOnEducationAndLaborMaterials)}

                </Col>




           
                           <Modal id='fullBillModal' show={houseCommitteeOnEducationAndLaborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnEducationAndLaborModalIsOpen(false)}>
                             <Modal.Header id='billModalHeader' closeButton><h3>House Committee on Education and Labor</h3></Modal.Header>
                             <HouseCommitteeOnEducationAndLabor />
                           </Modal>






           
        


         
            <Col>




            <h5 id='presidentialFeedHeadingMobile' onClick={() => setHouseCommitteeOnFinancialServicesModalIsOpen(true)}>House Committee on Financial Services</h5>




              


                 <div class='courtNewsMobile' id='houseCommitteeOnFinancialServices'></div>

                 {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=BA00', 'houseCommitteeOnFinancialServices', 'House Committee on Financial Services', 'Legislative', 'Economy', HouseCommitteeOnFinacialServices)}

                 
               </Col>

               <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setFinancialServicesMaterialsModalIsOpen(true)}>House Committee on Financial Services Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnFinancialServicesMaterials'></div>
                      



                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Financial%20Services.xml', 'houseCommitteeOnFinancialServicesMaterials', 'House Committee on Financial Services Materials', 'Legislative', 'Economy', HouseCommitteeOnFinacialServicesMaterials)}

                </Col>

                <Col>

           


<h5 id='presidentialFeedHeadingMobile' onClick={() => setHouseCommitteeOnSmallBusinessModalIsOpen(true)}>House Committee on Small Business</h5>
                 
                 <div class='courtNewsMobile' id='houseCommitteeOnSmallBusiness'></div>
                 {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=SM00', 'houseCommitteeOnSmallBusiness', 'House Committee on Small Business', 'Legislative', 'Economy', HouseCommitteeOnSmallBusiness)}

                 </Col>


                 <Col>
                    
                    <h5 id='presidentialFeedHeadingMobile' onClick={() => setSmallBusinessMaterialsModalIsOpen(true)}>House Committee on Small Business Materials</h5>
                      <div class='courtNewsMobile'id='houseCommitteeOnSmallBusinessMaterials'></div>
         
                      {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Small%20Business.xml', 'houseCommitteeOnSmallBusinessMaterials', 'House Committee on Small Business Materials', 'Legislative', 'Economy', HouseCommitteeOnSmallBusinessMaterials)}

                          </Col>

                          <Modal id='fullBillModal' show={smallBusinessMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setSmallBusinessMaterialsModalIsOpen(false)}>
                         <Modal.Header id='billModalHeader' closeButton><h3>House Committee on Small Business Materials</h3></Modal.Header>
                         <HouseCommitteeOnSmallBusinessMaterials/>
                       </Modal>




                          <Col>
              
              <h5 id='presidentialFeedHeadingMobile' onClick={() => setSenateCommitteeOnEnergyModalIsOpen(true)}>Senate Committee on Energy and Natural Resources Materials</h5>
                      <div class='courtNewsMobile'id='senateEnergyCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Energy%20and%20Natural%20Resources.xml', 'senateEnergyCommittee', 'Senate Committee on Energy and Natural Resources', 'Legislative', 'N/A', SenateEnergyAndNRCommittee)}

              
              </Col>

              <Modal id='fullBillModal' show={senateCommitteeOnEnergyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setSenateCommitteeOnEnergyModalIsOpen(false)}>
                         <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on Energy and Natural Resources Materials</h3></Modal.Header>
                         <SenateEnergyAndNRCommittee/>
                       </Modal>



               <Col>
<h5 id='presidentialFeedHeadingMobile' onClick={() => setSenateBudgetMaterialsModalIsOpen(true)}>Senate Committee on the Budget Materials</h5>
                      <div class='courtNewsMobile'id='senateBudgetCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20the%20Budget.xml', 'senateBudgetCommittee', 'Senate Committee on the Budget', 'Legislative', 'N/A', SenateBudgetCommittee)}
</Col>

<Modal id='fullBillModal' show={senateBudgetMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setSenateBudgetMaterialsModalIsOpen(false)}>
                         <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on the Budget Materials </h3></Modal.Header>
                         <SenateBudgetCommittee/>
                       </Modal>

               <Col>
<h5 id='presidentialFeedHeadingMobile' onClick={() => setSenateFinaceMaterialsModalIsOpen(true)} >Senate Committee on Finance Materials</h5>
                      <div class='courtNewsMobile'id='senateFinanceCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Finance.xml', 'senateFinanceCommittee', 'Senate Committee on Finance', 'Legislative', 'N/A', SenateFinanceCommittee)}

</Col>

<Modal id='fullBillModal' show={senateFinanceMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setSenateFinaceMaterialsModalIsOpen(false)}>
                         <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on Finance Materials </h3></Modal.Header>
                         <SenateFinanceCommittee/>
                       </Modal>




            
                       <Modal id='fullBillModal' show={houseCommitteeOnFinancialServicesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnFinancialServicesModalIsOpen(false)}>
                         <Modal.Header id='billModalHeader' closeButton><h3>House Committee on Financial Services</h3></Modal.Header>
                         <HouseCommitteeOnFinacialServices />
                       </Modal>






                       <Col>
              
              <h5 id='presidentialFeedHeadingMobile' onClick={() => setSenateTransportationMaterialsModalIsOpen(true)}>Senate Committee on Commerce, Science, and Transportation Materials</h5>
                      <div class='courtNewsMobile'id='senateCommerceScienceAndTransportationCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Commerce,%20Science,%20and%20Transportation.xml', 'senateCommerceScienceAndTransportationCommittee', 'Senate Committee on Commerce, Science, and Transportation', 'Legislative', 'N/A', SenateCommerceScienceCommittee)}

              
              

                        <Modal id='fullBillModal' show={senateTransportationMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setSenateTransportationMaterialsModalIsOpen(false)}>
                         <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on Commerce, Science, and Transportation Materials</h3></Modal.Header>
                         <SenateCommerceScienceCommittee/>
                       </Modal>
              </Col>
      


        
            



                 <Col>


<h5 id='presidentialFeedHeadingMobile'onClick={() => setSenateSmallBusinessMaterialsModalIsOpen(true)}> Senate Committee on Small Business and Entrepreneurs Materials</h5>
                      <div class='courtNewsMobile'id='senateSmallBusinessCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Small%20Business%20and%20Entrepreneurship.xml', 'senateSmallBusinessCommittee', 'Senate Committee on Small Business and Entrepreneurs', 'Legislative', 'N/A', SenateSmallBusinessCommittee)}


</Col>

<Modal id='fullBillModal' show={senateSmallBusinessMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setSenateSmallBusinessMaterialsModalIsOpen(false)}>
                         <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on Small Business and Entrepreneurs Materials</h3></Modal.Header>
                         <SenateSmallBusinessCommittee/>
                       </Modal>






            
                       <Modal id='fullBillModal' show={houseCommitteeOnSmallBusinessModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnSmallBusinessModalIsOpen(false)}>
                         <Modal.Header id='billModalHeader' closeButton><h3>House Committee on Business</h3></Modal.Header>
                         <HouseCommitteeOnSmallBusiness />
                       </Modal>






          
          



  <Col>
              <h5 id='presidentialFeedHeadingMobile' onClick={() => setSenateHousingMaterialsModalIsOpen(true)}>Senate Committee on Banking, Housing, and Urban Affairs	Materials</h5>
                      <div class='courtNewsMobile'id='senateBankingHousingUrbanCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Appropriations.xml', 'senateBankingHousingUrbanCommittee', 'Senate Committee on Banking, Housing, and Urban Affairs', 'Legislative', 'Economy', SenateBankingHousingUrbanCommittee)}

              
              
              </Col>

              <Modal id='fullBillModal' show={senateHousingMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setSenateHousingMaterialsModalIsOpen(false)}>
                         <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on Banking, Housing, and Urban Affairs Materials</h3></Modal.Header>
                         <SenateBankingHousingUrbanCommittee/>
                       </Modal>





                       <Modal id='fullBillModal' show={congressionalBudgetOfficeModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCongressionalBudgetOfficeModalIsOpen(false)}>
                         <Modal.Header id='billModalHeader' closeButton><h3>Congressional Budget Office</h3></Modal.Header>
                         <CongressionalBudgetOffice />
                       </Modal>






           
 














         




          




           <Col>

           </Col>
     
           <Navbar id='navbarMobileBottom' fixed="bottom">  

           


<div id='navbarSpacer'></div><div id='navbarSpacer'></div>





       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
              
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
         
          </div>

          </>
  )
}
 }



  






export default EconomyMobile;

