import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Container, Card, Spinner, Button, Modal, Form, Row, Image, Toast, Navbar, Dropdown } from 'react-bootstrap';
import { InfoCircle, FileText, FolderPlus, Clipboard, ClipboardCheck, PlayCircle, BoxArrowUp } from 'react-bootstrap-icons';
import './App.css';
import DesktopNavbar from './DesktopNavbar';
import useHandleContainerClick from './UseHandleContainerClick';
import { BrowserView, MobileView } from 'react-device-detect';
import MobileTopNavbar from './MobileTopNavbar';
import MobileOffCanvas from './MobileOffCanvas';
import UKDesktopNavbar from './UK/UKDesktopNavbar';
import CanadaDesktopNavbar from './Canada/CanadaDesktopNavbar';
import MexicoDesktopNavbar from './Mexico/MexicoDesktopNavbar';

require('dotenv').config();

const apiKey = process.env.REACT_APP_API_KEY;

var storedTitle = '';
var storedDetailsLink = '';
var storedDocLink = '';
var storedDate = '';
var storedNote = '';
var storedFeedName = '';
var storedBranchName = '';
var storedTopicName = '';
var storedDescription = '';
var collectionCounter = localStorage.getItem('collectionNumber');

// Save the selected feed item data
function SaveFeedItem(
  saveCollectionTitle,
  saveCollectionDetailLink,
  saveCollectionDocLink,
  saveCollectionDate,
  saveCollectionNote,
  saveCollectionFeedName,
  saveCollectionBranchName,
  saveCollectionTopicName,
  saveCollectionDescription
) {
  storedTitle = saveCollectionTitle;
  storedDetailsLink = saveCollectionDetailLink;
  storedDocLink = saveCollectionDocLink;
  storedDate = saveCollectionDate;
  storedNote = saveCollectionNote;
  storedFeedName = saveCollectionFeedName;
  storedBranchName = saveCollectionBranchName;
  storedTopicName = saveCollectionTopicName;
  storedDescription = saveCollectionDescription;
}

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  const day = utcDate.getDate();
  const month = utcDate.getMonth() + 1;
  const year = utcDate.getFullYear();

  return `${month}-${day}-${year}`;
}
function getMostRecentActionDate(actions) {
  if (!actions || actions.length === 0) return null;

  let mostRecentAction = null;
  for (let action of actions) {
    if (action.action_description) {
      if (!mostRecentAction || new Date(action.action_date) > new Date(mostRecentAction.action_date)) {
        mostRecentAction = action;
      }
    }
  }

  if (!mostRecentAction) return null;

  // Adjust for timezone offset to ensure correct date
  const actionDate = new Date(mostRecentAction.action_date + 'T00:00:00Z');
  actionDate.setMinutes(actionDate.getMinutes() + actionDate.getTimezoneOffset());
  
  // Format date as MM-DD-YYYY
  const formattedDate = `${actionDate.getMonth() + 1}-${actionDate.getDate()}-${actionDate.getFullYear()}`;

  return formattedDate;
}


function FeedDetailsPage() {
  const { schema, table, id } = useParams(); // Extract URL parameters
  const [data, setData] = useState(null);
  const [membersData, setMembersData] = useState([]); // Store member data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
  const [note, setNote] = useState(''); // State to hold the note input
  const [modalIsOpen, setModalIsOpen] = useState(false); // For sponsor modal
  const [showToast, setShowToast] = useState(false); // For share button toast notification
  const [toastPosition, setToastPosition] = useState({ top: 0, left: 0 }); // Toast position for share button
  const handleContainerClick = useHandleContainerClick();
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false); // Share modal state
  const [shareLink, setShareLink] = useState(''); // Share link
  const [linkCopied, setLinkCopied] = useState(false); // Track if link is copied
  const history = useHistory();
  const [sortBy, setSortBy] = useState('all'); // Default to showing all votes
  // Fetch sponsor (members) data
  useEffect(() => {
    const fetchMembersData = async () => {
      try {
        const response = await fetch('https://api.govglance.org/members/?current_member=true&order_by=state&limit=541&skip=0', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + apiKey,
          },
        });
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const members = await response.json();
        setMembersData(members); // Store the fetched members data

      } catch (error) {
        console.error('Error fetching members data:', error);
      }
    };

    fetchMembersData();
  }, []);

  useEffect(() => {
    const filterColumn = table === 'all_member_votes' ? 'roll_call_number' : 'id';
    const limit = table === 'all_member_votes' ? 435 : 1; // Set limit based on table
    const url = `https://api.govglance.org/posts/recent?limit=${limit}&skip=0&schema=${schema}&table=${table}&order_by=created_at&filter_column=${filterColumn}&filter_string=${id}`;

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiKey}`,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        return response.json();
      })
      .then((data) => {
        setData(data); // Set the detailed data for the selected item
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [schema, table, id]);

  const handleNativeShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: '',
          text: '',
          url: shareLink,
        });
     
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      alert('Sharing is not supported on this device.');
    }
  };

  // Save note function
  function saveNote(
    saveCollectionTitle,
    saveCollectionDetailLink,
    saveCollectionDocLink,
    saveCollectionDate,
    saveCollectionFeedName,
    saveCollectionBranchName,
    saveCollectionTopicName,
    saveCollectionDescription
  ) {
    collectionCounter = localStorage.getItem('collectionNumber');
    collectionCounter = collectionCounter ? parseInt(collectionCounter) + 1 : 1;

    localStorage.setItem('collectionNumber', collectionCounter);
    localStorage.setItem(`title${collectionCounter}`, saveCollectionTitle);
    localStorage.setItem(`detailLink${collectionCounter}`, saveCollectionDetailLink);
    localStorage.setItem(`docLink${collectionCounter}`, saveCollectionDocLink);
    localStorage.setItem(`itemNote${collectionCounter}`, note);
    localStorage.setItem(`itemDate${collectionCounter}`, saveCollectionDate);
    localStorage.setItem(`feedName${collectionCounter}`, saveCollectionFeedName);
    localStorage.setItem(`branchName${collectionCounter}`, saveCollectionBranchName);
    localStorage.setItem(`topicName${collectionCounter}`, saveCollectionTopicName);
    localStorage.setItem(`itemDescription${collectionCounter}`, saveCollectionDescription);

    setCollectionModalIsOpen(false);
    setNote(''); // Clear the note input
  }

  // Handle Share Button click and show toast notification
  const handleShareClick = (event) => {
    const shareUrl = `${window.location.origin}/feed/${schema}/${table}/${id}`;
    setShareLink(shareUrl); // Set share link for modal
    setShareModalIsOpen(true); // Open share modal
  };

  // Function to copy the share link to clipboard
  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink).then(() => {
      setLinkCopied(true);
      setTimeout(() => setLinkCopied(false), 3000); // Reset after 3 seconds
    });
  };

  

  function renderCongressionalBillsMembers(item) {
    return (
      <div id="sponsorsSection" className="mt-3">
        <Card.Title id="billText">Sponsors and Cosponsors:</Card.Title>
        <div className="sponsors-list">
          {item.members && item.members.map((member, index) => {
            const matchedMember = membersData.find(
              (m) => m.bio_guide_id === member.bioGuideId
            );

            if (!matchedMember) {
     
              return null;
            }
  
            const imageUrl = matchedMember.depication?.imageUrl || 'path/to/default/image.jpg';
            const firstName = matchedMember.first_name || '';
            const lastName = matchedMember.last_name || '';
            const role = member.role;
            const party = member.party;
            const state = member.state;
  
            return (
              <div key={index} className="sponsorItem" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', gap: '20px' }}
              onClick={() => {
                handleContainerClick(
                  matchedMember.bio_guide_id,
                  `${firstName} ${lastName}`,
                  matchedMember.official_website_url || '',
                  imageUrl,
                  matchedMember.terms?.[matchedMember.terms.length - 1]?.chamber || '',
                  matchedMember.terms?.[matchedMember.terms.length - 1]?.startYear || '',
                  matchedMember.terms?.[matchedMember.terms.length - 1]?.stateName || '',
                  matchedMember.party_history[0] || {},
                  matchedMember.address_information || {}
                );
              }}>
                <div id='profileImageRep'>
                  <Image id='profileImage-image'
                    src={imageUrl}
                    alt={`${firstName} ${lastName}`}
                    style={{ marginRight: '20px' }}
                  />
                </div>
                <p style={{ marginBottom: '0', color: 'white', display: 'flex', alignItems: 'center' }}>
                  <strong>{firstName} {lastName} - {role} ({party}-{state})</strong>
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
  }


  function renderAllMemberVotes() {
  

    // Tally Yeas and Nays
    const tally = { yeas: 0, nays: 0 };

    // Count the votes
    data.forEach((voteItem) => {
        if (voteItem.vote === 'Yea') {
            tally.yeas += 1;
        } else if (voteItem.vote === 'Nay') {
            tally.nays += 1;
        }
    });

    // Filter votes based on selected option (All, Yeas, or Nays)
    let filteredVotes = [...data];
    if (sortBy === 'yea') {
        filteredVotes = data.filter((voteItem) => voteItem.vote === 'Yea');
    } else if (sortBy === 'nay') {
        filteredVotes = data.filter((voteItem) => voteItem.vote === 'Nay');
    }

    return (
        <div id="votesSection" className="mt-3">
            <Card.Title id="billText">Votes by Members:</Card.Title>

            {/* Display the tally of Yeas and Nays */}
            <div className="vote-tally">
                <p id="billText">Total Yeas: {tally.yeas}</p>
                <p id="billText">Total Nays: {tally.nays}</p>
            </div>

            {/* Dropdown for sorting by Yeas, Nays, or All votes */}
            <Dropdown >
                <Dropdown.Toggle id="navbarAboutButtonDesktop">
                    Sort by: {sortBy === 'all' ? 'All Votes' : sortBy === 'yea' ? 'Yeas' : 'Nays'}
                </Dropdown.Toggle>

                <Dropdown.Menu id="docsDropdown">
                    <Dropdown.Item  id="docsDropdownItem" onClick={() => setSortBy('all')}>All Votes</Dropdown.Item>
                    <Dropdown.Item id="docsDropdownItem" onClick={() => setSortBy('yea')}>Yeas</Dropdown.Item>
                    <Dropdown.Item id="docsDropdownItem" onClick={() => setSortBy('nay')}>Nays</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <br></br>

            <div className="votes-list">
                {filteredVotes.map((voteItem, voteIndex) => {
                    const matchedMember = membersData.find(
                        (m) => m.bio_guide_id === voteItem.bio_guide_id
                    );

                    if (!matchedMember) {
         
                        return null;
                    }

                    const imageUrl = matchedMember.depication?.imageUrl || 'path/to/default/image.jpg';
                    const firstName = matchedMember.first_name || '';
                    const lastName = matchedMember.last_name || '';
                    const vote = voteItem.vote;
                    const party = matchedMember.party_history[0]?.partyAbbreviation || 'Unknown'; // Get latest party abbreviation
                    const state = matchedMember.state || 'Unknown';

                    return (
                        <div 
                            key={voteIndex} 
                            className="voteItem" 
                            style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', gap: '20px' }} 
                            onClick={() => {
                                handleContainerClick(
                                    matchedMember.bio_guide_id,
                                    `${firstName} ${lastName}`,
                                    matchedMember.official_website_url || '',
                                    imageUrl,
                                    matchedMember.terms?.[matchedMember.terms.length - 1]?.chamber || '',
                                    matchedMember.terms?.[matchedMember.terms.length - 1]?.startYear || '',
                                    matchedMember.terms?.[matchedMember.terms.length - 1]?.stateName || '',
                                    matchedMember.party_history[0] || {},
                                    matchedMember.address_information || {}
                                );
                            }}
                        >
                            <div id='profileImageRep'>
                                <Image 
                                    id='profileImage-image'
                                    src={imageUrl}
                                    alt={`${firstName} ${lastName}`}
                                    style={{ marginRight: '20px' }}
                                />
                            </div>
                            <p style={{ marginBottom: '0', color: 'white', display: 'flex', alignItems: 'center' }}>
                                <strong>{firstName} {lastName} ({party}-{state}) </strong> -  Vote: {vote}
                            </p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
  



function renderData() {
  if (loading) {
      return (
          <div id="homebg">
              <Spinner animation="grow" variant="light" />
          </div>
      );
  }

  if (error) {
      return (
          <div id="homebg">
              <p>Error fetching data: {error.message}</p>
          </div>
      );
  }

  if (!data || data.length === 0) {
      return (
          <div id="fullBillFeed">
              <p>No results found.</p>
          </div>
      );
  }

  // Main item details
  const { title, url, download, document_link, created_at: createdAt, actions, committees, members, description, status_title, saved_amount, award_amount, amount, annual_lease, saving_type } = data[0];
  const link = url
  const docLink = download?.pdfLink || document_link;
  const date = formatDate(createdAt);
  const latestBillAction = data[0].bill_status_data?.bill_latest_action_description || 'No bill status available';
  const titleToDisplay = table === 'x_account_1856751787644260354_posts'
  ? description
  : title || status_title;
  const awardAmount = award_amount || amount || annual_lease || '';
  const savedAmount = saved_amount || '';
  const type = saving_type;
  return (
      <div id="feedPageFeedNormalView" className="d-flex align-items-center justify-content-center">
          <Container id="feedContainerNormalView">
              {/* Title */}
              <Card.Title
                  id="billTextMore"
                  onClick={(e) => {
                      e.stopPropagation();
                      const linkToOpen = docLink || url;
                      window.open(linkToOpen, '_blank');
                  }}
                  style={{ cursor: 'pointer' }}
              >
                  {titleToDisplay}
              </Card.Title>

              {/* Date */}
              <Card.Body id="billBodyText">
              {table === 'congressional_bills' && getMostRecentActionDate(actions) 
    ? getMostRecentActionDate(actions) 
    : date}
      </Card.Body>

      {((table === 'recent_awards' || table === 'doge_savings')) && (
  <Card.Body id='billBodyText'>
    {type}
  </Card.Body>
)}
    {/* Full Description */}
    {description && table !== 'x_account_1856751787644260354_posts' && (
  <div id="descriptionSection" className="mt-3">
    <Card.Body id="billBodyText">{description}</Card.Body>
  </div>
)}

{((table === 'recent_awards' || table === 'doge_savings') && awardAmount
                    && awardAmount != '0' &&
                    awardAmount !== '' &&
                    Number(awardAmount) !== 0) && (
  <Card.Body id='billBodyText'>
    Total value: ${Number(awardAmount).toLocaleString()}
  </Card.Body>
)}

{((table === 'recent_awards' || table === 'doge_savings') && 
  savedAmount && 
  savedAmount !== '0' && 
  savedAmount !== '' && 
  parseFloat(savedAmount) > 0) && (
  <Card.Body id='billBodyText'>
    Savings: ${parseFloat(savedAmount).toLocaleString()}
  </Card.Body>
  )}
              {/* Shared Action Buttons */}



              {/* Conditional Rendering for congressional_bills Specific Elements */}
              {(table === 'congressional_bills' || table === 'congressional_bills_enrolled' || table === 'public_and_private_laws') && (
                  <>
                

                      {/* Bill Actions */}
                      {actions && actions.length > 0 && (
    <div id="actionsSection" className="mt-3">
        <Card.Title id="billText">Actions:</Card.Title>
        {actions.map((action, index) => (
            action.action_description ? ( // Check if action_description is not null
                <Card.Body id="billBodyText" key={index}>
                    <strong>{formatDate(action.action_date)}</strong> - {action.action_description}
                </Card.Body>
            ) : null
        ))}
    </div>
)}

                      {/* Committees */}
                      {committees && committees.length > 0 && (
                          <div id="committeesSection" className="mt-3">
                              {/* <Card.Title id="billText">Committees:</Card.Title> */}
                              {committees.map((committee, index) => (
                                  <Card.Body id="billBodyText" key={index}>
                                      {committee.committeeName} ({committee.chamber})
                                  </Card.Body>
                              ))}
                          </div>
                      )}
{/* Buttons based on table value */}






                      {/* Sponsors and Cosponsors */}
                      {members && members.length > 0 && renderCongressionalBillsMembers(data[0])}
                  </>
              )}

<div style={{ display: 'flex', gap: '10px' }}>

{/* Source Button - Only show if a valid link exists */}
{link ? (
  <Button className='clickable'
    id="billLink"
    onClick={(e) => {
      e.stopPropagation();
      window.open(link, '_blank');
    }}
  >
    <InfoCircle /> Source
  </Button>
) : (
  console.log(`No Source link found for table: ${table}`)
)}

{/* Document Button - Only show if a valid document link exists */}
{(download?.pdfLink || document_link || docLink) ? (
  <Button className='clickable'
    id="viewBillButton"
    onClick={(e) => {
      e.stopPropagation();
      const docToOpen = download?.pdfLink || document_link || docLink;
      window.open(docToOpen, '_blank');
    }}
  >
    <FileText /> Document
  </Button>
) : (
  console.log(`No Document link found for table: ${table}`)
)}

{/* Add to Collection Button - Always Display */}
<Button className='clickable'
  id="addToCollectionButton"
  onClick={(e) => {
    e.stopPropagation();
    setCollectionModalIsOpen(true);
    SaveFeedItem(titleToDisplay, link, download?.pdfLink || document_link || docLink, date, '', schema, table, description);
  }}
>
  <FolderPlus />
</Button>

{/* Share Button - Always Display */}
<Button className='clickable'
  id="shareButton"
  onClick={(e) => {
    e.stopPropagation();
    handleShareClick(e);
  }}
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-send"
    viewBox="0 0 16 16"
  >
    <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
  </svg>
</Button>
</div>

              {/* Render all_member_votes differently */}
              {table === 'all_member_votes' && renderAllMemberVotes(data[0])}

          </Container>
      </div>
  );
}

  
  return (
    <>
      <BrowserView>
        <div id='homebg'>
        {schema === 'mexico' ? (
  <MexicoDesktopNavbar />
) : schema === 'canada' ? (
  <CanadaDesktopNavbar />
) : schema === 'united_kingdom' ? (
  <UKDesktopNavbar />
) : (
  <DesktopNavbar />
)}

          {renderData()}

          {/* Toast Notification for "Copied to Clipboard" */}
          <Toast 
            style={{ position: 'absolute', top: toastPosition.top, left: toastPosition.left, zIndex: 1000, backgroundColor: '#002031' }} 
            onClose={() => setShowToast(false)} 
            show={showToast} 
            delay={3000} 
            autohide
          >
            <Toast.Body id='billBodyText'>Copied to clipboard!</Toast.Body>
          </Toast>

          <Modal
            id="fullBillModal"
            show={collectionModalIsOpen}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => setCollectionModalIsOpen(false)}
          >
            <Modal.Header id="billModalHeader" closeButton>
              <h3>{storedTitle}</h3>
            </Modal.Header>
            <Modal.Body id="descriptionModalBody">
              {storedDescription}
              {storedDate}
              <div id="fullBill">
                <Form id="noteModalForm">
                  <Form.Group id="noteModalForm" className="mb-3">
                    <Form.Control
                      id="noteModalFeedItem"
                      placeholder="Add a note here"
                      type="text"
                      as="textarea"
                      rows={2}
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    />
                    <br />
                  </Form.Group>
                </Form>

                <Modal.Footer id="addNoteModalFooter">
                  <Button className='clickable'
                    variant="primary"
                    id="noteSaveToCollectionButton"
                    onClick={() => saveNote(storedTitle, storedDetailsLink, storedDocLink, storedDate, schema, table, storedTopicName, storedDescription)}
                  >
                    Save To Collection
                  </Button>
                </Modal.Footer>
              </div>
            </Modal.Body>
          </Modal>

          {/* Share Modal */}
          <Modal
            id="shareModal"
            show={shareModalIsOpen}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => setShareModalIsOpen(false)}
          >
            <Modal.Header id="billModalHeader" closeButton>
              <h3>Share</h3>
            </Modal.Header>
            <Modal.Body id="descriptionModalBody">
              
              <Form.Group id="noteModalForm">
                <Form.Control
              
                  id="noteModalFeedItem"
                  type="text"
                  readOnly
                  value={shareLink}
                />
              </Form.Group>
              <Modal.Footer id="shareFooter">
                <Button className='clickable' id="addToCollectionButton" onClick={handleCopyLink}>
                  {linkCopied ? <ClipboardCheck /> : <Clipboard />}
                </Button>
              </Modal.Footer>
            </Modal.Body>
          </Modal>
        </div>
      </BrowserView>

      <MobileView>
        <div id='homebg'>
          <MobileTopNavbar/>

          {renderData()}

          {/* Toast Notification for "Copied to Clipboard" */}
          <Toast 
            style={{ position: 'absolute', top: toastPosition.top, left: toastPosition.left, zIndex: 1000, backgroundColor: '#002031' }} 
            onClose={() => setShowToast(false)} 
            show={showToast} 
            delay={3000} 
            autohide
          >
            <Toast.Body id='billBodyText'>Copied to clipboard!</Toast.Body>
          </Toast>

          <Modal
            id="fullBillModal"
            show={collectionModalIsOpen}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => setCollectionModalIsOpen(false)}
          >
            <Modal.Header id="billModalHeader" closeButton>
              <h3>{storedTitle}</h3>
            </Modal.Header>
            <Modal.Body id="descriptionModalBody">
              {storedDescription}
              {storedDate}
              <div id="fullBill">
                <Form id="noteModalForm">
                  <Form.Group id="noteModalForm" className="mb-3">
                    <Form.Control
                      id="noteModalFeedItem"
                      placeholder="Add a note here"
                      type="text"
                      as="textarea"
                      rows={2}
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    />
                    <br />
                  </Form.Group>
                </Form>

                <Modal.Footer id="addNoteModalFooter">
                  <Button className='clickable'
                    variant="primary"
                    id="noteSaveToCollectionButton"
                    onClick={() => saveNote(storedTitle, storedDetailsLink, storedDocLink, storedDate, schema, table, storedTopicName, storedDescription)}
                  >
                    Save To Collection
                  </Button>
                </Modal.Footer>
              </div>
            </Modal.Body>
          </Modal>

          {/* Share Modal */}
          <Modal
            id="fullBillModal"
            show={shareModalIsOpen}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => setShareModalIsOpen(false)}
          >
            <Modal.Header id="billModalHeader" closeButton>
              <h3>Share</h3>
            </Modal.Header>
            <Modal.Body id="descriptionModalBody">
            <div id="fullBill">
            <Form id="noteModalForm">
              <Form.Group id="noteModalForm">
                <Form.Control
          
                  id="noteModalFeedItem"
                  type="text"
                  readOnly
                  value={shareLink}
                />
              </Form.Group>
              </Form>
              </div>
              <Modal.Footer id="shareFooter">
              <Button className='clickable' 
  id="addToCollectionButton" 
  onClick={() => handleNativeShare()}
>
<BoxArrowUp/>
</Button>

                <Button className='clickable' id="addToCollectionButton" onClick={handleCopyLink}>
                  {linkCopied ? <ClipboardCheck /> : <Clipboard />}
                </Button>
              </Modal.Footer>
            </Modal.Body>
          </Modal>
          <br></br><br></br><br></br><br></br><br></br><br></br>
          <Navbar id='navbarMobileBottom' fixed="bottom">    
            <div id='changeBranchDropdownMobile'>
              <MobileOffCanvas/>
            </div>
          </Navbar>
        </div>
      </MobileView>
    </>
  );
}

export default FeedDetailsPage;
