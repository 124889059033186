import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { ChevronRight, Files } from 'react-bootstrap-icons';
import { Link, useHistory } from 'react-router-dom';

import { CreateFeedItemStateRepsAll } from './Functions';


const handleHapticsClick = async (callback) => {
  window.scrollTo(0, 0)

  ;
};

const NewCarousels = ({ feeds }) => {
  const history = useHistory();
  const [modalState, setModalState] = useState({});

  const openModal = (id) => setModalState({ ...modalState, [id]: true });
  const closeModal = (id) => setModalState({ ...modalState, [id]: false });

  // Function to handle "View More" click
  const handleFeedClick = (apiParams, feedName, feedBranch, feedTopic, feedButtonName) => {
    const urlParams = new URLSearchParams(apiParams);
    const schema = urlParams.get('schema') || 'default_schema';
    const table = urlParams.get('table') || 'default_table';
    window.scrollTo(0, 0)
    history.push({
      pathname: '/feed',
      state: {
        apiURL: apiParams,
        feedName,
        feedBranch,
        feedTopic,
        feedButtonName,
        schema,
        table,
      },
    });
  };

  // Function to create multiple cards for normal displays
  const createCardsFromNormalFeed = (feed) => {
    const cards = [];
    
    // Create 4 cards with incremented skip values
    for (let i = 0; i < 4; i++) {
      const cardId = `${feed.id}-card-${i}`;
      
      // Create a copy of the feed for this card
      const cardFeed = {
        ...feed,
        id: cardId,
      };
      
      // Update apiParams to use the proper skip value
      if (cardFeed.apiParams.includes('skip=')) {
        // Replace existing skip parameter
        cardFeed.apiParams = cardFeed.apiParams.replace(/skip=\d+/, `skip=${i}`);
      } else {
        // Add skip parameter if it doesn't exist
        cardFeed.apiParams += `&skip=${i}`;
      }
      
      cards.push(cardFeed);
    }
    
    return cards;
  };

  // Group carousel feeds by their group property
  const groupedFeeds = feeds
    .filter(feed => feed.displayType === 'carousel')
    .reduce((acc, feed) => {
      if (feed.group) {
        if (!acc[feed.group]) {
          acc[feed.group] = {
            displayName: feed.groupDisplayName,
            link: feed.groupLink,
            showGroupButton: feed.showGroupButton || false,
            groupButtonText: feed.groupButtonText || '',
            items: []
          };
        }
        acc[feed.group].items.push(feed);
      }
      return acc;
    }, {});
  
  // Create normal feeds as custom carousels
  const normalFeeds = feeds.filter(feed => feed.displayType === 'normal');
  
  // Helper function to determine which API function to call based on function name
  const renderFeedContent = (feed) => {
    // Handle CreateFeedItemStateRepsAll function specifically
    if (feed.apiFunction === CreateFeedItemStateRepsAll || feed.apiFunction.name === 'CreateFeedItemStateRepsAll') {
      return feed.apiFunction(
        feed.id,
        feed.candidateType, 
        feed.stateAbbreviation, 
        feed.skip
      );
    }
    
    // Standard API function handling
    return feed.apiFunction(
      feed.apiParams,
      `feed-${feed.id}`,
      feed.name,
      feed.feedBranch,
      feed.feedTopic,
      feed.buttonName,
      feed.generateFunction
    );
  };

  return (
   <>
      {/* Render normal feeds as custom carousels */}
      {normalFeeds.map((feed) => {
        const cardsForFeed = createCardsFromNormalFeed(feed);
        
        return (
          <div key={`${feed.id}-group`} className="carouselGroupContainer news-section">
            {/* Heading that triggers handleFeedClick with haptics */}
            {feed.name  && (
              <div 
                onClick={() => handleHapticsClick(() => handleFeedClick(
                  feed.apiParams,
                  feed.name,
                  feed.feedBranch,
                  feed.feedTopic,
                  feed.buttonName
                ))}
                id="pageLink"
                className="carouselGroupTitleLink"
                style={{ cursor: 'pointer' }}
              >
                <h5 className="carousel-heading">
                  <div className="title-container">
                    <span className="heading-text">{feed.name}</span>
                    <ChevronRight className="heading-chevron" />
                  </div>
                </h5>
              </div>
            )}

            <div className="scroll-container">
              <div className="gradient-left" />
              <div className="gradient-right" />
              <div className="scroll-area">
                <div className="card-container">
                  {cardsForFeed.map((cardFeed) => (
                    <div key={cardFeed.id} className="card-wrapper" >
                      <div className="card-content">
                        <div id={cardFeed.id} className="courtNewsMobile">
                          <div id={`feed-${cardFeed.id}`}>
                            {renderFeedContent(cardFeed)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* View All button */}
                  <div className="card-wrapper view-all-card">
                    <div id="viewFullPageButton">
                      <Button 
                        id="viewFullLegislativePageButtonMobile" 
                        size="lg"
                        onClick={() => handleHapticsClick(() => handleFeedClick(
                          feed.apiParams,
                          feed.name,
                          feed.feedBranch,
                          feed.feedTopic,
                          feed.buttonName
                        ))}
                      >
                        {`View all ${feed.name} news`}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Add explicit spacing div after each carousel */}
            <div className="carousel-spacing" />
          </div>
        );
      })}
      
      {/* Render original carousel feeds as before */}
      {Object.entries(groupedFeeds).map(([groupId, group]) => (
        <div key={groupId} id={`carousel-${groupId}`} className="carouselGroupContainer news-section">
          {/* Only render the heading Link if groupDisplayName exists */}
          {group.displayName && (
            <Link
              onClick={() => handleHapticsClick(() => {})}
              id="pageLink"
              to={group.link}
              className="carouselGroupTitleLink"
            >
              <h5 className="carousel-heading">
                <div className="title-container">
                  <span className="heading-text">{group.displayName}</span>
                  <ChevronRight className="heading-chevron" />
                </div>
              </h5>
            </Link>
          )}

          <div className="scroll-container">
            <div className="gradient-left" />
            <div className="gradient-right" />
            <div className="scroll-area">
              <div className="card-container">
                {group.items.map((feed) => (
                  <div key={feed.id} className="card-wrapper" >
                    {feed.name && (
                      <div className="row mobile-feed-heading-row">
                        <h5 
                          className="feed-heading-mobile"
                          onClick={() => handleFeedClick(
                            feed.apiParams,
                            feed.name,
                            feed.feedBranch,
                            feed.feedTopic,
                            feed.buttonName
                          )}
                        >    
                          <div className="title-container">
                            <span className="heading-text">{feed.name}</span>
                            <ChevronRight className="heading-chevron" />
                          </div>
                        </h5>
                      </div>
                    )}
                    <div className="card-content">
                      <div id={feed.id} className="courtNewsMobile">
                        <div id={`feed-${feed.id}`}>
                          {renderFeedContent(feed)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {/* Only show View All button if showGroupButton is true */}
                {group.showGroupButton && (
                  <div className="card-wrapper view-all-card" >
                    <div id="viewFullPageButton">
                      <Link id='pageLink'
                        to={group.link}
                        onClick={() => handleHapticsClick(() => {})}
                      >
                        <Button 
                          id="viewFullLegislativePageButtonMobile" 
                          size="lg"
                        >
                          {group.groupButtonText || `View all ${group.displayName} news`}
                        </Button>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* Add explicit spacing div after each carousel */}
          <div className="carousel-spacing" />

          {/* Modal for each feed */}
          {group.items.map((feed) => (
            modalState[feed.id] && (
              <div 
                key={`modal-${feed.id}`} 
                id={`fullBillModal-${feed.id}`}
                className="modal-overlay" 
                onClick={() => closeModal(feed.id)}
              >
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                  <div className="modal-header">
                    <h3>{feed.modalTitle}</h3>
                    <button className="close-button" onClick={() => closeModal(feed.id)}>×</button>
                  </div>
                  <div className="modal-body">
                    {feed.generateFunction && (
                      <feed.generateFunction
                        url={feed.apiParams}
                        name={feed.name}
                        branch={feed.feedBranch}
                        topic={feed.feedTopic}
                        buttonName={feed.buttonName}
                      />
                    )}
                  </div>
                </div>
              </div>
            )
          ))}
        </div>
      ))}

<style jsx>{`
      /* Base styling */
      .news-section {
        margin-bottom: 2rem;
      }

      /* Text styles - IMPROVED TITLE HANDLING */
      .carousel-heading {
        position: relative;
        padding: 16px 20px 8px;
        margin: 0;
        color: white;
        height: 50px; /* Fixed height for headings */
        overflow: hidden;
      }
      
      .title-container {
        display: inline-block;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      
      /* Style for icons in headings */
      .title-container svg,
      .title-container img,
      #homeStateIcon {
        vertical-align: middle;
        height: 20px;
        width: auto;
        margin-right: 6px;
        display: inline-block;
      }
      
      .heading-text {
        font-family: var(--site-font);
        font-size: clamp(15px, 4vw, 18px); /* Responsive font size */
        line-height: 1.2;
        display: inline;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 20px);
      }
      
      .heading-chevron {
        height: 18px;
        width: auto;
        display: inline-block;
        vertical-align: middle;
        margin-left: 4px; /* Small spacing between text and chevron */
      }

      .mobile-feed-heading-row {
        height: 50px; /* Fixed height */
        overflow: hidden;
        margin: 0;
        padding: 0;
      }
      
      .feed-heading-mobile {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 8px 15px;
        margin: 0;
        color: white;
        cursor: pointer;
        height: 50px; /* Fixed height */
        overflow: hidden;
      }

      .courtNewsMobile > div > * {
        margin-bottom: 0 !important;
      }
      
      /* Container styling */
      #legislativeConMobileNew {
        position: relative;
        margin-top: 0.5em;
        margin-bottom: 1em;
        padding-top: 10px;
        padding-bottom: 20px;
        border-radius: 20px;
        height: 300px; /* Fixed consistent height */
        width: auto;
        overflow: hidden; /* Prevent content from spilling */
      }
      
      /* Scroll container */
      .scroll-container {
        position: relative;
        height: 290px;
      }
      
      .gradient-left, .gradient-right {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 48px;
        z-index: 5;
        pointer-events: none;
      }
      
      .gradient-left {
        left: 0;
      }
      
      .gradient-right {
        right: 0;
      }
      
      .scroll-area {
        box-sizing: border-box;
        height: 290px;
        overflow-x: scroll;
        overflow-y: hidden;
        scroll-snap-type: x mandatory;
        scrollbar-width: none;
        -ms-overflow-style: none;
        padding-bottom: 17px;
      }
      
      .scroll-area::-webkit-scrollbar {
        display: none;
      }
      
      /* Card layout */
      .card-container {
        display: flex;
        padding-left: 10px;
        padding-right: 10px;
        height: 280px;
      }
      
      .card-wrapper {
        flex: 0 0 430px !important; /* Fixed width with !important to override other styles */
   
        padding-right: 1rem;
        background-color: pink
        scroll-snap-align: center;
        box-sizing: border-box; /* Include padding in width/height calculation */
        
      }

      .card-content {
        height: 250px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        overflow: hidden; /* Prevent content overflow */
      }
      
      .courtNewsMobile {

      }
      
      .card-wrapper:first-child {
        padding-left: 1rem;
      }
      
      #feedContainerNormalView > div:last-child {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
      }
      
      #viewFullLegislativePageButtonMobile {
        border: none;
        color: white;
        width: calc(100%);
        text-align: center;
        position: relative;
      }
      
      .close-button {
        background: none;
        border: none;
        font-size: 1.5rem;
        color: white;
        cursor: pointer;
      }
      
      @media screen and (min-width: 768px) {
        .card-wrapper {
          flex: 0 0 320px;
        }
        
        .heading-text {
          font-size: 18px; /* Fixed size for larger screens */
        }
      }
      .carouselGroupContainer:last-child,
.news-section:last-child {


}

.carouselGroupContainer
{
height: 250px;

}

[id^="carousel-recents_canada"] {

height: 240px
}

[id^="carousel-recents_mexico"] {

height: 270px
}
[id^="carousel-recents_uk"] {

height: 270px
}

/* If needed, add specific control for the recents group */
[id^="carousel-recents_all"] {

height: 230px
}
      
      /* Additional responsiveness for very small screens */
      @media screen and (max-width: 350px) {
        .heading-text {
          font-size: 13px;
        }
      }
    `}</style>
    </>
    );
  };
  
  export default NewCarousels;