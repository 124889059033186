import React, { useState, useEffect } from 'react';
import { useHistory,useLocation  } from 'react-router-dom';
import { Container, Button, Modal, Form, Card, Spinner, Toast, Navbar, Row, Nav, Dropdown } from 'react-bootstrap';
import { InfoCircle, FileText, FolderPlus, Clipboard, ClipboardCheck, PlayCircle, BoxArrowUp } from 'react-bootstrap-icons';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import DesktopNavbar from './DesktopNavbar';
import { BrowserView, MobileView } from 'react-device-detect';
import MobileTopNavbar from './MobileTopNavbar';
import MobileOffCanvas from './MobileOffCanvas';
import MexicoDesktopNavbar from './Mexico/MexicoDesktopNavbar';
import CanadaDesktopNavbar from './Canada/CanadaDesktopNavbar';
import UKDesktopNavbar from './UK/UKDesktopNavbar';

require('dotenv').config();

const apiKey = process.env.REACT_APP_API_KEY;

function getMostRecentActionDate(actions) {
  if (!actions || actions.length === 0) return null;

  let mostRecentAction = null;
  for (let action of actions) {
    if (action.action_description) {
      if (!mostRecentAction || new Date(action.action_date) > new Date(mostRecentAction.action_date)) {
        mostRecentAction = action;
      }
    }
  }

  if (!mostRecentAction) return null;

  // Adjust for timezone offset to ensure correct date
  const actionDate = new Date(mostRecentAction.action_date + 'T00:00:00Z');
  actionDate.setMinutes(actionDate.getMinutes() + actionDate.getTimezoneOffset());
  
  // Format date as MM-DD-YYYY
  const formattedDate = `${actionDate.getMonth() + 1}-${actionDate.getDate()}-${actionDate.getFullYear()}`;

  return formattedDate;
}

function FeedPageTest(props) {
  const history = useHistory();
  const location = useLocation(); // Get the location object
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [feedLength, setFeedLength] = useState(20);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
  const [note, setNote] = useState('');
  const [schema, setSchema] = useState('');
  const [table, setTable] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastPosition, setToastPosition] = useState({ top: 0, left: 0 });
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false); // For the share modal
  const [shareLink, setShareLink] = useState(''); // The link to be shared
  const [linkCopied, setLinkCopied] = useState(false); // To track if the link is copied
  const [selectedAgency, setSelectedAgency] = useState('');
  const [awardAmount, setAwardAmount] = useState('');
  // Always extract schema and table from the URL params
  const country = props.match.params.schema; // Assuming schema represents the country
  const { schema: urlSchema, table: urlTable } = props.match.params;
  const [orderBy, setOrderBy] = useState('created_at');
  const [filterColumn, setFilterColumn] = useState('');
const [filterString, setFilterString] = useState('');
  // Extract from URL params or props

  const { apiRequest } = props;

  const queryParams = new URLSearchParams(location.search);
  useEffect(() => {
    // Get schema and table directly from props.match.params
    const urlSchema = props.match.params.schema;
    const urlTable = props.match.params.table;
    
    // Update the state variables with the URL parameters
    setSchema(urlSchema);
    setTable(urlTable);
    
    // Extract query parameters directly from location.search
    const queryParams = new URLSearchParams(location.search);
    const queryOrderBy = queryParams.get('orderBy');
    const queryFilterColumn = queryParams.get('filterColumn');
    const queryFilterString = queryParams.get('filterString');
    
    // Determine the effective orderBy value based on table and query params
    let effectiveOrderBy;
    if (queryOrderBy) {
      // If orderBy is in the URL, use it
      effectiveOrderBy = queryOrderBy;
    } else if (urlTable === 'congressional_bills') {
      // Default for Bill Updates
      effectiveOrderBy = 'action_date';
    } else if (urlTable === 'congressional_bills_enrolled') {
      // Default for Bills Enrolled
      effectiveOrderBy = 'date_issued';
    } else if (urlTable === 'public_and_private_laws') {
      // Default for Laws
      effectiveOrderBy = 'date_issued';
    } else {
      // Default fallback
      effectiveOrderBy = 'created_at';
    }
    
    // Set the orderBy state
    setOrderBy(effectiveOrderBy);
    
    // Set filter parameters if they exist in the URL
    if (queryFilterColumn && queryFilterString) {
      setFilterColumn(queryFilterColumn);
      setFilterString(queryFilterString);
    }
  
    // Special case for DOGE savings with specific filters
    if (urlTable === 'doge_savings') {
      if (queryFilterString === 'Contract' || queryFilterString === 'Grant') {
        // Default for DOGE Contract and Grant cancellations
        if (!queryOrderBy) setOrderBy('uploaded_on');
      } else if (queryFilterString === 'Real Estate') {
        // Default for DOGE Real Estate Cancellations
        if (!queryOrderBy) setOrderBy('annual_lease');
      }
    }
  
    // Build the base URLs - make sure to include skip=0
    const searchUrl = `https://api.govglance.org/search/${urlSchema}/${urlTable}?limit=20&skip=0&search_term=${searchQuery}`;
    
    // Use the effective orderBy for the API request
    let recentUrl = `https://api.govglance.org/posts/recent?limit=${feedLength}&skip=0&schema=${urlSchema}&table=${urlTable}&order_by=${effectiveOrderBy}`;
  
    // console.log('URL params:', urlSchema, urlTable);
    // console.log('Using orderBy:', effectiveOrderBy);
    
    // Add filter parameters
    if (selectedAgency) {
      recentUrl += `&filter_column=agency_id&filter_string=${selectedAgency}`;
    } 
    else if (queryFilterColumn && queryFilterString) {
      recentUrl += `&filter_column=${queryFilterColumn}&filter_string=${queryFilterString}`;
    }
    else if (filterColumn && filterString) {
      recentUrl += `&filter_column=${filterColumn}&filter_string=${filterString}`;
    }
  
    const url = searchPerformed && searchQuery.trim() !== '' ? searchUrl : recentUrl;
    
    // console.log('Fetching from URL:', url);
  
    setLoading(true);
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiKey}`,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        return response.json();
      })
      .then((data) => {
        setData(data);
        setError(null);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, [
    feedLength,
    searchPerformed,
    searchQuery,
    selectedAgency,
    props.match.params,
    location.search
  ]);
  // Updated handleItemClick to open the document link if available
  const handleItemClick = (docLink, id) => {
    if (docLink) {
      window.open(docLink, '_blank');
    } else {
      history.push(`/feed/${schema}/${table}/${id}`);
    }
  };

  const handleAddToCollection = (item) => {
    setSelectedItem(item); // Store the selected item
    setCollectionModalIsOpen(true);
  };

  // Copy URL to clipboard and show toast above the clicked share button
  const handleShareClick = (id, event) => {
    const shareUrl = `${window.location.origin}/feed/${schema}/${table}/${id}`;
    setShareLink(shareUrl);
    setShareModalIsOpen(true);
    setLinkCopied(false); // Reset the link copied state
  };

  // Function to copy the share link to clipboard
  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink).then(() => {
      setLinkCopied(true);
      setTimeout(() => setLinkCopied(false), 3000); // Reset after 3 seconds
    });
  };

  // Save note function
  function saveNote() {
    if (!selectedItem) return;

    const { status_title, title, url, document_link, created_at } = selectedItem;
    const saveCollectionTitle = status_title || title;
    const saveCollectionDetailLink = url;
    const saveCollectionDocLink = document_link;
    const saveCollectionDate = new Date(created_at).toLocaleDateString();

    let collectionCounter = localStorage.getItem('collectionNumber');
    collectionCounter = collectionCounter ? parseInt(collectionCounter) + 1 : 1;

    localStorage.setItem('collectionNumber', collectionCounter);
    localStorage.setItem(`title${collectionCounter}`, saveCollectionTitle);
    localStorage.setItem(`detailLink${collectionCounter}`, saveCollectionDetailLink);
    localStorage.setItem(`docLink${collectionCounter}`, saveCollectionDocLink);
    localStorage.setItem(`itemNote${collectionCounter}`, note);
    localStorage.setItem(`itemDate${collectionCounter}`, saveCollectionDate);
    localStorage.setItem(`feedName${collectionCounter}`, schema);
    localStorage.setItem(`branchName${collectionCounter}`, '');
    localStorage.setItem(`topicName${collectionCounter}`, '');

    setCollectionModalIsOpen(false);
    setNote('');
  }
  const renderDropdown = () => {
    if (table !== 'regulations') return null;
  
    return (
      <Dropdown drop="down-centered">
        <Dropdown.Toggle id="agencyDropdown">Agency</Dropdown.Toggle>
        <Dropdown.Menu id="docsDropdown">
          {[
            "AMS", "BIS", "CMS", "CRB", "CRC", "DARS", "DOT", "ED", "EEOC",
            "EERE", "EPA", "FAA", "FDA", "FEMA", "FERC", "FMC", "FNS", "FRS",
            "FTC", "FWS", "HHS", "ITA", "ITC", "NOAA", "OPM", "PHMSA", "SEC",
            "SRBC", "USPS", "USTR", "VA", "WAPA"
          ].map((agency, index) => (
            <Dropdown.Item
              key={index}
              id="docsDropdownItem"
              onClick={(e) => {
                e.preventDefault();
                setSelectedAgency(agency); // Set the selected agency
              }}
            >
              {agency}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const handleNativeShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: '',
          text: '',
          url: shareLink,
        });
        // console.log('Content shared successfully!');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      alert('Sharing is not supported on this device.');
    }
  };


  const renderData = () => {
    if (loading) {
      return (
        <div id="homebg">
          <Spinner animation="grow" variant="light" />
        </div>
      );
    }
  
    if (error) {
      return (
        <div id="homebg">
          <Spinner animation="grow" variant="light" />
        </div>
      );
    }
  
    return data.map((item, index) => {
      const titleToDisplay = table === 'x_account_1856751787644260354_posts'
      ? item.description
      : item.status_title || item.title;
      const link = item.url;
      const docLink = item.document_link || item.download?.pdfLink || item.pdf_url;
      const audioLink = item.audio_url || '#'; // Use the correct field for the audio link if available
      const id = item.id;
      const rollCallId = item.roll_call_number; // Specific ID for 'all_member_votes'
      const { committees, bill_status_data } = item;
      const awardAmount = item.award_amount || item.amount || item.annual_lease || '';
      
  const date = new Date(item.created_at).toLocaleDateString();

      // Truncate description to 150 characters if it exists
      const truncatedDescription = item.description
        ? item.description.length > 150
          ? item.description.substring(0, 150) + '...'
          : item.description
        : null;
  
      // Truncate latest bill action description to 117 characters if it exists
      const latestBillAction = bill_status_data?.bill_latest_action_description
        ? bill_status_data.bill_latest_action_description.length > 117
          ? bill_status_data.bill_latest_action_description.substring(0, 117) + '...'
          : bill_status_data.bill_latest_action_description
        : null;

      let displayDate;
      if (table === 'congressional_bills') {
        // For congressional bills, use the action date
        displayDate = getMostRecentActionDate(item.actions);
        displayDate = displayDate ? `${displayDate} - ${latestBillAction}` : date;
      } else if (orderBy === 'date_issued' && item.date_issued) {
        // For items using date_issued
        displayDate = new Date(item.date_issued).toLocaleDateString();
      } else if (orderBy === 'uploaded_on' && item.uploaded_on) {
        // For items using uploaded_on
        displayDate = new Date(item.uploaded_on).toLocaleDateString();
      } else if (orderBy === 'annual_lease' && item.uploaded_on) {
        // For items using annual_lease, still display uploaded_on date
        displayDate = new Date(item.uploaded_on).toLocaleDateString();
      } else {
        // Default to created_at
        displayDate = date;
      }
    
   
  
  
      return (
        
        <div id="feedPageFeed" key={index}>
          <Container id="feedContainerNormalView">
            {/* Title now opens the document if docLink exists */}
            <Card.Title
              id="billText"
              onClick={(e) => {
                e.stopPropagation();
               
                if (
                  table === 'congressional_bills_enrolled' ||
                  table === 'congressional_bills' ||
                  table === 'public_and_private_laws'
                ) {
                  history.push(`/feed/${schema}/${table}/${item.id}`);
                } else {
                  window.open(link || docLink, '_blank');
                }
              }}
            >
              {titleToDisplay}
            </Card.Title>
  
            {/* <Card.Body id="billBodyText">
  {table === 'congressional_bills' 
    ? `${getMostRecentActionDate(item.actions)} - ${latestBillAction}`
    : {displayDate}}
</Card.Body> */}

<Card.Body id="billBodyText">
          {displayDate}
        </Card.Body>


{truncatedDescription && (
              <div id="descriptionSection" className="mt-3">
                {/* <Card.Title id="billText">Description:</Card.Title> */}
                {table !== 'x_account_1856751787644260354_posts' && (
  <Card.Body id="billBodyText">
    {truncatedDescription}
  </Card.Body>
)}
              </div>
            )}
  

  {((table === 'recent_awards' || table === 'doge_savings') && awardAmount
                    && awardAmount != '0' &&
                    awardAmount !== '' &&
                    Number(awardAmount) !== 0) && (
  <Card.Body id='billBodyText'>
    Total value: ${Number(awardAmount).toLocaleString()}
  </Card.Body>
)}

{((table === 'recent_awards' || table === 'doge_savings') && 
  item.saved_amount && 
  item.saved_amount !== '0' && 
  item.saved_amount !== '' && 
  parseFloat(item.saved_amount) > 0) && (
  <Card.Body id='billBodyText'>
    Savings: ${parseFloat(item.saved_amount).toLocaleString()}
  </Card.Body>
  )}
                    
           
      
  
            {/* Committees Section */}
            {committees && committees.length > 0 && (
              <div id="committeesSection" className="mt-3">
                {/* <Card.Title id="billText">Committees:</Card.Title> */}
                {committees.map((committee, index) => (
                  <Card.Body id="billBodyText" key={index}>
                    {committee.committeeName} ({committee.chamber})
                  </Card.Body>
                ))}
              </div>
            )}
  
            {/* Conditionally render Latest Bill Action Section if available */}
            {/* {latestBillAction && (
              <div id="billActionSection" className="mt-3">
                <Card.Title id="billText">Latest Action:</Card.Title>
                <Card.Body id="billBodyText">{latestBillAction}</Card.Body>
              </div>
            )} */}

<div style={{ marginTop: '20px' }}>
              {/* Conditionally show the appropriate buttons based on the table value */}
              {table === 'all_member_votes' ? (
    <>
      {/* More Button */}
      <Button className='clickable'
        id="billLink"
        onClick={() => history.push(`/feed/${schema}/${table}/${item.roll_call_number}`)}
      >
        <InfoCircle /> More
      </Button>

      {/* Document Button */}
      {docLink && (
        <Button className='clickable'
          id="viewBillButton"
          onClick={(e) => {
            e.stopPropagation();
            window.open(docLink, '_blank');
          }}
        >
          <FileText /> Document
        </Button>
      )}

     
    </>
  ): table === 'supreme_court_arguments' ? (
                <>
                  <Button className='clickable'
                    id="billLink"
                    onClick={(e) => {
                      e.stopPropagation();
                      const audioLink = item.url || '#'; // Assume audio link or modify as needed
                      window.open(audioLink, '_blank');
                    }}
                  >
                    <PlayCircle /> Audio
                  </Button>
  
                  {docLink && (
                    <Button className='clickable'
                      id="viewBillButton"
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(docLink, '_blank');
                      }}
                    >
                      <FileText /> Document
                    </Button>
                  )}
                </>
              ) : table === 'supreme_court_opinions' ? (
                <Button className='clickable'
                  id="billLink"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(link, '_blank'); // This button now links directly to the `link` field
                  }}
                >
                  <FileText /> Document
                </Button>
              ) : table === 'congressional_bills_enrolled' ||
                table === 'congressional_bills' ||
                table === 'public_and_private_laws' ? (
                <>
                  <Button className='clickable'
                    id="billLink"
                    onClick={() => history.push(`/feed/${schema}/${table}/${id}`)}
                  >
                    <InfoCircle /> More
                  </Button>
  
                  {docLink && (
                    <Button className='clickable'
                      id="viewBillButton"
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(docLink, '_blank');
                      }}
                    >
                      <FileText /> Document
                    </Button>
                  )}
                </>
              ) : (
                <Button className='clickable'
                  id="billLink"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(link, '_blank');
                  }}
                >
                  <InfoCircle /> Source
                </Button>
              )}
  
              <Button className='clickable' id="addToCollectionButton" onClick={() => handleAddToCollection(item)}>
                <FolderPlus />
              </Button>
  
              <Button className='clickable'
  id="shareButton"
  onClick={(e) => {
    e.stopPropagation();
    handleShareClick(table === 'all_member_votes' ? item.roll_call_number : id, e);
  }}
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-send"
    viewBox="0 0 16 16"
  >
    <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
  </svg>
</Button>
            </div>
          </Container>
        </div>
      );
    });
  };
  

  return (
    <>
      <BrowserView>
        <div id="homebg">
        {country === 'mexico' ? (
  <MexicoDesktopNavbar />
) : country === 'canada' ? (
  <CanadaDesktopNavbar />
) : country === 'united_kingdom' ? (
  <UKDesktopNavbar />
) : (
  <DesktopNavbar />
)}

          <div id="searchFormPage">
            <Form.Group className="d-flex justify-content-center align-items-center">
            {renderDropdown()} {/* Conditionally render the dropdown */}
              <Form.Control
                id="ggSearchCollection"
                placeholder="Search..."
                type="text"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setSearchPerformed(!!e.target.value);
                }}
                color="white"
              />
            </Form.Group>
          </div>

          {renderData()}

          {/* Toast Notification for "Copied to Clipboard" */}
          <Toast
            style={{ position: 'absolute', top: toastPosition.top, left: toastPosition.left, zIndex: 1000, backgroundColor: '#002031' }}
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={3000}
            autohide
          >
            <Toast.Body id="billBodyText">Copied to clipboard!</Toast.Body>
          </Toast>

          <div style={{ height: '20px' }}></div>
          <div className="d-flex justify-content-center align-items-center">
            <Button className='clickable' id="feedViewMoreButton" onClick={() => setFeedLength(feedLength + 20)}>
              More
            </Button>
          </div>
          <div style={{ height: '20px' }}></div>

          {/* Collection Modal */}
          <Modal
            id="fullBillModal"
            show={collectionModalIsOpen}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => setCollectionModalIsOpen(false)}
          >
            <Modal.Header id="billModalHeader" closeButton>
              <h3>{selectedItem?.status_title || selectedItem?.title}</h3>
            </Modal.Header>
            <Modal.Body id="descriptionModalBody">
              {new Date(selectedItem?.created_at).toLocaleDateString()}
              <div id="fullBill">
                <Form id="noteModalForm">
                  <Form.Group id="noteModalForm" className="mb-3">
                    <Form.Control
                      id="noteModalFeedItem"
                      placeholder="Add a note here"
                      type="text"
                      as="textarea"
                      rows={2}
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    />
                    <br />
                  </Form.Group>
                </Form>

                <Modal.Footer id="addNoteModalFooter">
                  <Button className='clickable'
                    variant="primary"
                    id="noteSaveToCollectionButton"
                    onClick={saveNote}
                  >
                    Save To Collection
                  </Button>
                </Modal.Footer>
              </div>
            </Modal.Body>
          </Modal>

          {/* Share Modal */}
          <Modal
            id="shareModal"
            show={shareModalIsOpen}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => setShareModalIsOpen(false)}
          >
            <Modal.Header id="billModalHeader" closeButton>
              <h3>Share</h3>
            </Modal.Header>
            <Modal.Body id="descriptionModalBody">
              <Form.Group id="noteModalForm">
                <Form.Control
               
                  id="noteModalFeedItem"
                  type="text"
                  readOnly
                  value={shareLink}
                />
              </Form.Group>
              <Modal.Footer id="shareFooter">
              <Button className='clickable' 
  id="addToCollectionButton" 
  onClick={() => handleNativeShare()}
>
<BoxArrowUp/>
</Button>

                <Button className='clickable' id="addToCollectionButton" onClick={handleCopyLink}>
                  {linkCopied ? <ClipboardCheck /> : <Clipboard />}
                </Button>
              </Modal.Footer>
            </Modal.Body>
          </Modal>
        </div>
      </BrowserView>

      <MobileView>
        <div id="homebg">
          <MobileTopNavbar />

          <div id="searchFormPage" className="d-flex justify-content-center align-items-center">
  {renderDropdown()} {/* Conditionally render the dropdown */}
  <Form.Group className="d-flex align-items-center mx-2">
    <Form.Control
      id="ggSearchCollectionMobile"
      placeholder="Search..."
      type="text"
      value={searchQuery}
      onChange={(e) => {
        setSearchQuery(e.target.value);
        setSearchPerformed(!!e.target.value);
      }}
      style={{
        width: table === 'regulations' ? '300px' : '400px' // Set width to 270px only for 'regulations' table
      }}
    />
  </Form.Group>
</div>


          {renderData()}

          {/* Toast Notification for "Copied to Clipboard" */}
          <Toast
            style={{ position: 'absolute', top: toastPosition.top, left: toastPosition.left, zIndex: 1000, backgroundColor: '#002031' }}
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={3000}
            autohide
          >
            <Toast.Body id="billBodyText">Copied to clipboard!</Toast.Body>
          </Toast>

          <div style={{ height: '20px' }}></div>
          <div className="d-flex justify-content-center align-items-center">
            <Button className='clickable' id="feedViewMoreButton" onClick={() => setFeedLength(feedLength + 20)}>
              More
            </Button>
          </div>
          <div style={{ height: '20px' }}></div>

          {/* Collection Modal */}
          <Modal
            id="fullBillModal"
            show={collectionModalIsOpen}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => setCollectionModalIsOpen(false)}
          >
            <Modal.Header id="billModalHeader" closeButton>
              <h3>{selectedItem?.status_title || selectedItem?.title}</h3>
            </Modal.Header>
            <Modal.Body id="descriptionModalBody">
              {new Date(selectedItem?.created_at).toLocaleDateString()}
              <div id="fullBill">
                <Form id="noteModalForm">
                  <Form.Group id="noteModalForm" className="mb-3">
                    <Form.Control
                      id="noteModalFeedItem"
                      placeholder="Add a note here"
                      type="text"
                      as="textarea"
                      rows={2}
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    />
                    <br />
                  </Form.Group>
                </Form>

                <Modal.Footer id="addNoteModalFooter">
                  <Button className='clickable'
                    variant="primary"
                    id="noteSaveToCollectionButton"
                    onClick={saveNote}
                  >
                    Save To Collection
                  </Button>
                </Modal.Footer>
              </div>
            </Modal.Body>
          </Modal>

                 <Modal
            id="shareModal"
            show={shareModalIsOpen}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => setShareModalIsOpen(false)}
          >
            <Modal.Header id="billModalHeader" closeButton>
              <h3>Share</h3>
            </Modal.Header>
            <Modal.Body id="descriptionModalBody">
              <Form.Group id="noteModalForm">
                <Form.Control
               
                  id="noteModalFeedItem"
                  type="text"
                  readOnly
                  value={shareLink}
                />
              </Form.Group>
              <Modal.Footer id="shareFooter">
              <Button className='clickable' 
  id="addToCollectionButton" 
  onClick={() => handleNativeShare()}
>
<BoxArrowUp/>
</Button>

                <Button className='clickable' id="addToCollectionButton" onClick={handleCopyLink}>
                  {linkCopied ? <ClipboardCheck /> : <Clipboard />}
                </Button>
              </Modal.Footer>
            </Modal.Body>
          </Modal>

          <br></br><br></br><br></br><br></br><br></br><br></br>
          <Navbar id="navbarMobileBottom" fixed="bottom">
            <div id="changeBranchDropdownMobile">
              <MobileOffCanvas />
            </div>
          </Navbar>
        </div>
      </MobileView>
    </>
  );
}

export default FeedPageTest;
