import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, NavLink,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, lazy  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter, Spinner } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, FileMedicalFill, Coin, Tree, Globe2,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, GeoAlt, HeartFill, QuestionCircleFill, MicFill, ReceiptCutoff, Bricks, Lightbulb } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import NationalScienceFoundation from './NationalScienceFoundation';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import Outbreaks from './Outbreaks';
import TravelNotices from './TravelNotices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import GAOReports from './GAOReports';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import SecuritiesAndExchangeCommissionSpeechesAndStatments from './SecuritiesAndExchangeCommissionSpeechesAndStatments';
import HouseCommitteeOnArmedServices from './HouseCommitteeOnArmedServices';
import FoodRecalls from './FoodRecalls';
import FoodSafety from './FoodSafety';
import HouseCommitteeOnEnergyAndCommerce from './HouseCommitteeOnEnergyAndCommerce';
import HouseCommitteeOnEthics from './HouseCommitteeOnEthics';
import CongressionalHearings from './CongressionalHearings';
import HouseCommitteeOnFinacialServices from './HouseCommitteeOnFinacialServices';
import HouseCommitteeOnForeignAffairs from './HouseCommitteeOnForeignAffairs';
import HouseCommitteeOnHomelandSecurity from './HouseCommitteeOnHomelandSecurity';
import HouseCommitteeOnTheJudiciary from './HouseCommitteeOnTheJudiciary';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import HouseCommitteeOnHouseAdministration from './HouseCommitteeOnHouseAdministration';
import CongressionalBudgetOffice from './CongressionalBudgetOffice';
import PresidentialProclamations from './PresidentialProclamations';
import ExecutiveOrders from './ExecutiveOrders';
import SecuritiesAndExchangeCommissionPressReleases from './SecuritiesAndExchangeCommissionPressReleases';
import CDCNewsroom from './CDCNewsroom';
import HouseCommitteeOnIntelligence from './HouseCommitteeOnIntelligence';
import UNTopStories from './UNTopStories';
import BillUpdates from './BillUpdates';
import NASAJPLNews from './NASAJPLNews';
import NISTNews from './NISTNews';
import GAOScienceAndTech from './GAOScienceAndTech';
import HouseCommitteeOnScienceSpaceAndTechnology from './HouseCommitteeOnScienceSpaceAndTechnology';
import
 {supremeCourtSearch, whiteHouseSearch, billFeedModal, 
  createHouseFloorToday, createSenateFloorToday, createTrendingBills,
  createNationalAeronauticsAndSpaceAdministration, 
  createEnvironmentalProtectionAgency, createDepartmentOfHomelandSecurity,
  createDepartmentOfVeteransAffairs, createDepartmentOfEducation,
  createDepartmentOfHousingAndUrbanDevelopment, 
  createDepartmentOfHealthAndHumanServices, createDepartmentOfLabor
, createDepartmentOfCommerce, createDepartmentOfAgriculture,
createDepartmentOfTheInterior, createDepartmentOfJustice,
createDepartmentOfTransportation, createDepartmentOfDefense,
createDepartmentOfTreasury, createDepartmentOfEnergy,
createDepartmentOfState, createBillsEnrolledMobile,
createBillsEnrolled, createNewLaws, createGAOReports,
createExecutiveOrders, createPresidentialProclamations, createCongressionalHearings,
createCongressionalBudgetOfficeMobile, createCongressionalBudgetOffice,
createHouseCommitteeOversightAndReform,
createHouseCommitteeOnNaturalResources,
createHouseCommitteeOnTheJudiciary,
createHouseCommitteeOnHouseAdministration,
createHouseCommitteeOnHomelandSecurity,
createHouseCommitteeOnForeignAffairs,
createHouseCommitteeOnFinancialServices,
createHouseCommitteeOnEthics,
createHouseCommitteeOnEnergyAndCommerce,
createHouseCommitteeOnEducationAndLabor,
createHouseCommitteeOnTheBudget,
createHouseCommitteeOnArmedServices,
createHouseCommitteeOnAppropriations,
createHouseCommitteeOnAgriculture,
createReports,
createBillFeedTwoMobile,
createBillFeedTwo,
createBillFeed, createWhiteHouseFeedMobile
, createWhiteHouseFeed, createWhiteHouseDocuments, 
createBillsPresentedFeed, createCourtAppealsMobile,
createCourtAppeals, createUSCourtNewsFeedMobile,
createUSCourtNewsFeed, saveToCollection,
createFullBillFeed, ListItem, Speak, feedText, variableTest, myMethod, myMethod2, createEconomicIndicators, 
createSecuritiesAndExchangeCommissionPressReleases, createNationWeatherServices, createCDCNewsroom,
createSecuritiesAndExchangeCommissionSpeechesAndStatements, createNationalScienceFoundation, createHouseCommitteeOnIntelligence,
 createUNTopStories, createCDCOutbreaks, createCDCTravelNotices, createFoodRecalls, createFoodSafety, createFederalElectionCommissionNews, createElectionAssistancCommissioneNews, 
 createBillStatus, CreateFeedItem, CreateFeedItemWithDocButton, CreateFeedItemWithLawButton, CreateFeedItemGAOReports,
CreateFeedItemCongressionalReports,
CreateFeedItemPresidentialDocuments,
CreateFeedItemExecutiveOrders,
CreateFeedItemCourtAppeals,
CreateFeedItemEconomicIndicators,
CreateFeedItemBillUpdates,
createBIllItem,
CreateFeedItemSupremeCourtArguments,
CreateFeedItemSupremeCourtOpinions,
CreateFeedItemTest,
CreateFeedItemBillUpdatesFlip,
CreateFeedItemWithDocumentFlip,
CreateFeedItemWithDocButtonDateFix,
CreateFeedItemWithLawButtonFlip,
CreateFeedAPI,
CreateFeedAPIRecentCache,
CreateFeedAPIOpinions,
CreateFeedAPIArguments,
CreateFeedAPIWithPage,
CreateFeedAPIWithPageTest} from './Functions'
import HomeMobile from './HomeMobile';
import DesktopNavbar from './DesktopNavbar';
import FederalElectionCommissionNews from './FederalElectionCommissionNews';
import ElectionAssistanceComissionNews from './ElectionAssistanceComissionNews';
import SupremeCourtArguments from './SupremeCourtArguments';
import SupremeCourtOpinions from './SupremeCourtOpinions';
import GenerateFeed from './GenerateFeed';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import Energy from './Energy';
import DepartmentOfEnergyDocs from './DepartmentOfEnergyDocs';
import DepartmentOfTransportationDocs from './DepartmentOfTransportationDocs';
import DepartmentOfHousingAndUrbanDevelopmentDocs from './DepartmentOfHousingAndUrbanDevelopmentDocs';
import DepartmentOfJusticeDocs from './DepartmentOfJusticeDocs';
import { Suspense } from 'react';
import PresidentDisplay from './PresidentDisplay';
import PresidentDisplayOnPage from './PresidentDisplayOnPage';
import PresidentDisplayOnCarouselDesk from './PresidentDisplayOnCarouselDesk';
import VicePresidentDisplayOnCarouselDesk from './VicePresidentDisplayOnCarouselDesk';
import JusticeDisplayOnCarouselDesk from './JusticeDisplayOnCarouselDesk';
import GenerateFeedRecent from './GenerateFeedRecent';
import GenerateFeedAudio from './GenerateFeedAudio';
import GenerateFeedOpinions from './GenerateFeedOpinions';

var collectionCounter = 0
var carouselInterval = 30000;
const HeavyDependencyComponent = lazy(() => import('./GenerateFeed'));
const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);


export const Home = () =>   {
  render()
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };
  {

  const [billHelpModalIsOpen, setBillHelpModalIsOpen] = useState(false)
  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
  const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
  const [secSAndSModalIsOpen, setSECSAndSModalIsOpen] = useState(false)
  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
  const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
  const [houseCommitteeOnArmedServicesModalIsOpen, setHouseCommitteeOnOnArmedServicesModalIsOpen] = useState(false)
  const [congressionalBudgetOfficeModalIsOpen, setCongressionalBudgetOfficeModalIsOpen] = useState(false)
  const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
  const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
  const [cdcNewsroomModalIsOpen, setCDCNewsroomModalIsOpen] = useState(false)
  const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
  const [unTopStoriesModalIsOpen, setUNTopStoriesModalIsOpen] = useState(false)
  const [houseCommitteeOnIntelligenceModalIsOpen, setHouseCommitteeOnIntelligenceModalIsOpen] = useState(false)
  const [travelNoticesModalIsOpen, setTravelNoticesModalIsOpen] = useState(false)
  const [fullBillModalIsOpen, setFullBillModalIsOpen ] = useState(false)
  const [foodRecallsModalIsOpen, setFoodRecallsModalIsOpen] = useState(false)
  const [foodSafetyModalIsOpen, setFoodSafetyModalIsOpen] = useState(false)
  const [outbreaksModalIsOpen, setOutbreaksModalIsOpen] = useState(false)
  const [congressionalHearingsModalIsOpen, setCongressionalHearingsModalIsOpen] = useState(false)
  const [fecModalIsOpen, setFECModalIsOpen] = useState(false)
  const [eacModalIsOpen, setEACModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [secPressModalIsOpen, setSECPressModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEnergyDocsModalIsOpen, setDepartmentOfEnergyDocsModalIsOpen] = useState(false)
  const [departmentOfTransportationDocsModalIsOpen, setDepartmentOfTransportationDocsModalIsOpen] = useState(false)
  const [hudDocumentsModalIsOpen, setHUDDocumentsModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [immigrationModalIsOpen, setImmigrationModalIsOpen] = useState(false)

  function BranchesTab() {
  

    return (
      <>
   
      <div id='branchesTab'>
      <Row id='branchesRow'>
      <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />

{/*Health Column */}
<Col id='homeColumn'>

<h3 id='branchHeading' onClick={() => window.open('/us/recents', "_self")}><svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                              <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
                              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
                            </svg> <b id='branchHeadingBold'>Recents</b> <ChevronRight id='clickChevron'></ChevronRight></h3>
<div  id='recent'></div> 
{CreateFeedAPIRecentCache('https://api.govglance.org/posts/recent/schema?limit=1&skip=0&schema=united_states_of_america', 'recent', 
                'Recents', 'N/A', 
                'N/A', 'Doc', GenerateFeed)}
</Col>

<Col id='homeColumn'>
<h3 id='branchHeading' >  </h3>
<div  id='recent2'></div> 
{CreateFeedAPIRecentCache('https://api.govglance.org/posts/recent/schema?limit=1&skip=1&schema=united_states_of_america', 'recent2', 
                'Recents', 'N/A', 
                'N/A', 'Doc', GenerateFeed)}
</Col>
<Col id='homeColumn'>
<h3 id='branchHeading' >  </h3>
<div  id='recent3'></div> 
{CreateFeedAPIRecentCache('https://api.govglance.org/posts/recent/schema?limit=1&skip=2&schema=united_states_of_america', 'recent3', 
                'Recents', 'N/A', 
                'N/A', 'Doc', GenerateFeed)}
</Col>

                



{/*Placeholder2 Column */}

</Row>
        <Row id='branchesRow'>
            {/*Legislative Column */}
          <Col id='homeColumn'>
              <Row id="homePageBranchRow">
<h3 id='branchHeading' onClick={() => window.open('/us/legislative', "_self")}> <Pen></Pen> <b id='branchHeadingBold'>Legislative</b><ChevronRight id='clickChevron'></ChevronRight></h3>
            </Row>
            {/*Legislative Desktop Carousel*/}
          <Carousel id='homeCarousel' controls={false}  >
          <Carousel.Item interval={carouselInterval} >
<div id='legislativeCon'>
          <Col id='homeCol' >
            <div id='columnRightPadding'>
            {/*onMouseEnter={() => render(renderTooltip)} */}
            <h4 id='billFeedHeading' onClick={() =>
                            openModal('Bill Updates',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=united_states_of_america&table=congressional_bills&order_by=action_date'
                                name='Bill Updates' branch='Legislative' topic='N/A' buttonName='Bill' />)}><b>Bill Updates</b> <QuestionCircleFill onClick={() => setBillHelpModalIsOpen(true)}  size={20}></QuestionCircleFill></h4>
            <Modal id='fullBillModal' show={billHelpModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setBillHelpModalIsOpen(false)}>
                <Modal.Header id='billModalHeader' closeButton><h3>How to Read Bill Updates</h3></Modal.Header>
              
              <div id='fullBillFeed'>
<Container id='feedContainer'>

<p><strong>Types of Legislation</strong></p>
<p><strong>Bills</strong></p>
<ul>
<li>H.R. – House Bill</li>
<li>S. – Senate Bill</li>
</ul>
<p>A <strong>bill</strong> is a legislative proposal before Congress. Bills from each house are assigned a number in the order in which they are introduced, starting at the beginning of each Congress (first and second sessions). Public bills pertain to matters that affect the general public or classes of citizens, while private bills pertain to individual matters that affect individuals and organizations, such as claims against the Government.</p>
<p><strong>Joint Resolutions</strong></p>
<ul>
<li>H.J. Res. – House Joint Resolution</li>
<li>S.J. Res. – Senate Joint Resolution</li>
</ul>
<p>A <strong>joint resolution</strong> is a legislative proposal that requires the approval of both houses and the signature of the President, just as a bill does. Resolutions from each house are assigned a number in the order in which they are introduced, starting at the beginning of each Congress (first and second sessions). There is no real difference between a bill and a joint resolution. Joint resolutions generally are used for limited matters, such as a single appropriation for a specific purpose. They are also used to propose amendments to the Constitution. A joint resolution has the force of law, if approved. Joint resolutions become a part of the Constitution when three-quarters of the states have ratified them; they do not require the President&#39;s signature.</p>
<p><strong>Concurrent Resolutions</strong></p>
<ul>
<li>H. Con. Res. – House Concurrent Resolution</li>
<li>S. Con. Res. – Senate Concurrent Resolution</li>
</ul>
<p>A <strong>concurrent resolution</strong> is a legislative proposal that requires the approval of both houses but does not require the signature of the President and does not have the force of law. Concurrent resolutions generally are used to make or amend rules that apply to both houses. They are also used to express the sentiments of both of the houses. For example, a concurrent resolution is used to set the time of Congress&#39; adjournment. It may also be used by Congress to convey congratulations to another country on the anniversary of its independence.</p>
<p><strong>Simple Resolutions</strong></p>
<ul>
<li>H. Res. – House Simple Resolution</li>
<li>S. Res. – Senate Simple Resolution</li>
</ul>
<p>A <strong>simple resolution</strong> is a legislative proposal that addresses matters entirely within the prerogative of one house or the other. It requires neither the approval of the other house nor the signature of the President, and it does not have the force of law. Most simple resolutions concern the rules of one house. They are also used to express the sentiments of a single house. For example, a simple resolution may offer condolences to the family of a deceased member of Congress, or it may give &quot;advice&quot; on foreign policy or other executive business.</p>




</Container>
</div>

                
              
              </Modal>
              <div  id='bills'></div> 
              {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=congressional_bills&order_by=action_date', 'bills', 
                            'Bill Updates', 'Legislative', 
                            'N/A', 'Bill', GenerateFeed)}


            </div>
          </Col>
    </div>
          </Carousel.Item>
                <Carousel.Item id='legislativeCarousel' interval={carouselInterval} >

                  <div id='legislativeCon'>
                    <Col id='homeCol'>
                      <div id='columnLefttPadding'>
                        <h4 id='feedHeadingBillsEnrolled' onClick={() =>
                            openModal('Congressional Hearings',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=united_states_of_america&table=congressional_hearings&order_by=created_at'
                                name='Congressional Hearings' branch='Legislative' topic='N/A' buttonName='Hearing' />)} ><b>Congressional Hearings</b></h4>
                        <div id='congressionalHearings'></div>
                        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=congressional_hearings&order_by=created_at', 'congressionalHearings', 
                            'Congressional Hearings', 'Legislative', 
                            'N/A', 'Hearing', GenerateFeed)}

                      </div>
                    </Col>
                  </div>

                </Carousel.Item>
            <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                          <div id='legislativeCon'>
                      <Col id='homeCol'>
                        <div id='columnLefttPadding'>
                          <h4 id='feedHeadingBillsEnrolled' onClick={() =>
                            openModal('Bills Enrolled',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=united_states_of_america&table=congressional_bills_enrolled&order_by=date_issued'
                                name='Bills Enrolled' branch='Legislative' topic='N/A' buttonName='Bill' />)}><b>Bills Enrolled (Sent to President)</b></h4>
                          <div id='billsEnrolled'></div>
                                    {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                                    {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=congressional_bills_enrolled&order_by=date_issued', 
                    'billsEnrolled', 
                    'Bills Enrolled', 'Legislative', 
                    'N/A', 'Bill', GenerateFeed)}
      
                        </div>
                      </Col>
              </div>

            </Carousel.Item>
<Carousel.Item interval={carouselInterval} >

              <div id='legislativeCon'>
                        <Col id='homeCol'>
                          <div id='columnRightPadding'>
                            <h4 id='feedHeading' onClick={() =>
                            openModal('Laws',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=united_states_of_america&table=public_and_private_laws&order_by=date_issued'
                                name='Laws' branch='Legislative' topic='N/A' buttonName='Law' />)}><b>Laws</b></h4>
                            <div id='newLaws'></div>
                            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=public_and_private_laws&order_by=date_issued', 'newLaws', 
                            'Laws', 'Legislative', 
                            'N/A', 'Law', GenerateFeed)}

                          </div>
                        </Col>
                  </div>
                </Carousel.Item>
                        <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                          <div id='legislativeCon'>
 
                        <Col id='homeCol'>
                          <div id='columnLefttPadding'>
                            <h4 id='feedHeadingBillsEnrolled' ><b>Government Accountability Office Reports</b></h4>
                            <div id='gaoReports'></div>
                            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=government_accountability_office_reports&order_by=created_at', 'gaoReports', 'Government Accountability Office Reports', 'Legislative', 'N/A', GAOReports)}
                        
                          </div>
                        </Col>
              </div>
            </Carousel.Item>


              <Carousel.Item interval={carouselInterval} >

                <div id='legislativeCon'>
                        <Col id='homeCol'>
                          <div id='columnRightPadding'>
                            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
                            openModal('Congressional Reports',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=united_states_of_america&table=congressional_reports&order_by=created_at'
                                name='Congressional Reports' branch='Legislative' topic='N/A' buttonName='Report' />)}><b>Congressional Reports</b></h4>
                            <div id='reports'></div>
                        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=congressional_reports&order_by=created_at', 'reports', 
                            'Congressional Reports', 'Legislative', 
                            'N/A', 'Report', GenerateFeed)}   
       
                          </div>
                        </Col>

                   
                  </div>
                </Carousel.Item>

                
                <Carousel.Item interval={carouselInterval} >

                  <div id='legislativeCon'>



                    <Col id='homeCol'>
                      <div id='columnLefttPadding'>
                        <h4 id='feedHeadingBillsEnrolled' onClick={() =>
                            openModal('Congressional Budget Office',
                            <CongressionalBudgetOffice />)}><b>Congressional Budget Office</b></h4>
                        <div id='congressionalBudgetOffice'></div>
                        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=supreme_court_opinions&order_by=created_at', 'congressionalBudgetOffice', 'Congressional Budget Office', 'Legislative', 'Economy', 'Document', CongressionalBudgetOffice)}
                      
                      </div>
                    </Col>
                  </div>
                </Carousel.Item>
                <Carousel.Item interval={carouselInterval} >
                  <div id='legislativeCon'>
                    <Col id='homeCol'>
                      <div id='columnLefttPadding'>
                      <Button id='viewFullLegislativePageButton' size='lg' href='/us/legislative' >View all Legislative news</Button>
                      </div>
                    </Col>
                  </div>
                </Carousel.Item>
                </Carousel>
          </Col>
          {/*Executive Column */}
          <Col id='homeColumn'>
          <Row id="homePageBranchRow">
                <h3 id='branchHeading' onClick={() => window.open('/us/executive', "_self")}><People></People> <b id='branchHeadingBold'>Executive</b><ChevronRight id='clickChevron'></ChevronRight></h3>
            </Row>
            {/*Executive Desktop Carousel*/}
          <Carousel controls={false}>


    
                <Carousel.Item interval={carouselInterval}>
              <div id='executiveCon' >
              <Col id='homeCol'>
                        <div id='columnLeftPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() =>
                            openModal('Presidential Actions',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=united_states_of_america&table=the_white_house&order_by=created_at'
                                name='White House Briefing Room' branch='Executive' topic='N/A' buttonName='' />)}><b>White House Briefing Room</b></h4>
                          <div id='wh2'></div>
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=the_white_house&order_by=created_at', 'wh2', 
                            'White House Briefing Room', 'Executive', 
                            'N/A', '', GenerateFeed)}

                
                        </div>
                      </Col>
                  </div>
                </Carousel.Item>

                <Carousel.Item interval={carouselInterval}>
              <div id='executiveCon' >
              <Col id='homeCol'>
                        <div id='columnLeftPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() =>
                            openModal('Presidential Actions',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=united_states_of_america&table=the_white_house&order_by=created_at&filter_column=category&filter_string=Presidential%20Actions'
                                name='Presidential Actions' branch='Executive' topic='N/A' buttonName='' />)}><b>Presidental Actions</b></h4>
                          <div id='wh'></div>
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=the_white_house&order_by=created_at&filter_column=category&filter_string=Presidential%20Actions', 'wh', 
                            'Presidential Actions', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}

                
                        </div>
                      </Col>
                  </div>
                </Carousel.Item>
          {/* <Carousel.Item interval={carouselInterval}>
              <div id='executiveCon' >
              <Col id='homeCol'>
                        <div id='columnLeftPadding'>
                          <h4 onClick={() => window.open('/us/presidents', "_self")} id='presidentialFeedHeading'><b>President</b></h4>
             
<PresidentDisplayOnCarouselDesk/>
                
                        </div>
                      </Col>
                  </div>
                </Carousel.Item> */}

      
 


                <Carousel.Item interval={carouselInterval}>

                  <div id='executiveCon' >
                   
                      

                      <Col id='homeCol'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() =>
                            openModal('Presidential Documents',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=united_states_of_america&table=compilation_of_presidential_documents&order_by=created_at'
                                name='Presidential Documents' branch='Executive' topic='N/A' buttonName='Document' />)}><b>Presidential Documents</b></h4>
                          <div id='whdocs'></div>
                          
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=compilation_of_presidential_documents&order_by=created_at', 'whdocs', 
                            'Presidential Documents', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}

                  
                        </div>
                      </Col>


                    


              
              </div>
            </Carousel.Item>

            {/* <Carousel.Item interval={carouselInterval}>

<div id='executiveCon' >
 
    

    <Col id='homeCol'>
      <div id='columnRightPadding'>
        <h4 id='presidentialFeedHeading' onClick={() =>
                            openModal('Executive Orders',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=united_states_of_america&table=president_executive_orders&order_by=created_at'
                                name='Executive Orders' branch='Executive' topic='N/A' buttonName='Document' />)}><b> Executive Orders</b></h4>
        <div id='executiveOrders'></div>
        
        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=president_executive_orders&order_by=created_at', 'executiveOrders', 
                            'Executive Orders', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}

      </div>
    </Col>


  



</div>
</Carousel.Item> */}


            <Carousel.Item interval={carouselInterval}>

<div id='executiveCon' >
 
    

    <Col id='homeCol'>
      <div id='columnRightPadding'>
        <h4 id='presidentialFeedHeading' onClick={() =>
                            openModal('Executive Orders',
                            <PresidentialProclamations/>)}><b> Presidential Proclamations</b></h4>
        <div id='presidentialProclomations'></div>
        
        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=presidential_proclamation&order_by=created_at', 'presidentialProclomations', 'Presidential Proclamations', 'Executive', 'N/A', 'Document', PresidentialProclamations)}

      </div>
    </Col>


  



</div>
</Carousel.Item>

<Carousel.Item interval={carouselInterval}>

<div id='executiveCon' >
 
    

    <Col id='homeCol'>
      <div id='columnRightPadding'>

      <Button id='viewFullLegislativePageButton' size='lg' href='/us/executive' >View all Executive news</Button>
       
      </div>
    </Col>


  



</div>
</Carousel.Item>
          </Carousel >
          </Col>


            {/*Judical Column */}
            <Col id='homeColumn'>


               <Row id="homePageBranchRow">
                <h3 id='branchHeading' onClick={() => window.open('/us/judicial', "_self")}><svg xmlns="http://www.w3.org/2000/svg" height="27.54px" viewBox="0 -960 960 960" width="27.54px" fill="#FFFFFF"><path d="M160-120v-80h480v80H160Zm226-194L160-540l84-86 228 226-86 86Zm254-254L414-796l86-84 226 226-86 86Zm184 408L302-682l56-56 522 522-56 56Z"/></svg><b id='branchHeadingBold'>Judicial</b><ChevronRight id='clickChevron'></ChevronRight></h3>
              
            
             </Row>
            
            {/*Judical Desktop Carousel*/}
         
          <Carousel controls={false}>

    

            <Carousel.Item interval={carouselInterval}>

              <div id='judicalCon'>
                
                    
                    

                      <Col id='homeCol'>
                        <div id='columnLefttPadding'>
                          <h4 id='feedHeading' onClick={() =>
                            openModal('U.S. Court News',
                            <FullUSCourtNews/>)}><b>U.S. Court News</b></h4>
                          <div id='courtNews'></div>

                           
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=uscourts&order_by=created_at', 'courtNews', 'U.S. Court News', 'Judicial', 'N/A', "Document", FullUSCourtNews)}
                         
           

                        </div>
                      </Col>

                  
                  </div>
                </Carousel.Item>

                <Carousel.Item interval={carouselInterval}>

                  <div id='judicalCon'>
                  

                      <Col id='homeCol'>
                        <div id='columnRightPadding'>

                          <h4 id='departmentOfStateHeading' onClick={() =>
                            openModal('U.S. Court of Appeals',
                            <CourtAppeals/>)}><b>U.S. Court of Appeals</b></h4>

                          <div id='courtAppeals'></div>
                          
                          {CreateFeedItemCourtAppeals('https://www.govinfo.gov/rss/uscourts-ca13.xml', 'courtAppeals', 'U.S. Court of Appeals', 'Judicial', 'N/A', CourtAppeals)}
                     
                           





                        </div>
                      </Col>





                    



               
              </div>
            </Carousel.Item>
            {/* <Carousel.Item interval={carouselInterval}>
              <div id='executiveCon' >
              <Col id='homeCol'>
                        <div id='columnLeftPadding'>
                          <h4 onClick={() => window.open('/us/justices', "_self")} id='presidentialFeedHeading'><b>Justices</b></h4>
             
<JusticeDisplayOnCarouselDesk/>
                
                        </div>
                      </Col>
                  </div>
                </Carousel.Item> */}


            <Carousel.Item interval={carouselInterval}>

              <div id='judicalCon'>
               
                    

                    


                      <Col id='homeCol'>
                        <div id='columnLefttPadding'>
                          <h4 id='feedHeading'onClick={() =>
                            openModal('Supreme Court Opinions',
                            <GenerateFeedOpinions url='&skip=0&schema=united_states_of_america&table=supreme_court_opinions&order_by=created_at' 
                            name='Supreme Court Opinions' branch='Judicial' topic='N/A' buttonName='Document' />)} > <b>Supreme Court Opinions</b></h4>
                          <div id='courtOpinions'></div>
                 
                        
                          {CreateFeedAPIOpinions('&skip=0&schema=united_states_of_america&table=supreme_court_opinions&order_by=created_at', 'courtOpinions', 
                            'Supreme Court Opinions', 'Judicial', 
                            'N/A', 'Document', GenerateFeedOpinions)}

                        </div>
                      </Col>

                    
                  </div>
                </Carousel.Item>

                <Carousel.Item interval={carouselInterval}>

                  <div id='judicalCon'>
                   







                      <Col id='homeCol'>
                        <div id='columnRightPadding'>
                        <h4 id='feedHeading' onClick={() =>
                            openModal('Supreme Court Arguments',
                            <GenerateFeedAudio url='&skip=0&schema=united_states_of_america&table=supreme_court_arguments&order_by=created_at' 
                            name='Supreme Court Arguments' branch='Judicial' topic='N/A' buttonName='Document' />)}><b>Supreme Court Arguments</b></h4>
                         

                         <div id='courtArguments'></div>
                         {CreateFeedAPIArguments('&skip=0&schema=united_states_of_america&table=supreme_court_arguments&order_by=created_at', 'courtArguments', 
                            'Supreme Court Arguments', 'Judicial', 
                            'N/A', 'Document', GenerateFeedAudio)}
                        </div>
                      </Col>






                    




          
              </div>

            </Carousel.Item>

            <Carousel.Item interval={carouselInterval}>

<div id='judicalCon'>
 







    <Col id='homeCol'>
      <div id='columnRightPadding'>
      
      <Button id='viewFullLegislativePageButton' size='lg' href='/us/judicial' >View all Judicial news</Button>


        

      </div>
    </Col>

</div>

</Carousel.Item>

          </Carousel>
          </Col>
          </Row>

          <Row id='branchesRow'>
          <Col id='homeColumn'>

<Row id="topicPageBranchRow">

<h3 id='branchHeading' onClick={() => window.open('/us/defense', "_self")}> <ShieldShaded/> <b id='branchHeadingBold'>Defense</b><ChevronRight id='clickChevron'></ChevronRight></h3>


</Row>
<Carousel  controls={false} >

<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='departmentOfDefenseHeading' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}><b>Department of Defense</b></h4>

<div id='defenseDepartment2'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=defense&order_by=created_at', 'defenseDepartment2', 'Department of Defense', 'Executive', 'Foreign Affairs', GenerateFeed)}

    </div>
  </Col>
















</div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='departmentOfDefenseHeading' onClick={() => setDefenseDocumentsModalIsOpen(true)}><b>Department of Defense Documents</b></h4>

    <div id='frDefense'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=defense&order_by=created_at&filter_column=agency&filter_string=Defense%20Department', 
'frDefense', 'Department of Defense Documents', 'Executive', 'Defense', "Document", FederalRegisterDefenseDepartment)}

<Modal id='whiteHouseDocsModal' show={defenseDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseDocumentsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Defense Documents</h3></Modal.Header>
                  <FederalRegisterDefenseDepartment />
                </Modal>
    </div>
  </Col>
















</div>

</Carousel.Item>



<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



<Col id='homeCol'>
<div id='columnLefttPadding'>



<Button id='viewFullLegislativePageButton' size='lg' href='/us/defense' >View all Defense news</Button>










</div>
</Col>
















</div>

</Carousel.Item>



</Carousel>



</Col>
{/*Economy Column */}
 <Col id='homeColumn'>

                        <Row id="topicPageBranchRow">

                        <h3 id='branchHeading' onClick={() => window.open('/us/economy', "_self")}> <Coin></Coin> <b id='branchHeadingBold'>Economy</b><ChevronRight id='clickChevron'></ChevronRight></h3>


                          </Row>
<Carousel id='homeCarousel' controls={false} >


<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


      <h4 id='feedHeadingBillsEnrolled' onClick={() => setSECPressModalIsOpen(true)}><b>SEC Press Releases</b></h4>
      <div class='whdocs' id='secPR'></div>
                        
      {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=sec_updates&order_by=created_at', 'secPR', 
                            'SEC Press Releases', 'Executive', 
                            'Economy', 'Document', GenerateFeed)}

                          <Modal id='whiteHouseDocsModal' show={secPressModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                          centered onHide={() => setSECPressModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>SEC Press Releases</h3></Modal.Header>
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=sec_updates&order_by=created_at' 
                            name='SEC Press Releases' branch='Executive' topic='Economy' buttonName='Document' />
                          </Modal>





    </div>
  </Col>

</div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='presidentialFeedHeading' onClick={() => setSECSAndSModalIsOpen(true)}><b>SEC Speeches and Statements</b></h4>
                        <div class='whdocs' id='sec'></div>
                        
                        
                        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=sec_speeches_statements&order_by=created_at', 'sec', 
                            'SEC Speeches and Statements', 'Executive', 
                            'Economy', 'Document', GenerateFeed)}

                          <Modal id='whiteHouseDocsModal' show={secSAndSModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                          centered onHide={() => setSECSAndSModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>SEC Speeches and Statements</h3></Modal.Header>
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=sec_speeches_statements&order_by=created_at' 
                            name='SEC Speeches and Statements' branch='Executive' topic='Economy' buttonName='Document' />
                          </Modal>





    </div>
  </Col>
</div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='departmentOfStateHeading' ><b>Department of Commerce</b></h4>

<div class='whdocs' id='commerceDepartment'></div>


{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=defense&order_by=created_at', 'commerceDepartment', 'Department of Commerce', 'N/A', 'Economy', 'Document', DepartmentOfCommerce)}


<Modal id='whiteHouseDocsModal' show={departmentOfCommerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfCommerceModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Commerce</h3></Modal.Header>
  <DepartmentOfCommerce />
</Modal>





    </div>
  </Col>
















</div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='presidentialFeedHeading'  onClick={() => setDepartmentOfTreasuryModalIsOpen(true)}><b>Department of Treasury</b></h4>
                        <div class='whdocs' id='treasuryDepartment'></div>
                        

                        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=treasury&order_by=created_at', 
                                             'treasuryDepartment', 
                                               'Department of Treasury', 'Executive', 
                                               'N/A', 'Document', GenerateFeed)}
                        <Modal id='whiteHouseDocsModal' show={departmentOfTreasuryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfTreasuryModalIsOpen(false)}>
                          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Treasury</h3></Modal.Header>
                          <GenerateFeed url='&skip=0&schema=united_states_of_america&table=treasury&order_by=created_at' name='Department of Treasury' branch='Executive' topic='N/A' buttonName='Doc'
                />
                        </Modal>





    </div>
  </Col>
















</div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='departmentOfStateHeading' onClick={() => setDepartmentOfLaborModalIsOpen(true)}><b>Department of Labor</b></h4>

<div class='whdocs' id='laborDepartment'></div>


{CreateFeedAPI('https://www.dol.gov/rss/releases.xml', 'laborDepartment', 'Department of Labor', 'N/A', 'Economy', 'Document', DepartmentOfLabor)}


<Modal id='whiteHouseDocsModal' show={departmentOfLaborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfLaborModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Labor</h3></Modal.Header>
  <DepartmentOfLabor />
</Modal>





    </div>
  </Col>
















</div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>
    


    <Button id='viewFullLegislativePageButton' size='lg' href='/us/economy' >View all Economy news</Button>
 



    





    </div>
  </Col>
















</div>

</Carousel.Item>



 </Carousel>
                  </Col>
                  <Col id='homeColumn'>

<Row id="topicPageBranchRow">

<h3 id='branchHeading' onClick={() => window.open('/us/elections', "_self")}> <ReceiptCutoff></ReceiptCutoff> <b id='branchHeadingBold'>Elections</b><ChevronRight id='clickChevron'></ChevronRight></h3>


  </Row>
  <Carousel id='healthCarousel' controls={false} >



  <Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='presidentialFeedHeading'><b>Federal Election Commission</b></h4>

<div id='fec'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=federal_election_commission&order_by=created_at', 'fec', 'Federal Election Commission', 'N/A', 'Election', FederalElectionCommissionNews)}


<Modal id='departmentOfStateModal' show={fecModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFECModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Federal Election Commission</h3></Modal.Header>
  <FederalElectionCommissionNews />
</Modal>





    </div>
  </Col>
















</div>

</Carousel.Item>

  <Carousel.Item interval={carouselInterval} >

<div id='healthCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>

  
    <h4 id='departmentOfDefenseHeading' onClick={() => setEACModalIsOpen(true)}><b>Election Assistance Commission</b></h4>

<div id='eac'></div>


{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=election_assistance_commission&order_by=created_at', 'eac', 
                            'Election Assistance Commission', 'Legislative', 
                            'N/A', 'Doc', GenerateFeed)}
                        <Modal id='departmentOfStateModal' show={eacModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setEACModalIsOpen(false)}>
                          <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Election Assistance Commission</h3></Modal.Header>
                          <GenerateFeed
                                url='&skip=0&schema=united_states_of_america&table=election_assistance_commission&order_by=created_at'
                                name='Election Assistance Commission News' branch='N/A' topic='Election' buttonName='Doc' />
                        </Modal>

    </div>
  </Col>
















</div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>
    


    <Button id='viewFullLegislativePageButton' size='lg' href='/us/elections' >View all Elections new</Button>
 



    





    </div>
  </Col>
















</div>

</Carousel.Item>








 </Carousel>

 




  </Col>

{/*Environment Column */}


            </Row>

            <Row id='branchesRow'>
            <Col id='homeColumn'>

<Row id="topicPageBranchRow">

<h3 id='branchHeading' onClick={() => window.open('/us/environment', "_self")}> <Tree></Tree> <b id='branchHeadingBold'>Environment</b><ChevronRight id='clickChevron'></ChevronRight></h3>


  </Row>

  <Carousel id='homeCarousel' controls={false} >

<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



<Col id='homeCol'>
<div id='columnLefttPadding'>


<h4 id='presidentialFeedHeading' onClick={() =>
    openModal('National Weather Service Update',
    <GenerateFeed url='&skip=0&schema=united_states_of_america&table=national_weather_service_update&order_by=created_at' 
    name='National Weather Service Update' branch='Executive' topic='Environment' buttonName='Doc' />)}><b>National Weather Service Update</b></h4>

<div id='nws'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=national_weather_service_update&order_by=created_at', 'nws', 
    'National Weather Service Update', 'N/A', 
    'Environment', 'Document', GenerateFeed)}











</div>
</Col>
















</div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



<Col id='homeCol'>
<div id='columnLefttPadding'>


<h4 id='presidentialFeedHeading' onClick={() => window.open('/us/epa', "_self")}><b>EPA <ChevronRight id='clickChevron'></ChevronRight></b></h4>

<div id='epa'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=epa&order_by=created_at', 'epa', 
    'EPA', 'N/A', 
    'Environment', 'Document', GenerateFeed)}

  <Modal id='whiteHouseDocsModal' show={epaModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
  centered onHide={() => setEPAModalIsOpen(false)}>
    <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>EPA</h3></Modal.Header>
    <GenerateFeed url='&skip=0&schema=united_states_of_america&table=epa&order_by=created_at' 
    name='EPA' branch='N/A' topic='Environment' buttonName='Document' />
  </Modal>





</div>
</Col>
















</div>

</Carousel.Item>


<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



<Col id='homeCol'>
<div id='columnLefttPadding'>


<h4 id='presidentialFeedHeading' onClick={() =>
    openModal('National Science Foundation News',
    <GenerateFeed
    url='&skip=0&schema=united_states_of_america&table=national_science_foundation&order_by=created_at'
    name='National Science Foundation News' branch='N/A' topic='Science & Tech' buttonName='Doc' />)}><b>National Science Foundation Research News</b></h4>

<div id='nsf'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=national_science_foundation&order_by=created_at', 'nsf', 
    'National Science Foundation News', 'N/A', 
    'Science & Tech', 'Doc', GenerateFeed)}







</div>
</Col>
















</div>

</Carousel.Item>




<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



<Col id='homeCol'>
<div id='columnLefttPadding'>


<h4 id='presidentialFeedHeading' onClick={() => setDepartmentOfAgricultureModalIsOpen(true)}><b>Department of Agriculture</b></h4>

<div id='agricultureDepartment'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=environment&order_by=created_at', 'agricultureDepartment', 'Department of Agriculture', 'Executive', 'Environment', 'Document', DepartmentOfAgriculture)}


<Modal id='whiteHouseDocsModal' show={departmentOfAgricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfAgricultureModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Agriculture</h3></Modal.Header>
  <DepartmentOfAgriculture />
</Modal>




</div>
</Col>
















</div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



<Col id='homeCol'>
<div id='columnLefttPadding'>


<h4 id='presidentialFeedHeading' onClick={() => setHouseComitteeOnAgricultureModalIsOpen(true)} ><b>House Committee on Agriculture</b></h4>

<div id='houseComitteeOnAgriculture'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_agriculture_meeting_feed&order_by=created_at', 'houseComitteeOnAgriculture', 'House Committee On Agriculture', 'Legislative', 'Environment', HouseComitteeOnAgriculture )}

<Modal id='fullBillModal' show={houseComitteeOnAgricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseComitteeOnAgricultureModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>House Committee On Agriculture</h3></Modal.Header>
<HouseComitteeOnAgriculture />
</Modal>




</div>
</Col>
















</div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



<Col id='homeCol'>
<div id='columnLefttPadding'>


<h4 id='presidentialFeedHeading' onClick={() => setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen(true)}><b>Department of Housing and Urban Development</b></h4>

<div id='housingAndUrbanDevelopmentDepartment'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=housing_and_urban_development&order_by=created_at', 'housingAndUrbanDevelopmentDepartment', 
    'Department of Housing and Urban Development', 'Executive', 
    'N/A', 'Document', GenerateFeed)}



<Modal id='whiteHouseDocsModal' show={departmentOfHousingAndUrbanDevelopmentModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen(false)(false)}>
<Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Housing and Urban Development</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=united_states_of_america&table=housing_and_urban_development&order_by=created_at'
      name='Department of Housing and Urban Development' branch='Executive' topic='N/A' buttonName='Document'/>
</Modal>




</div>
</Col>
















</div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



<Col id='homeCol'>
<div id='columnLefttPadding'>



<Button id='viewFullLegislativePageButton' size='lg' href='/us/environment' >View all Environment news</Button>










</div>
</Col>
















</div>

</Carousel.Item>



</Carousel>
  </Col>
{/*Foreign Affairs Column */}
<Col id='homeColumn'>

<Row id="topicPageBranchRow">

<h3 id='branchHeading' onClick={() => window.open('/us/foreignaffairs', "_self")}> <Globe2></Globe2> <b id='branchHeadingBold'>Foreign Affairs</b><ChevronRight id='clickChevron'></ChevronRight></h3>


</Row>
<Carousel id='homeCarousel' controls={false} >

<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



<Col id='homeCol'>
<div id='columnLefttPadding'>


<h4 id='departmentOfStateHeading' onClick={() => window.open('/us/departmentofstate', "_self")}><b>Department of State</b></h4>

<div id='stateDepartment'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=state&order_by=created_at', 
'stateDepartment', 'Department of State News', 'Executive', 
    'N/A', '', GenerateFeed)}
<Modal id='whiteHouseDocsModal' show={departmentOfStateModalIsOpen} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() =>setDepartmentOfStateModalIsOpen(false)}>
<Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>News</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=united_states_of_america&table=department_of_state&order_by=created_at' 
    name='Department of State News' branch='Executive' topic='N/A' buttonName='' />
</Modal>





</div>
</Col>
















</div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



<Col id='homeCol'>
<div id='columnLefttPadding'>


<h4 id='departmentOfDefenseHeading' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}><b>Department of Defense</b></h4>

<div id='defenseDepartment'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=defense&order_by=created_at', 'defenseDepartment', 'Department of Defense', 'Executive', 'Foreign Affairs', GenerateFeed)}

<Modal id='whiteHouseDocsModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Defense</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=united_states_of_america&table=defense&order_by=created_at' 
    name='Department of Defense News' branch='Executive' topic='N/A' buttonName='' />
</Modal>





</div>
</Col>
















</div>

</Carousel.Item>

{/* <Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



<Col id='homeCol'>
<div id='columnLefttPadding'>


<h4 id='presidentialFeedHeading' onClick={() => setHouseCommitteeOnIntelligenceModalIsOpen(true)}><b>House Committee On Intelligence</b></h4>
<div class='whdocs' id='houseCommitteeOnIntelligence'></div>


{CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=IG00', 'houseCommitteeOnIntelligence', 'House Committee On Intelligence', 'N/A', 'Foreign Affairs', HouseCommitteeOnIntelligence)}

<Modal id='fullBillModal' show={houseCommitteeOnIntelligenceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnIntelligenceModalIsOpen(false)}>
  <Modal.Header id='billModalHeader' closeButton><h3>House Committee On Intelligence</h3></Modal.Header>
  <HouseCommitteeOnIntelligence />
</Modal>





</div>
</Col>
















</div>

</Carousel.Item> */}

<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



<Col id='homeCol'>
<div id='columnLefttPadding'>


<h4 id='feedHeadingBillsEnrolled' onClick={() => setHouseCommitteeOnOnArmedServicesModalIsOpen(true)}><b>House Committee on Armed Services</b></h4>
<div id='houseComitteeOnArmedServices'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_armed_services_meeting_feed&order_by=created_at', 'houseComitteeOnArmedServices', 'House Committee on Armed Services', 'N/A', 'Foreign Affairs', HouseCommitteeOnArmedServices)};


<Modal id='fullBillModal' show={houseCommitteeOnArmedServicesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnOnArmedServicesModalIsOpen(false)}>
  <Modal.Header id='billModalHeader' closeButton><h3>House Committee on Armed Services</h3></Modal.Header>
  <HouseCommitteeOnArmedServices />
</Modal>





</div>
</Col>
















</div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



<Col id='homeCol'>
<div id='columnLefttPadding'>



<h4 id='departmentOfStateHeading' ><b>United Nations News</b></h4>

<div class='whdocs' id='unTopStories'></div>


{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=united_nations&order_by=created_at&filter_column=collection_name&filter_string=United%20Nations%20News', 'unTopStories', 'United Nations News', 'N/A', 'Foreign Affairs', UNTopStories)}

<Modal id='whiteHouseDocsModal' show={unTopStoriesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setUNTopStoriesModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>United Nations News</h3></Modal.Header>
<UNTopStories/>
</Modal>





</div>
</Col>
















</div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



<Col id='homeCol'>
<div id='columnLefttPadding'>



<Button id='viewFullLegislativePageButton' size='lg' href='/us/foreignaffairs' >View all Foreign Affairs news</Button>










</div>
</Col>
















</div>

</Carousel.Item>






</Carousel>
</Col>

              {/*Health Column */}
 <Col id='homeColumn'>

<Row id="topicPageBranchRow">

<h3 id='branchHeading' onClick={() => window.open('/us/health', "_self")}> <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-heart-pulse" viewBox="0 0 16 16">
  <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053.918 3.995.78 5.323 1.508 7H.43c-2.128-5.697 4.165-8.83 7.394-5.857q.09.083.176.171a3 3 0 0 1 .176-.17c3.23-2.974 9.522.159 7.394 5.856h-1.078c.728-1.677.59-3.005.108-3.947C13.486.878 10.4.28 8.717 2.01zM2.212 10h1.315C4.593 11.183 6.05 12.458 8 13.795c1.949-1.337 3.407-2.612 4.473-3.795h1.315c-1.265 1.566-3.14 3.25-5.788 5-2.648-1.75-4.523-3.434-5.788-5"/>
  <path d="M10.464 3.314a.5.5 0 0 0-.945.049L7.921 8.956 6.464 5.314a.5.5 0 0 0-.88-.091L3.732 8H.5a.5.5 0 0 0 0 1H4a.5.5 0 0 0 .416-.223l1.473-2.209 1.647 4.118a.5.5 0 0 0 .945-.049l1.598-5.593 1.457 3.642A.5.5 0 0 0 12 9h3.5a.5.5 0 0 0 0-1h-3.162z"/>
</svg><b id='branchHeadingBold'>Health</b><ChevronRight id='clickChevron'></ChevronRight></h3>


  </Row>
  <Carousel  controls={false} >

  <Carousel.Item interval={carouselInterval} >

<div id='healthCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='departmentOfDefenseHeading' onClick={() => setDepartmentOfHealthAndHumanServicesModalIsOpen(true)}><b>Department of Health and Human Services</b></h4>

<div id='healthAndHumanServicesDepartment'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=health_and_human_services&order_by=created_at', 'healthAndHumanServicesDepartment', 
                            'Department of Health and Human Services', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}



<Modal id='whiteHouseDocsModal' show={departmentOfHealthAndHumanServicesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHealthAndHumanServicesModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Health and Human Services</h3></Modal.Header>
  <GenerateFeed url='&skip=0&schema=united_states_of_america&table=health_and_human_services&order_by=created_at'
                              name='Department of Health and Human Services' branch='Executive' topic='N/A' buttonName='Document'/>
</Modal>





    </div>
  </Col>
















</div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='presidentialFeedHeading' onClick={() => setCDCNewsroomModalIsOpen(true)}><b>CDC Newsroom</b></h4>

<div id='cdcNewsroom'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=cdc_newsroom&order_by=created_at', 'cdcNewsroom', 
                            'CDC Newsroom', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}

<Modal id='departmentOfStateModal' show={cdcNewsroomModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCDCNewsroomModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>CDC Newsroom</h3></Modal.Header>
  <GenerateFeed url='&skip=0&schema=united_states_of_america&table=cdc_newsroom&order_by=created_at'
                              name='CDC Newsroom' branch='N/A' topic='Health' buttonName='Document'/>
</Modal>





    </div>
  </Col>
















</div>

</Carousel.Item>



<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='departmentOfDefenseHeading' onClick={() => setOutbreaksModalIsOpen(true)}><b>CDC Outbreaks</b></h4>

<div id='outbreaks'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=cdc_newsroom&order_by=created_at&filter_column=collection_name&filter_string=CDC%20Outbreaks', 'outbreaks', 'CDC Outbreaks', 'N/A', 'Health', Outbreaks)}

<Modal id='departmentOfStateModal' show={outbreaksModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setOutbreaksModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>CDC Outbreaks</h3></Modal.Header>
  <Outbreaks />
</Modal>





    </div>
  </Col>
















</div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='presidentialFeedHeading' onClick={() => setTravelNoticesModalIsOpen(true)}><b>CDC Travel Notices</b></h4>

<div id='travelNotices'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=cdc_newsroom&order_by=created_at&filter_column=collection_name&filter_string=CDC%20Travel%20Notices', 'travelNotices', 'CDC Travel Notices', 'N/A', 'Health', TravelNotices)}

<Modal id='departmentOfStateModal' show={travelNoticesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTravelNoticesModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>CDC Travel Notices</h3></Modal.Header>
  <TravelNotices />
</Modal>





    </div>
  </Col>
















</div>

</Carousel.Item>

     

<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='presidentialFeedHeading' onClick={() => setFoodRecallsModalIsOpen(true)}><b>Food Recalls</b></h4>

<div id='foodRecalls'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=food_recalls&order_by=created_at', 'foodRecalls', 
                            'Food Recalls', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}

<Modal id='departmentOfStateModal' show={foodRecallsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFoodRecallsModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Food Recalls</h3></Modal.Header>
  <GenerateFeed url='&skip=0&schema=united_states_of_america&table=food_recalls&order_by=created_at'
                              name='Food Recalls' branch='N/A' topic='Health' buttonName='Document'/>
</Modal>





    </div>
  </Col>
















</div>

</Carousel.Item>


<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>
    


    <Button id='viewFullLegislativePageButton' size='lg' href='/us/health' >View all Health news</Button>
 



    





    </div>
  </Col>
















</div>

</Carousel.Item>



 </Carousel>



  </Col>






       {/*Placeholder2 Column */}

              </Row>

              <Row id='branchesRow'>

{/*Health Column */}

<Col id='homeColumn'>

<Row id="topicPageBranchRow">

<h3 id='branchHeading' onClick={() => window.open('/us/immigration', "_self")}><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-passport" viewBox="0 0 16 16">
  <path d="M8 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6M6 8a2 2 0 1 1 4 0 2 2 0 0 1-4 0m-.5 4a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1z"/>
  <path d="M3.232 1.776A1.5 1.5 0 0 0 2 3.252v10.95c0 .445.191.838.49 1.11.367.422.908.688 1.51.688h8a2 2 0 0 0 2-2V4a2 2 0 0 0-1-1.732v-.47A1.5 1.5 0 0 0 11.232.321l-8 1.454ZM4 3h8a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1"/>
</svg> <b id='branchHeadingBold'> Immigration</b><ChevronRight id='clickChevron'></ChevronRight></h3>


</Row>
<Carousel id='healthCarousel' controls={false} >




<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='departmentOfDefenseHeading'  onClick={() => openModal('ICE',
                              <GenerateFeed 
                              url='&schema=united_states_of_america&table=immigration&order_by=created_at&filter_column=agency&filter_string=U.S.%20Immigration%20and%20Customs%20Enforcement' 
                              name='ICE News' 
                              branch='N/A' 
                              topic='Immigration' 
                              buttonName='Document' 
                            />)}><b>News</b></h4>

<div id='ice'></div>

{/* Display ICE News feed */}
{CreateFeedAPI(
  '&schema=united_states_of_america&table=immigration&order_by=created_at&filter_column=agency&filter_string=U.S.%20Immigration%20and%20Customs%20Enforcement', 
  'ice', 
  'ICE News', 
  'N/A', 
  'Immigration', 
  'Document', 
  GenerateFeed
)}

    </div>
  </Col>
</div>
</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



<Col id='homeCol'>
<div id='columnLefttPadding'>
<Button id='viewFullLegislativePageButton' size='lg' href='/us/immigration' >View all Immigration news</Button>
</div>
</Col>
</div>
</Carousel.Item>
</Carousel>






</Col>

<Col id='homeColumn'>

<Row id="topicPageBranchRow">

<h3 id='branchHeading' onClick={() => window.open('/us/infrastructure', "_self")}> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
  <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
  <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3z"/>
</svg> <b id='branchHeadingBold'> Infrastructure</b><ChevronRight id='clickChevron'></ChevronRight></h3>


</Row>
<Carousel id='healthCarousel' controls={false} >



<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



<Col id='homeCol'>
<div id='columnLefttPadding'>


<h4 id='presidentialFeedHeading'onClick={() => setDepartmentOfTransportationDocsModalIsOpen(true)}><b>Department of Transportation Documents</b></h4>




<div id='transDepartment'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=transportation&order_by=created_at&filter_column=agency&filter_string=Transportation%20Department', 'transDepartment', 'Department of Transporation Docs', 'Executive', 'N/A', DepartmentOfTransportationDocs)}




<Modal id='whiteHouseDocsModal' show={departmentOfTransportationDocsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfTransportationDocsModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Transporation</h3></Modal.Header>
<DepartmentOfTransportationDocs />
</Modal>
</div>
</Col>
</div>

</Carousel.Item>


<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



<Col id='homeCol'>
<div id='columnLefttPadding'>


<h4 id='presidentialFeedHeading' onClick={() => setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen(true)}><b>Department of Housing and Urban Development News</b></h4>


<div   id='housingAndUrbanDevelopmentDepartment2'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=housing_and_urban_development&order_by=created_at', 'housingAndUrbanDevelopmentDepartment2', 
                            'Department of Housing and Urban Development', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}



</div>
</Col>
</div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



<Col id='homeCol'>
<div id='columnLefttPadding'>


<h4 id='presidentialFeedHeading' onClick={() => setHUDDocumentsModalIsOpen(true)}><b> Department of Housing and Urban Development Documents</b></h4>


<div id='hudDocs'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=housing_and_urban_development&order_by=created_at&filter_column=collection_name&filter_string=Department%20of%20Housing%20And%20Urban%20Development%20Documents', 'hudDocs', 'Department of Housing and Urban Development Documents', 'Executive', 'N/A', DepartmentOfHousingAndUrbanDevelopmentDocs)}

<Modal id='whiteHouseDocsModal' show={hudDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHUDDocumentsModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Housing and Urban Development Documents</h3></Modal.Header>
<DepartmentOfHousingAndUrbanDevelopmentDocs/>
</Modal>

</div>
</Col>
</div>

</Carousel.Item>


<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



<Col id='homeCol'>
<div id='columnLefttPadding'>
<Button id='viewFullLegislativePageButton' size='lg' href='/us/infrastructure' >View all Infrastructure news</Button>
</div>
</Col>
</div>
</Carousel.Item>
</Carousel>






</Col>
<Col id='homeColumn'>
<Row id="topicPageBranchRow">

<h3 id='branchHeading' onClick={() => window.open('/us/justice', "_self")}> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-arms-up" viewBox="0 0 16 16">
  <path d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/>
  <path d="m5.93 6.704-.846 8.451a.768.768 0 0 0 1.523.203l.81-4.865a.59.59 0 0 1 1.165 0l.81 4.865a.768.768 0 0 0 1.523-.203l-.845-8.451A1.5 1.5 0 0 1 10.5 5.5L13 2.284a.796.796 0 0 0-1.239-.998L9.634 3.84a.7.7 0 0 1-.33.235c-.23.074-.665.176-1.304.176-.64 0-1.074-.102-1.305-.176a.7.7 0 0 1-.329-.235L4.239 1.286a.796.796 0 0 0-1.24.998l2.5 3.216c.317.316.475.758.43 1.204Z"/>
</svg><b id='branchHeadingBold'> Justice</b><ChevronRight id='clickChevron'></ChevronRight></h3>


</Row>
<Carousel  controls={false} >

<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



<Col id='homeCol'>
<div id='columnLefttPadding'>


<h4 id='presidentialFeedHeading' onClick={() => setDepartmentOfJusticeModalIsOpen(true)}><b>News</b></h4>




<div id='justiceNews'></div>


{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=justice&order_by=created_at', 'justiceNews', 
                        'Department of Justice News', 'Executive', 
                        'N/A', 'Document', GenerateFeed)}



<Modal id='whiteHouseDocsModal' show={departmentOfJusticeModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfJusticeModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Justice</h3></Modal.Header>
<DepartmentOfJustice />
</Modal>
</div>
</Col>
</div>

</Carousel.Item>





<Carousel.Item interval={carouselInterval} >
<div id='legislativeCon'>
<Col id='homeCol'>
<div id='columnLefttPadding'>
<Button id='viewFullLegislativePageButton' size='lg' href='/us/justice' >View all Justice news</Button>
</div>
</Col>
</div>
</Carousel.Item>
</Carousel>
</Col>



{/*Placeholder2 Column */}

</Row>


<Row id='branchesRow'>

<Col id='homeColumn'>

<Row id="topicPageBranchRow">

<h3 id='branchHeading' onClick={() => window.open('/us/science&tech', "_self")}> <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-rocket-takeoff" viewBox="0 0 16 16">
  <path d="M9.752 6.193c.599.6 1.73.437 2.528-.362.798-.799.96-1.932.362-2.531-.599-.6-1.73-.438-2.528.361-.798.8-.96 1.933-.362 2.532Z"/>
  <path d="M15.811 3.312c-.363 1.534-1.334 3.626-3.64 6.218l-.24 2.408a2.56 2.56 0 0 1-.732 1.526L8.817 15.85a.51.51 0 0 1-.867-.434l.27-1.899c.04-.28-.013-.593-.131-.956a9.42 9.42 0 0 0-.249-.657l-.082-.202c-.815-.197-1.578-.662-2.191-1.277-.614-.615-1.079-1.379-1.275-2.195l-.203-.083a9.556 9.556 0 0 0-.655-.248c-.363-.119-.675-.172-.955-.132l-1.896.27A.51.51 0 0 1 .15 7.17l2.382-2.386c.41-.41.947-.67 1.524-.734h.006l2.4-.238C9.005 1.55 11.087.582 12.623.208c.89-.217 1.59-.232 2.08-.188.244.023.435.06.57.093.067.017.12.033.16.045.184.06.279.13.351.295l.029.073a3.475 3.475 0 0 1 .157.721c.055.485.051 1.178-.159 2.065Zm-4.828 7.475.04-.04-.107 1.081a1.536 1.536 0 0 1-.44.913l-1.298 1.3.054-.38c.072-.506-.034-.993-.172-1.418a8.548 8.548 0 0 0-.164-.45c.738-.065 1.462-.38 2.087-1.006ZM5.205 5c-.625.626-.94 1.351-1.004 2.09a8.497 8.497 0 0 0-.45-.164c-.424-.138-.91-.244-1.416-.172l-.38.054 1.3-1.3c.245-.246.566-.401.91-.44l1.08-.107-.04.039Zm9.406-3.961c-.38-.034-.967-.027-1.746.163-1.558.38-3.917 1.496-6.937 4.521-.62.62-.799 1.34-.687 2.051.107.676.483 1.362 1.048 1.928.564.565 1.25.941 1.924 1.049.71.112 1.429-.067 2.048-.688 3.079-3.083 4.192-5.444 4.556-6.987.183-.771.18-1.345.138-1.713a2.835 2.835 0 0 0-.045-.283 3.078 3.078 0 0 0-.3-.041Z"/>
  <path d="M7.009 12.139a7.632 7.632 0 0 1-1.804-1.352A7.568 7.568 0 0 1 3.794 8.86c-1.102.992-1.965 5.054-1.839 5.18.125.126 3.936-.896 5.054-1.902Z"/>
</svg> <b id='branchHeadingBold'>Science & Tech</b><ChevronRight id='clickChevron'></ChevronRight></h3>


  </Row>
  <Carousel id='healthCarousel' controls={false} >



  <Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='presidentialFeedHeading'><b>National Science Foundation News</b></h4>
                          <div id='nsf2'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=national_science_foundation&order_by=created_at', 'nsf2', 'National Science Foundation Research News', 'Executive', 'Environment', 'Document', NationalScienceFoundation)}







    </div>
  </Col>
















</div>

</Carousel.Item>

  <Carousel.Item interval={carouselInterval} >

<div id='healthCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='presidentialFeedHeading' onClick={() =>
                            openModal('NASA',
                            <GenerateFeed
                            url='&skip=0&schema=united_states_of_america&table=nasa&order_by=created_at'
                            name='NASA' branch='N/A' topic='Science & Tech' buttonName='Doc' />)}><b>NASA</b></h4>
                          <div id='nasa2'></div>
                        
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=nasa&order_by=created_at', 'nasa2', 
                            'NASA', 'N/A', 
                            'Science & Tech', 'Doc', GenerateFeed)}
             





    </div>
  </Col>
















</div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='healthCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='presidentialFeedHeading'><b>NASA JPL News</b></h4>
                          <div id='nasaJPL'></div>
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=nasa_jpl&order_by=created_at', 'nasaJPL', 'NASA JPL News', 'Executive', 'Science and Technology', 'Document', NationalAeronauticsAndSpaceAdministration)}
                





    </div>
  </Col>
















</div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='healthCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='presidentialFeedHeading'><b>NIST News</b></h4>
                          <div id='nist'></div>
                        
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=nist&order_by=created_at', 'nist', 'NIST News', 'N/A', 'Science and Technology', NISTNews)}







    </div>
  </Col>
















</div>

</Carousel.Item>


<Carousel.Item interval={carouselInterval} >

<div id='healthCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='presidentialFeedHeading'><b>Government Accountability Office Science and Technology</b></h4>
                          <div id='gaoScienceAndTech'></div>
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=government_accountability_office_reports&order_by=created_at', 'gaoScienceAndTech', 'Government Accountability Office Science and Technology', 'N/A', 'Science and Technology', GAOScienceAndTech)}






    </div>
  </Col>
















</div>

</Carousel.Item>


<Carousel.Item interval={carouselInterval} >

<div id='healthCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='presidentialFeedHeading'><b>House Committee on Science, Space, and Technology</b></h4>
                          <div id='committeeOnScienceSpaceAndTech'></div>
                        
                            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_science_space_and_technology_meeting_feed&order_by=created_at', 'committeeOnScienceSpaceAndTech', 'House Committee on Science, Space, and Technology', 'N/A', 'Science and Technology', HouseCommitteeOnScienceSpaceAndTechnology)}








    </div>
  </Col>
















</div>

</Carousel.Item>




<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>
    


    <Button id='viewFullLegislativePageButton' size='lg' href='/us/science&tech' >View all Science and Tech news</Button>
 



    





    </div>
  </Col>
















</div>

</Carousel.Item>




 </Carousel>

 




  </Col>

{/*Health Column */}
<Col id='homeColumn'>

<Row id="topicPageBranchRow">

<h3 id='branchHeading' onClick={() => window.open('/us/regulations', "_self")}> <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-file-earmark-text" viewBox="0 0 16 16">
  <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"/>
  <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
</svg>  <b id='branchHeadingBold'>Regulations</b><ChevronRight id='clickChevron'></ChevronRight></h3>


</Row>
<Carousel  controls={false} >

<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='departmentOfDefenseHeading' onClick={() => openModal(
'Rules',
<GenerateFeed 
url='&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Rule' 
name='Rules' 
branch='N/A' 
topic='Regulations' 
buttonName='Document' 
/>)}><b>Rules</b></h4>

<div id='rules'></div>

{/* Display rules feed */}
{CreateFeedAPI(
'&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Rule', 
'rules', 
'Rules', 
'N/A', 
'Regulations', 
'Document', 
GenerateFeed
)}
    </div>
  </Col>
















</div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='departmentOfDefenseHeading' onClick={() => openModal(
      'Proposed Rules',
      <GenerateFeed 
        url='&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Proposed%20Rule' 
        name='Proposed Rules' 
        branch='N/A' 
        topic='Regulations' 
        buttonName='Document' 
      />
    )}><b>Proposed Rules</b></h4>

<div id='pr'></div>

{CreateFeedAPI(
'&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Proposed%20Rule', 
'pr', 
'Rules', 
'N/A', 
'Regulations', 
'Document', 
GenerateFeed
)}
    </div>
  </Col>
















</div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='departmentOfDefenseHeading'  onClick={() => openModal(
'Supporting & Related Material',
<GenerateFeed 
url='&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Supporting%20%26%20Related%20Material' 
name='Supporting & Related Material' 
branch='N/A' 
topic='Regulations' 
buttonName='Document' 
/>
)}><b>Supporting & Related Materials</b></h4>

<div id='supporting'></div>

{/* Display rules feed */}
{CreateFeedAPI(
'&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Supporting%20%26%20Related%20Material', 
'supporting', 
'Supporting & Related Material', 
'N/A', 
'Regulations', 
'Document', 
GenerateFeed
)}
    </div>
  </Col>
</div>
</Carousel.Item>



<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



<Col id='homeCol'>
<div id='columnLefttPadding'>



<Button id='viewFullLegislativePageButton' size='lg' href='/us/regulations' >View all Regulation news</Button>










</div>
</Col>
















</div>

</Carousel.Item>



</Carousel>



</Col>



<Col id='homeColumn'>

<Row id="topicPageBranchRow">

<h3 id='branchHeading' onClick={() => window.open('/us/spending', "_self")}>  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#FFFFFF"><path d="M444-144v-80q-51-11-87.5-46T305-357l74-30q8 36 40.5 64.5T487-294q39 0 64-20t25-52q0-30-22.5-50T474-456q-78-28-114-61.5T324-604q0-50 32.5-86t87.5-47v-79h72v79q72 12 96.5 55t25.5 45l-70 29q-8-26-32-43t-53-17q-35 0-58 18t-23 44q0 26 25 44.5t93 41.5q70 23 102 60t32 94q0 57-37 96t-101 49v77h-72Z"/></svg>   <b id='branchHeadingBold'>Spending</b><ChevronRight id='clickChevron'></ChevronRight></h3>


</Row>
<Carousel  controls={false} >

<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='departmentOfDefenseHeading'><b>Recent Awards</b></h4>

<div id='spendingAwards'></div>
                  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=recent_awards&order_by=start_date', 'spendingAwards', 
                            'Recent Awards', 'N/A', 
                            'Spending', 'Document', GenerateFeed)}
    </div>
  </Col>
















</div>

</Carousel.Item>



<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='departmentOfDefenseHeading'><b>DOGE Contract Cancellations</b></h4>

<div id='dogeSavings'></div>
                  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=doge_savings&order_by=uploaded_on&filter_column=saving_type&filter_string=Contract', 'dogeSavings', 
                            'DOGE Contract Cancellations', 'N/A', 
                            'Spending', 'Document', GenerateFeed)}
    </div>
  </Col>
















</div>

</Carousel.Item>


<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='departmentOfDefenseHeading'><b>DOGE Grant Cancellations</b></h4>

<div id='dogeSavings2'></div>
                  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=doge_savings&order_by=uploaded_on&filter_column=saving_type&filter_string=Grant', 'dogeSavings2', 
                            'DOGE Grant Cancellations', 'N/A', 
                            'Spending', 'Document', GenerateFeed)}
    </div>
  </Col>
















</div>

</Carousel.Item>

<Carousel.Item interval={carouselInterval} >

<div id='topicCon'>



  <Col id='homeCol'>
    <div id='columnLefttPadding'>


    <h4 id='departmentOfDefenseHeading'><b>DOGE Real Estate Cancellations</b></h4>

<div id='dogeSavings3'></div>
                  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=doge_savings&order_by=annual_lease&filter_column=saving_type&filter_string=Real%20Estate', 'dogeSavings3', 
                            'DOGE Real Estate Cancellations', 'N/A', 
                            'Spending', 'Document', GenerateFeed)}
    </div>
  </Col>
















</div>

</Carousel.Item>


<Carousel.Item interval={carouselInterval} >

<div id='legislativeCon'>



<Col id='homeCol'>
<div id='columnLefttPadding'>



<Button id='viewFullLegislativePageButton' size='lg' href='/us/spending' >View all Spending news</Button>










</div>
</Col>
















</div>

</Carousel.Item>



</Carousel>



</Col>


{/*Placeholder2 Column */}

</Row>


<Row id='branchesRow'>

{/*Health Column */}
<Col id='homeColumn'>
<Carousel id='healthCarousel' controls={false} >
</Carousel>
</Col>

<Col id='homeColumn'>
<Carousel id='healthCarousel' controls={false} >
</Carousel>
</Col>
<Col id='homeColumn'>




<Carousel id='healthCarousel' controls={false} >

</Carousel>






</Col>

                



{/*Placeholder2 Column */}

</Row>



<br></br>
<h5 id='branchHeadingPicker'><GeoAlt/>   States <ChevronRight id='clickChevron'></ChevronRight></h5>

  <Container >
  <Col>
  
  <Row className="d-flex justify-content-center align-items-center">
                  <Button size='sm' id='statesModalButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/alabama', '_self')
                      }}> <Image id='stateFlagMobile'  src='alabamaFlag.png'></Image> Alabama</Button>
                        <Button size='sm' id='statesModalButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/alaska', '_self')
                      }}><Image id='stateFlagMobile'  src='alaskaFlag.png'></Image> Alaska</Button>
 
<Button size='sm' id='statesModalButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/arizona', '_self')
                      }}> <Image id='stateFlagMobile'  src='arizonaFlag.png'></Image> Arizona</Button>
 </Row>
 
 <Row className="d-flex justify-content-center align-items-center">
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/arkansas', '_self')
                      }}><Image id='stateFlagMobile'  src='arkansasFlag.png'></Image> Arkansas</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/california', '_self')
                      }}><Image id='stateFlagMobile'  src='californiaFlag.png'></Image> California</Button>
 
 
<Button size='sm' id='statesModalButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/colorado', '_self')
                      }}><Image id='stateFlagMobile'  src='coloradoFlag.png'></Image> Colorado</Button>
 </Row>
 
 <Row className="d-flex justify-content-center align-items-center">
<Button size='sm' id='statesModalButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/connecticut', '_self')
                      }}><Image id='stateFlagMobile'  src='connecticutFlag.png'></Image> Connecticut</Button>
 
<Button size='sm' id='statesModalButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/delaware', '_self')
                      }}><Image id='stateFlagMobile'  src='delawareFlag.png'></Image> Delaware</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/florida', '_self')
                      }}><Image id='stateFlagMobile'  src='floridaFlag.png'></Image> Florida</Button>
 </Row>
 
 <Row className="d-flex justify-content-center align-items-center">
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/georgia', '_self')
                      }}><Image id='stateFlagMobile'  src='georgiaFlag.png'></Image> Georgia</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/hawaii', '_self')
                      }}><Image id='stateFlagMobile'  src='hawaiiFlag.png'></Image> Hawaii</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/idaho', '_self')
                      }}> <Image id='stateFlagMobile'  src='idahoFlag.png'></Image> Idaho</Button>
 </Row>
 
 <Row className="d-flex justify-content-center align-items-center">
<Button size='sm' id='statesModalButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/illinois', '_self')
                      }}> <Image id='stateFlagMobile'  src='illinoisFlag.png'></Image> Illinois</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/indiana', '_self')
                      }}><Image id='stateFlagMobile'  src='indianaFlag.png'></Image> Indiana</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/iowa', '_self')
                      }}><Image id='stateFlagMobile'  src='iowaFlag.png'></Image> Iowa</Button>
 </Row>
 
 <Row className="d-flex justify-content-center align-items-center">
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/kansas', '_self')
                      }}><Image id='stateFlagMobile'  src='kansasFlag.png'></Image> Kansas</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/kentucky', '_self')
                      }}><Image id='stateFlagMobile'  src='kentuckyFlag.png'></Image> Kentucky</Button>
 
                     
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/louisiana', '_self')
                      }}><Image id='stateFlagMobile'  src='louisianaFlag.png'></Image> Louisiana</Button>
 </Row>
 
 <Row className="d-flex justify-content-center align-items-center">
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/maine', '_self')
                      }}><Image id='stateFlagMobile'  src='maineFlag.png'></Image> Maine</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/maryland', '_self')
                      }}><Image id='stateFlagMobile'  src='marylandFlag.png'></Image> Maryland</Button>
 
<Button size='sm' id='statesModalButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/massachusetts', '_self')
                      }}><Image id='stateFlagMobile'  src='massachusettsFlag.png'></Image> Massachusetts</Button>
 </Row>
 
 <Row className="d-flex justify-content-center align-items-center">
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/michigan', '_self')
                      }}><Image id='stateFlagMobile'  src='michiganFlag.png'></Image> Michigan</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/minnesota', '_self')
                      }}><Image id='stateFlagMobile'  src='minnesotaFlag.png'></Image> Minnesota</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/mississippi', '_self')
                      }}><Image id='stateFlagMobile'  src='mississippiFlag.png'></Image> Mississippi</Button>
 </Row>
 
 <Row className="d-flex justify-content-center align-items-center">
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/missouri', '_self')
                      }}><Image id='stateFlagMobile'  src='missouriFlag.png'></Image> Missouri</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/montana', '_self')
                      }}><Image id='stateFlagMobile'  src='montanaFlag.png'></Image> Montana</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/nebraska', '_self')
                      }}><Image id='stateFlagMobile'  src='nebraskaFlag.png'></Image> Nebraska</Button>
</Row>
 
<Row className="d-flex justify-content-center align-items-center">                    
                      <Button size='sm' id='statesModalButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/nevada', '_self')
                      }}><Image id='stateFlagMobile'  src='nevadaFlag.png'></Image> Nevada</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/newhampshire', '_self')
                      }}><Image id='stateFlagMobile'  src='newHampshireFlag.png'></Image> New Hampshire</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/newjersey', '_self')
                      }}><Image id='stateFlagMobile'  src='newJerseyFlag.png'></Image> New Jersey</Button>
</Row>
 
<Row className="d-flex justify-content-center align-items-center">
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/newmexico', '_self')
                      }}><Image id='stateFlagMobile'  src='newMexicoFlag.png'></Image> New Mexico</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/newyork', '_self')
                      }}><Image id='stateFlagMobile'  src='newYorkFlag.png'></Image> New York</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/northcarolina', '_self')
                      }}><Image id='stateFlagMobile'  src='northCarolinaFlag.png'></Image>North Carolina</Button>
 </Row>
 
 <Row className="d-flex justify-content-center align-items-center">
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/northdakota', '_self')
                      }}><Image id='stateFlagMobile'  src='northDakotaFlag.png'></Image>North Dakota</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/ohio', '_self')
                      }}><Image id='stateFlagMobile'  src='ohioFlag.png'></Image>Ohio</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/oklahoma', '_self')
                      }}><Image id='stateFlagMobile'  src='oklahomaFlag.png'></Image> Oklahoma</Button>
 </Row>
 
 <Row className="d-flex justify-content-center align-items-center">
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/oregon', '_self')
                      }}><Image id='stateFlagMobile'  src='oregonFlag.png'></Image> Oregon</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/pennsylvania', '_self')
                      }}><Image id='stateFlagMobile'  src='pennsylvaniaFlag.png'></Image> Pennsylvania</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/rhodeisland', '_self')
                      }}><Image id='stateFlagMobile'  src='rhodeIslandFlag.png'></Image> Rhode Island</Button>
 </Row>
 
 <Row className="d-flex justify-content-center align-items-center">
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/southcarolina', '_self')
                      }}><Image id='stateFlagMobile'  src='southCarolinaFlag.png'></Image> South Carolina</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/southdakota', '_self')
                      }}><Image id='stateFlagMobile'  src='southDakotaFlag.png'></Image> South Dakota</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/tennessee', '_self')
                      }}><Image id='stateFlagMobile'  src='tennesseeFlag.png'></Image> Tennessee</Button>
 </Row>
 
 <Row className="d-flex justify-content-center align-items-center">
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/texas', '_self')
                      }}><Image id='stateFlagMobile'  src='texasFlag.png'></Image> Texas</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/utah', '_self')
                      }}><Image id='stateFlagMobile'  src='utahFlag.png'></Image> Utah</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/vermont', '_self')
                      }}><Image id='stateFlagMobile'  src='vermontFlag.png'></Image> Vermont</Button>
 </Row>
 
 <Row className="d-flex justify-content-center align-items-center">
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/virginia', '_self')
                      }}><Image id='stateFlagMobile'  src='virginiaFlag.png'></Image> Virginia</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/washington', '_self')
                      }}><Image id='stateFlagMobile'  src='washingtonFlag.png'></Image> Washington</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/westvirginia', '_self')
                      }}><Image id='stateFlagMobile'  src='westVirginiaFlag.png'></Image> West Virginia</Button>
 </Row>
 
 <Row className="d-flex justify-content-center align-items-center">
<Button size='sm' id='statesModalButtonsLast2' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/wisconsin', '_self')
                      }}><Image id='stateFlagMobile'  src='wisconsinFlag.png'></Image>Wisconsin</Button>
 
<Button size='sm' id='statesModalButtonsLast2' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/wyoming', '_self')
                      }}><Image id='stateFlagMobile'  src='wyomingFlag.png'></Image>Wyoming</Button>
 
 </Row>

 
 
 
 </Col>
 </Container>
 
<br></br>

  <h5 id='branchHeadingPicker'><GeoAlt/>   Change country <ChevronRight id='clickChevron'></ChevronRight></h5>

  <Col>
  <Button id='selectHomeCountryDesktop'   onClick={() => window.open('/canada', "_self")}>
        🇨🇦 Canada
      </Button>

      <Button id='selectHomeCountryDesktop'   onClick={() => window.open('/mexico', "_self")}>
      🇲🇽 Mexico
      </Button>


      <Button id='selectHomeCountryDesktop' onClick={() => window.open('/uk', "_self")}>
        🇬🇧 United Kingdom
      </Button>

      <Button id='selectHomeCountryDesktop' onClick={() => window.open('/us', "_self")}>
        🇺🇸 United States
      </Button>
      </Col>

                     <br></br>
 
   
      </div>
      </>
    );
  }





  
  
  

  

  
  //onClick={linkToBill()}
  return (
    
    <>
    
      <div className="App">
   
    




      <BrowserView>
      <div id='homePage'>
        


        <div id='homebg'>

     

          

         <DesktopNavbar />
       

            <BranchesTab />
            

            {/*onClick={() => } */}
           
           
        
            <Modal id='fullBillModal' show={legislativePageBillsEnrolledModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setLegislativePageBillsEnrolledModalIsOpen(false)}>
              <Modal.Header id='billModalHeader' closeButton><h3>Bills Enrolled (Sent to President)</h3></Modal.Header>
              <BillsEnrolled />
            </Modal>
         


          
            
              
             
              
            
          
            
            
          <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
   <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>About</Nav.Link>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/privacy', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>

      


    
          </div>
  

    </div>
      </BrowserView>
     

      <MobileView> 
        
        
                      <HomeMobile/>

     
      </MobileView>
        </div>
      


       
    </>

     
    
  );







}
}


export default Home;
