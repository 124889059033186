
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand,  ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Tree, FileMedical, FileMedicalFill, ChevronRight  } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import NationalOceanicAndAtmosphericAdministration from './NationalOceanicAndAtmosphericAdministration';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import NationalScienceFoundation from './NationalScienceFoundation';
import CDCNewsroom from './CDCNewsroom';
import Outbreaks from './Outbreaks'
import TravelNotices from './TravelNotices';
import FoodRecalls from './FoodRecalls'
import FoodSafety from './FoodSafety'
import MedWatch from './MedWatch'
import HealthyLivingFeatures from './HealthyLivingFeatures'
import PreventingChronicDisease from './PreventingChronicDisease'
import Covid19 from './Covid19'
import NovelCoronavirus from './NovelCoronavirus'
import VaccineUpdates from './VaccineUpdates'
import SeasonalFlu from './SeasonalFlu'
import {createDepartmentOfHealthAndHumanServices, 
  createCDCNewsroom, createCDCOutbreaks, 
  createCDCTravelNotices, createFoodSafety, 
  createFoodRecalls, createMedWatch, 
  createHealthyLivingFeatures, 
  createPreventingChronicDiseases, 
  createCovid19, createNovelCoronavirus, 
  createVaccineUpdates, createSeasonalFlu, Speak, CreateFeedItem, CreateFeedAPI, CreateFeedAPIRecentCacheHealth} from "./Functions";
import DesktopNavbar from './DesktopNavbar';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import SenateHealthEducationLaborCommittee from './SenateHealthEducationLaborCommittee';
import GenerateFeed from './GenerateFeed';

var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;


 



//https://evening-plains-21303.herokuapp.com/











export const SpendingMobile = () =>
 {
   render()
   
   {
  const [show, setShow] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fullscreen, setFullScreen] = useState(true);
  const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)

  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)


  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)


  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)

  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)

  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)


  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  const [cdcNewsroomModalIsOpen, setCDCNewsroomModalIsOpen] = useState(false)
  const [noaaModalIsOpen, setNOAAModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
  const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
  const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
  const [outbreaksModalIsOpen, setOutbreaksModalIsOpen] = useState(false)
  const [travelNoticesModalIsOpen, setTravelNoticesModalIsOpen] = useState(false)
  
  const [foodRecallsModalIsOpen, setFoodRecallsModalIsOpen] = useState(false)
  const [foodSafetyModalIsOpen, setFoodSafetyModalIsOpen] = useState(false)
  const [medWatchModalIsOpen, setMedWatchModalIsOpen] = useState(false)
  const [healthyLivingFeaturesModalIsOpen, setHealthyLivingFeaturesModalIsOpen] = useState(false)
  const [preventingChronicDiseaseModalIsOpen, setPreventingChronicDiseaseModalIsOpen] = useState(false)
  const [covid19ModalIsOpen, setCovid19ModalIsOpen] = useState(false)
  const [novelCoronavirusModalIsOpen, setNovelCoronavirusModalIsOpen] = useState(false)
  const [vaccineUpdatesModalIsOpen, setVaccineUpdatesModalIsOpen] = useState(false)
  const [seasonalFluModalIsOpen, setSeasonalFluModalIsOpen] = useState(false)

  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)



  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)


  return(
    <>

      


<MobileTopNavbar/>


        <div id='homebg'>
    

            <h5 id='branchHeadingMobile' >  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#FFFFFF"><path d="M444-144v-80q-51-11-87.5-46T305-357l74-30q8 36 40.5 64.5T487-294q39 0 64-20t25-52q0-30-22.5-50T474-456q-78-28-114-61.5T324-604q0-50 32.5-86t87.5-47v-79h72v79q72 12 96.5 55t25.5 45l-70 29q-8-26-32-43t-53-17q-35 0-58 18t-23 44q0 26 25 44.5t93 41.5q70 23 102 60t32 94q0 57-37 96t-101 49v77h-72Z"/></svg> 
            Spending</h5>

<br></br>
               


              <Col>


               <h5 id='presidentialFeedHeadingMobile'>Recent Awards</h5>



                  <div class='courtNewsMobile' id='spendingAwards'></div>
                  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=recent_awards&order_by=start_date', 'spendingAwards', 
                            'Recent Awards', 'N/A', 
                            'Spending', 'Document', GenerateFeed)}



   </Col>


   <Col>


<h5 id='presidentialFeedHeadingMobile'>DOGE Contract Cancellations</h5>



   <div class='courtNewsMobile'id='dogeSavings'></div>
                  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=doge_savings&order_by=uploaded_on&filter_column=saving_type&filter_string=Contract', 'dogeSavings', 
                            'DOGE Contract Cancellations', 'N/A', 
                            'Spending', 'Document', GenerateFeed)}



</Col>

<Col>


<h5 id='presidentialFeedHeadingMobile'>DOGE Grant Cancellations</h5>



   <div class='courtNewsMobile'id='dogeSavings2'></div>
                  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=doge_savings&order_by=uploaded_on&filter_column=saving_type&filter_string=Grant', 'dogeSavings2', 
                            'DOGE Grant Cancellations', 'N/A', 
                            'Spending', 'Document', GenerateFeed)}



</Col>


<Col>


<h5 id='presidentialFeedHeadingMobile'>DOGE Real Estate Cancellations</h5>



   <div class='courtNewsMobile'id='dogeSavings3'></div>
                  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=doge_savings&order_by=annual_lease&filter_column=saving_type&filter_string=Real%20Estate', 'dogeSavings3', 
                            'DOGE Real Estate Cancellations', 'N/A', 
                            'Spending', 'Document', GenerateFeed)}



</Col>


        <br></br>  <br></br>  <br></br>
          <Col>

            </Col>
            <Navbar id='navbarMobileBottom' fixed="bottom">  

          
 


<div id='navbarSpacer'></div><div id='navbarSpacer'></div>




       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
              
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
</div>




             
              
    </>
  )
}
 }



  






export default SpendingMobile;