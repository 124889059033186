
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, Pen, PauseCircle, PlayCircle, People, HeartFill, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Rocket, ChevronRight} from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import App from './App';
import Overlay from 'react-overlays/esm/Overlay';

import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
  createWhiteHouseFeedMobile,createDepartmentOfState,
  createDepartmentOfDefense, 
  createDepartmentOfJustice, createDepartmentOfTheInterior,
  createDepartmentOfAgriculture, createDepartmentOfCommerce,
  createDepartmentOfCommerceMobile, createDepartmentOfLabor,
  createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
  ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
  , createEnvironmentalProtectionAgency,
  createDepartmentOfTreasury, createNationalScienceFoundation, 
   createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, createFederalRegisterStateDepartment, createFederalRegisterTreasuryDepartment, createFederalRegisterJusticeDepartment, createFederalRegisterInteriorDepartment, createFederalRegisterAgricultureDepartment, createFederalRegisterCommerceDepartment, createFederalRegisterLaborDepartment, createFederalRegisterHealthAndHumanServicesDepartment, createFederalRegisterHousingAndUrbanDevelopmentDepartment, createFederalRegisterHomelandSecurityDepartment, createFederalRegisterEducationDepartment, createFederalRegisterVeteransAffairsDepartment, CreateFeedItem, CreateFeedAPI, CreateFeedAPIRecentCacheScienceTech
   } from './Functions'
//import ExecutiveBranchMobile from './ExecutiveBranchMobile';
import DesktopNavbar from './DesktopNavbar';
import NationalScienceFoundation from './NationalScienceFoundation';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import HouseCommitteeOnScienceSpaceAndTechnology from './HouseCommitteeOnScienceSpaceAndTechnology';
import NASAJPLNews from './NASAJPLNews';
import NISTNews from './NISTNews';
import GAOScienceAndTech from './GAOScienceAndTech';
import { ScienceAndTechnologyMobile } from './ScienceAndTechnologyMobile';
import GenerateFeed from './GenerateFeed';


const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);


var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;





  

 


  


export const ScienceAndTechnology = () =>
 {
   render()
   
   {
    const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
    const [modalInfo, setModalInfo] = useState({
      isOpen: false,
      title: '',
      content: null
    });
  
    const openModal = (title, content) => {
      setModalInfo({
        isOpen: true,
        title,
        content
      });
    };
  
    const closeModal = () => {
      setModalInfo({
        isOpen: false,
        title: '',
        content: null
      });
    };

  return(
    <>
      <BrowserView>
        <div id='homePage'>

     

        <div id='homebg'>
        <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />

    
          <DesktopNavbar/>
              <div id='sectionHeading'>
             <Row>
            <h3 id='branchHeading'><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-rocket-takeoff" viewBox="0 0 16 16">
  <path d="M9.752 6.193c.599.6 1.73.437 2.528-.362.798-.799.96-1.932.362-2.531-.599-.6-1.73-.438-2.528.361-.798.8-.96 1.933-.362 2.532Z"/>
  <path d="M15.811 3.312c-.363 1.534-1.334 3.626-3.64 6.218l-.24 2.408a2.56 2.56 0 0 1-.732 1.526L8.817 15.85a.51.51 0 0 1-.867-.434l.27-1.899c.04-.28-.013-.593-.131-.956a9.42 9.42 0 0 0-.249-.657l-.082-.202c-.815-.197-1.578-.662-2.191-1.277-.614-.615-1.079-1.379-1.275-2.195l-.203-.083a9.556 9.556 0 0 0-.655-.248c-.363-.119-.675-.172-.955-.132l-1.896.27A.51.51 0 0 1 .15 7.17l2.382-2.386c.41-.41.947-.67 1.524-.734h.006l2.4-.238C9.005 1.55 11.087.582 12.623.208c.89-.217 1.59-.232 2.08-.188.244.023.435.06.57.093.067.017.12.033.16.045.184.06.279.13.351.295l.029.073a3.475 3.475 0 0 1 .157.721c.055.485.051 1.178-.159 2.065Zm-4.828 7.475.04-.04-.107 1.081a1.536 1.536 0 0 1-.44.913l-1.298 1.3.054-.38c.072-.506-.034-.993-.172-1.418a8.548 8.548 0 0 0-.164-.45c.738-.065 1.462-.38 2.087-1.006ZM5.205 5c-.625.626-.94 1.351-1.004 2.09a8.497 8.497 0 0 0-.45-.164c-.424-.138-.91-.244-1.416-.172l-.38.054 1.3-1.3c.245-.246.566-.401.91-.44l1.08-.107-.04.039Zm9.406-3.961c-.38-.034-.967-.027-1.746.163-1.558.38-3.917 1.496-6.937 4.521-.62.62-.799 1.34-.687 2.051.107.676.483 1.362 1.048 1.928.564.565 1.25.941 1.924 1.049.71.112 1.429-.067 2.048-.688 3.079-3.083 4.192-5.444 4.556-6.987.183-.771.18-1.345.138-1.713a2.835 2.835 0 0 0-.045-.283 3.078 3.078 0 0 0-.3-.041Z"/>
  <path d="M7.009 12.139a7.632 7.632 0 0 1-1.804-1.352A7.568 7.568 0 0 1 3.794 8.86c-1.102.992-1.965 5.054-1.839 5.18.125.126 3.936-.896 5.054-1.902Z"/>
</svg><b>Science & Tech</b></h3>
{/* 
              <Button size='md' id='whiteHouseButton' variant='primary' onClick={(e) => {
                e.preventDefault();
                window.open('https://www.whitehouse.gov/', "_blank")
              }} >whitehouse.gov</Button>
            <Form id='whiteHouseSearchForm'>
              <Row>
                <Form.Group className="mb-3" >

                  <Form.Control id="whiteHouseSearch" placeholder="Search whitehouse.gov"
                    type="text" />

                </Form.Group>
                


                <Button variant="primary" id='whiteHouseSearchButton' onClick={() => whiteHouseSearch()}>
                  Search
                </Button>
              </Row>
            </Form>
             */}
            </Row>
            </div>

            <div id='branchPage'>
  <Container className='bgcolor'>
    <Container id='carouselBufferOne'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLefttPadding'>
            <h4
              id='departmentOfDefenseHeadingInCol'
              
              onClick={() => window.open('/us/recents/science&tech', "_self")}><svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                              <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
                              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
                            </svg> <b id='branchHeadingBold'>Recents</b> <ChevronRight id='clickChevron'/>
            </h4>

            <Carousel id='carouselInColumn' controls={false}>


                <Carousel.Item interval={carouselInterval}>
<Row>
  <Col>

                   
                   <div id='recent3'></div> 
{CreateFeedAPIRecentCacheScienceTech('https://api.govglance.org/posts/recent/topic?limit=1&skip=0&topic=science_and_tech', 'recent3', 
                'Recents', 'N/A', 
                'N/A', 'Doc', GenerateFeed)}


</Col>
<Col>
<div id='recent1'></div> 
{CreateFeedAPIRecentCacheScienceTech('https://api.govglance.org/posts/recent/topic?limit=1&skip=1&topic=science_and_tech', 'recent1', 
                'Recents', 'N/A', 
                'N/A', 'Doc', GenerateFeed)}


</Col>
                    </Row>
</Carousel.Item>  
 
<Carousel.Item interval={carouselInterval}>
<Row>
  <Col>

                   
                   <div id='recent4'></div> 
{CreateFeedAPIRecentCacheScienceTech('https://api.govglance.org/posts/recent/topic?limit=1&skip=2&topic=science_and_tech', 'recent4', 
                'Recents', 'N/A', 
                'N/A', 'Doc', GenerateFeed)}


</Col>
<Col>
<div id='recent5'></div> 
{CreateFeedAPIRecentCacheScienceTech('https://api.govglance.org/posts/recent/topic?limit=1&skip=3&topic=science_and_tech', 'recent5', 
                'Recents', 'N/A', 
                'N/A', 'Doc', GenerateFeed)}


</Col>
                    </Row>
</Carousel.Item>  


            </Carousel>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>
           
                     
           
            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='whBreifingFeedHeading'onClick={() =>
                            openModal('National Science Foundation News',
                            <GenerateFeed
                            url='&skip=0&schema=united_states_of_america&table=national_science_foundation&order_by=created_at'
                            name='National Science Foundation News' branch='N/A' topic='Science & Tech' buttonName='Doc' />)}><b>National Science Foundation News</b></h4>
                          <div id='nsf'></div>
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=national_science_foundation&order_by=created_at', 'nsf', 
                            'National Science Foundation News', 'N/A', 
                            'Science & Tech', 'Doc', GenerateFeed)}

                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() =>
                            openModal('NASA',
                            <GenerateFeed
                            url='&skip=0&schema=united_states_of_america&table=nasa&order_by=created_at'
                            name='NASA' branch='N/A' topic='Science & Tech' buttonName='Doc' />)}><b>NASA</b></h4>
                          <div id='nasa'></div>
                        
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=nasa&order_by=created_at', 'nasa', 
                            'NASA', 'N/A', 
                            'Science & Tech', 'Doc', GenerateFeed)}
                         
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
             
              </div>

              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='whBreifingFeedHeading'><b>NASA JPL News</b></h4>
                          <div id='nasaJPL'></div>
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=nasa_jpl&order_by=created_at', 'nasaJPL', 'NASA JPL News', 'Executive', 'Science and Technology', 'Document', NationalAeronauticsAndSpaceAdministration)}
                
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading'><b>NIST News</b></h4>
                          <div id='nist'></div>
                        
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=nist&order_by=created_at', 'nist', 'NIST News', 'N/A', 'Science and Technology', NISTNews)}

                         
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
             
              </div>

              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='whBreifingFeedHeading'><b>Government Accountability Office Science and Technology</b></h4>
                          <div id='gaoScienceAndTech'></div>
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=government_accountability_office_reports&order_by=created_at', 'gaoScienceAndTech', 'Government Accountability Office Science and Technology', 'N/A', 'Science and Technology', GAOScienceAndTech)}


                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading'><b>House Committee on Science, Space, and Technology</b></h4>
                          <div id='committeeOnScienceSpaceAndTech'></div>
                        
                            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_science_space_and_technology_meeting_feed&order_by=created_at', 'committeeOnScienceSpaceAndTech', 'House Committee on Science, Space, and Technology', 'N/A', 'Science and Technology', HouseCommitteeOnScienceSpaceAndTechnology)}


                         
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
             
              </div>



  



            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
   <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>About</Nav.Link>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/privacy', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>


</div>
         </div>
          

              </BrowserView>

              <MobileView>

 <ScienceAndTechnologyMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default ScienceAndTechnology;