import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, NavLink,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal,  Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, List, InfoCircle, FileText, FileMedicalFill, Apple,  Offcanvas, ReceiptCutoff,  ShieldShaded, Globe, Tree, CircleFill, Pen, People, MicFill, ChatSquareQuote, ChatLeftQuote, Coin, BlockquoteRight, Collection, Grid, ThreeDots, Stack, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, GeoAlt, HeartFill, Bricks, Lightbulb } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import CDCNewsroom from './CDCNewsroom';
import Outbreaks from './Outbreaks';
import TravelNotices from './TravelNotices';
import FoodSafety from './FoodSafety';
import FoodRecalls from './FoodRecalls';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnOversightAndReform from './HouseCommitteeOnOversightAndReform';
import EconomicIndicators from './EconomicIndicators';
import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import HouseCommitteeOnArmedServices from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnTheBudget from './HouseCommitteeOnTheBudget';
import HouseCommitteeOnEducationAndLabor from './HouseCommitteeOnEducationAndLabor';
import HouseFloor from './HouseFloor';
import HouseCommitteeOnEnergyAndCommerce from './HouseCommitteeOnEnergyAndCommerce';
import HouseCommitteeOnEthics from './HouseCommitteeOnEthics';
import CongressionalHearings from './CongressionalHearings';
import HouseCommitteeOnFinacialServices from './HouseCommitteeOnFinacialServices';
import HouseCommitteeOnForeignAffairs from './HouseCommitteeOnForeignAffairs';
import HouseCommitteeOnHomelandSecurity from './HouseCommitteeOnHomelandSecurity';
import HouseCommitteeOnTheJudiciary from './HouseCommitteeOnTheJudiciary';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import HouseCommitteeOnHouseAdministration from './HouseCommitteeOnHouseAdministration';
import CongressionalBudgetOffice from './CongressionalBudgetOffice';
import PresidentialProclamations from './PresidentialProclamations';
import ExecutiveOrders from './ExecutiveOrders';
import NASAJPLNews from './NASAJPLNews';
import NISTNews from './NISTNews';
import GAOScienceAndTech from './GAOScienceAndTech';
import HouseCommitteeOnScienceSpaceAndTechnology from './HouseCommitteeOnScienceSpaceAndTechnology';
import GenerateFeedRecent from './GenerateFeedRecent';
import
 {supremeCourtSearch, whiteHouseSearch, billFeedModal, 
  createHouseFloorToday, createSenateFloorToday, createTrendingBills,
  createNationalAeronauticsAndSpaceAdministration, 
  createEnvironmentalProtectionAgency, createDepartmentOfHomelandSecurity,
  createDepartmentOfVeteransAffairs, createDepartmentOfEducation,
  createDepartmentOfHousingAndUrbanDevelopment, 
  createDepartmentOfHealthAndHumanServices, createDepartmentOfLabor
, createDepartmentOfCommerce, createDepartmentOfAgriculture,
createDepartmentOfTheInterior, createDepartmentOfJustice,
createDepartmentOfTransportation, createDepartmentOfDefense,
createDepartmentOfTreasury, createDepartmentOfEnergy,
createDepartmentOfState, createBillsEnrolledMobile,
createBillsEnrolled, createNewLaws, createGAOReports,
createExecutiveOrders, createPresidentialProclamations, createCongressionalHearings,
createCongressionalBudgetOfficeMobile, createCongressionalBudgetOffice,
createHouseCommitteeOversightAndReform,
createHouseCommitteeOnNaturalResources,
createHouseCommitteeOnTheJudiciary,
createHouseCommitteeOnHouseAdministration,
createHouseCommitteeOnHomelandSecurity,
createHouseCommitteeOnForeignAffairs,
createHouseCommitteeOnFinancialServices,
createHouseCommitteeOnEthics,
createHouseCommitteeOnEnergyAndCommerce,
createHouseCommitteeOnEducationAndLabor,
createHouseCommitteeOnTheBudget,
createHouseCommitteeOnArmedServices,
createHouseCommitteeOnAppropriations,
createHouseCommitteeOnAgriculture,
createReports,
createBillFeedTwoMobile,
createBillFeedTwo,
createBillFeed, createWhiteHouseFeedMobile
, createWhiteHouseFeed, createWhiteHouseDocuments, 
createBillsPresentedFeed, createCourtAppealsMobile,
createCourtAppeals, createUSCourtNewsFeedMobile,
createUSCourtNewsFeed, saveToCollection,
createFullBillFeed, ListItem, Speak, feedText, variableTest, myMethod, myMethod2,
createEconomicIndicators, 
createSecuritiesAndExchangeCommissionPressReleases, 
createSecuritiesAndExchangeCommissionSpeechesAndStatements,
createDepartmentOfCommerceMobile, createNationWeatherServices, createEnvironmentalProtectionAgencyMobile,
createNationalScienceFoundation, createHouseCommitteeOnIntelligence, createUNTopStories, createCDCNewsroom,
createCDCOutbreaks, createCDCTravelNotices, createFoodRecalls, createFoodSafety, createElectionAssistancCommissioneNews, 
createFederalElectionCommissionNews, createBillStatus, CreateFeedItem, CreateFeedItemBillUpdates,
CreateFeedItemWithDocButton, CreateFeedItemWithLawButton, CreateFeedItemPresidentialDocuments, CreateFeedItemExecutiveOrders,
CreateFeedItemCourtAppeals, CreateFeedItemEconomicIndicators, CreateFeedItemGAOReports, CreateFeedItemCongressionalReports, CreateFeedItemSupremeCourtArguments, CreateFeedItemTest, CreateFeedItemWithDocumentFlip, CreateFeedItemWithDocButtonDateFix, CreateFeedItemWithLawButtonFlip, CreateFeedAPI, CreateFeedItemBillWithDescription, CreateFeedAPIRecentCache, CreateFeedAPIDepartmentUS, CreateFeedAPIOpinions, CreateFeedAPIArguments  } from './Functions'
import SecuritiesAndExchangeCommissionPressReleases from './SecuritiesAndExchangeCommissionPressReleases';
import SecuritiesAndExchangeCommissionSpeechesAndStatments from './SecuritiesAndExchangeCommissionSpeechesAndStatments';
import NationalScienceFoundation from './NationalScienceFoundation';
import HouseCommitteeOnIntelligence from './HouseCommitteeOnIntelligence';
import UNTopStories from './UNTopStories';
import FederalElectionCommissionNews from './FederalElectionCommissionNews';
import ElectionAssistanceComissionNews from './ElectionAssistanceComissionNews';
import BillStatus from './BillStatus';
import BillUpdates from './BillUpdates';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import SupremeCourtArguments from './SupremeCourtArguments';
import SupremeCourtOpinions from './SupremeCourtOpinions';
import DisplayHomeStateMobile from './DisplayHomeStateMobile';
import GenerateFeed from './GenerateFeed';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import HomelandSecurityDocuments from './HomelandSecurityDocuments';
import DepartmentOfEnergyDocs from './DepartmentOfEnergyDocs';
import EnergyInformationAdministration from './EnergyInformationAdministration';
import FederalEnergyRegulatoryCommission from './FederalEnergyRegulatoryCommission';
import DepartmentOfHousingAndUrbanDevelopmentDocs from './DepartmentOfHousingAndUrbanDevelopmentDocs';
import DepartmentOfTransportationDocs from './DepartmentOfTransportationDocs';
import AlcoholTobaccoFireArms from './AlcoholTobaccoFireArms';
import DepartmentOfJusticeDocs from './DepartmentOfJusticeDocs';
import PresidentDisplay from './PresidentDisplay';
import VicePresidentDisplay from './VicePresidentDisplay';
import JusticeDisplay from './JusticeDisplay';
import GenerateFeedDepartment from './GenerateFeedDepartment';
import GenerateFeedAudio from './GenerateFeedAudio';
import GenerateFeedOpinions from './GenerateFeedOpinions';
import TabBar from './Menu';
import NavigationBar from './Menu';
import BottomNavigation from './Menu';
import JustifiedExample from './Menu';
import BottomNavbar from './Menu';
import MobileOffCanvasTest from './MobileOffCanvasTest';
import MobileBackButton2 from './MobileBackButton2';

const mobileCarouselInterval = 60000;

const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);

export const HomeMobile = () =>   {
  render()

  {

//Off Canvas
const [showOffCanvas, setShowOffCanvas] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

    const [show, setShow] = useState(true);
  
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [fullscreen, setFullScreen] = useState(true);
  
    
    const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
  
    const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
  
  
    const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
    const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
    const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
  
  
    const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
    const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
    const [courtOpinionsModalIsOpen, setCourtOpinionsModalIsOpen] = useState(false)
    const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
    

    const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
  
    const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
  
    const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
  
  
    const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
    const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
    const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
    const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
    const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
    const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
    const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
    const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
    const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
    const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
    const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
    const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
    const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
    const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
    const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
    const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
    const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
    const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
    const [trendingBillsOptionOne, setTrendingBillsOptionOneModalIsOpen ] = useState(false)
    const [trendingBillsOptionTwo, setTrendingBillsOptionTwoModalIsOpen ] = useState(false)
    const [trendingBillsOptionThree, setTrendingBillsOptionThreeModalIsOpen ] = useState(false)
    const [trendingBillsOptionFour, setTrendingBillsOptionFourModalIsOpen ] = useState(false)
    const [trendingBillsOptionFive, setTrendingBillsOptionFiveModalIsOpen ] = useState(false)
    const [trendingBillsOptionSix, setTrendingBillsOptionSixModalIsOpen ] = useState(false)
    const [trendingBillsOptionSeven, setTrendingBillsOptionSevenModalIsOpen ] = useState(false)
    const [trendingBillsOptionEight, setTrendingBillsOptionEightModalIsOpen ] = useState(false)
    const [trendingBillsOptionNine, setTrendingBillsOptionNineModalIsOpen ] = useState(false)
    const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
    const [trendingBillsOptionEleven, setTrendingBillsOptionElevenModalIsOpen ] = useState(false)
    const [trendingBillsOptionTweleve, setTrendingBillsOptionTweleveModalIsOpen ] = useState(false)
    const [trendingBillsOptionThirteen, setTrendingBillsOptionThirteenModalIsOpen ] = useState(false)
    const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
    const [cdcNewsroomModalIsOpen, setCDCNewsroomModalIsOpen] = useState(false)
    const [outbreaksModalIsOpen, setOutbreaksModalIsOpen] = useState(false)
    const [travelNoticesModalIsOpen, setTravelNoticesModalIsOpen] = useState(false)
    
    const [foodRecallsModalIsOpen, setFoodRecallsModalIsOpen] = useState(false)
    const [foodSafetyModalIsOpen, setFoodSafetyModalIsOpen] = useState(false)
    const [medWatchModalIsOpen, setMedWatchModalIsOpen] = useState(false)
    const [healthyLivingFeaturesModalIsOpen, setHealthyLivingFeaturesModalIsOpen] = useState(false)
    const [preventingChronicDiseaseModalIsOpen, setPreventingChronicDiseaseModalIsOpen] = useState(false)
    const [covid19ModalIsOpen, setCovid19ModalIsOpen] = useState(false)
    const [novelCoronavirusModalIsOpen, setNovelCoronavirusModalIsOpen] = useState(false)
    const [vaccineUpdatesModalIsOpen, setVaccineUpdatesModalIsOpen] = useState(false)
    const [seasonalFluModalIsOpen, setSeasonalFluModalIsOpen] = useState(false)
      const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
      const [houseCommitteeOnAppropriationsModalIsOpen, setHouseCommitteeOnAppropriationsModalIsOpen] = useState(false)
      const [houseCommitteeOnArmedServicesModalIsOpen, setHouseCommitteeOnOnArmedServicesModalIsOpen] = useState(false)
      const [houseCommitteeOnTheBudgetModalIsOpen, setHouseCommitteeOnTheBudgetModalIsOpen] = useState(false)
      const [houseCommitteeOnEnergyAndCommerceModalIsOpen, setHouseCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
      const [houseCommitteeOnEducationAndLaborModalIsOpen, setHouseCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
      const [houseCommitteeOnEthicsModalIsOpen, setHouseCommitteeOnEthicsModalIsOpen] = useState(false)
      const [houseCommitteeOnFinancialServicesModalIsOpen, setHouseCommitteeOnFinancialServicesModalIsOpen] = useState(false)
      const [houseCommitteeOnForeignAffairsModalIsOpen, setHouseCommitteeOnForeignAffairsModalIsOpen] = useState(false)
      const [houseCommitteeOnHomelandSecurityModalIsOpen, setHouseCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
      const [houseCommitteeOnHouseAdministrationModalIsOpen, setHouseCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
      const [houseCommitteeOnTheJudiciaryModalIsOpen, setHouseCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
      const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
      const [houseCommitteeOnOversightAndReformModalIsOpen, setHouseCommitteeOnOversightAndReformModalIsOpen] = useState(false)
      const [congressionalBudgetOfficeModalIsOpen, setCongressionalBudgetOfficeModalIsOpen] = useState(false)
      const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
      const [economicIndicatorsModalIsOpen, setEconomicIndicatorsModalIsOpen] = useState(false)
      const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
      const [secPressModalIsOpen, setSECPressModalIsOpen] = useState(false)
      const [secSAndSModalIsOpen, setSECSAndSModalIsOpen] = useState(false)
      const [unTopStoriesModalIsOpen, setUNTopStoriesModalIsOpen] = useState(false)
      const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  
  
  
      const [congressionalHearingsModalIsOpen, setCongressionalHearingsModalIsOpen] = useState(false)
  
      const [houseFloorModalIsOpen, setHouseFloorModalIsOpen] = useState(false)
  
  
  
    const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
    const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
    const [houseCommitteeOnIntelligenceModalIsOpen, setHouseCommitteeOnIntelligenceModalIsOpen] = useState(false)
  
    const [fecModalIsOpen, setFECModalIsOpen] = useState(false)
    const [eacModalIsOpen, setEACModalIsOpen] = useState(false)
  
    


  
    const [alertModalIsOpen, setAlertModalIsOpen] = useState(true)

    const [
      number1ModalIsOpen, setNumber1ModalIsOpen,
      number2ModalIsOpen, setNumber2ModalIsOpen,
      number3ModalIsOpen, setNumber3ModalIsOpen,
      number4ModalIsOpen, setNumber4ModalIsOpen,
      number5ModalIsOpen, setNumber5ModalIsOpen,
      number6ModalIsOpen, setNumber6ModalIsOpen,
      number7ModalIsOpen, setNumber7ModalIsOpen,
      number8ModalIsOpen, setNumber8ModalIsOpen,
      number9ModalIsOpen, setNumber9ModalIsOpen,
      number10ModalIsOpen, setNumber10ModalIsOpen,
      number11ModalIsOpen, setNumber11ModalIsOpen,
      number12ModalIsOpen, setNumber12ModalIsOpen,
      number13ModalIsOpen, setNumber13ModalIsOpen,
      number14ModalIsOpen, setNumber14ModalIsOpen,
      number15ModalIsOpen, setNumber15ModalIsOpen,
      number16ModalIsOpen, setNumber16ModalIsOpen,
      number17ModalIsOpen, setNumber17ModalIsOpen,
      number18ModalIsOpen, setNumber18ModalIsOpen,
      number19ModalIsOpen, setNumber19ModalIsOpen,
      number20ModalIsOpen, setNumber20ModalIsOpen,
      number21ModalIsOpen, setNumber21ModalIsOpen,
      number22ModalIsOpen, setNumber22ModalIsOpen,
      number23ModalIsOpen, setNumber23ModalIsOpen,
      number24ModalIsOpen, setNumber24ModalIsOpen,
      number25ModalIsOpen, setNumber25ModalIsOpen,
    ] = useState(false);

        /* Defense */
        const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
        const [homelandDocsModalIsOpen, setHomelandDocsModalIsOpen] = useState(false)
      
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
        const [diplomaticSecurityModalIsOpen, setDiplomaticSecurityModalIsOpen] = useState(false)
     /* Infrastructure */
     const [departmentOfHousingAndUrbanDevelopmentDocsModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentDocsModalIsOpen] = useState(false)
     const [enforcementsModalIsOpen, setEnforcemnetsModalIsOpen] = useState(false)

     const [departmentofJusticeNewsModalIsOpen, setDepartmentofJusticeNewsModalIsOpen] = useState(false)
     const [departmentofJusticeDocsModalIsOpen, setDepartmentofJusticeDocsModalIsOpen] = useState(false)
     const [alchoholTabaccoModalIsOpen, setAlchoholTabaccoModalIsOpen] = useState(false)

     const [modalInfo, setModalInfo] = useState({
      isOpen: false,
      title: '',
      content: null
    });
  
    const openModal = (title, content) => {
      setModalInfo({
        isOpen: true,
        title,
        content
      });
    };
  
    const closeModal = () => {
      setModalInfo({
        isOpen: false,
        title: '',
        content: null
      });
    };
    
     const mobileStatesContainer = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
     
    };
  return(
     <>

<div id='homebg'>
<MobileTopNavbar/>
<CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />

<h5 id='branchHeadingMobile' onClick={() => window.open('/us/recents', "_self")}> <svg style={{gap: '5px'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                              <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
                              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
                            </svg> Recents <ChevronRight id='clickChevron'></ChevronRight></h5>

<div class='courtNewsMobile'  id='recent'></div> 
{CreateFeedAPIRecentCache('https://api.govglance.org/posts/recent/schema?limit=1&skip=0&schema=united_states_of_america', 'recent', 
                'Recent', 'N/A', 
                'N/A', 'Doc', GenerateFeed)}
<br></br>
{/*Legislative Mobile Carousel*/}
    <h5 id='branchHeadingMobile' onClick={() => window.open('/us/legislative', "_self")}> <Pen></Pen> Legislative<ChevronRight id='clickChevron'></ChevronRight></h5>
  {/*onClick={() => setModalIsOpen(true)}*/}

<Carousel controls={false} >
{/* */}

  <Carousel.Item interval={mobileCarouselInterval}>

    <div id='legislativeConMobile'>

  <Col>
      <Row id = 'mobileFeedHeadingRow'><h5 id='feedName' onClick={() => setFullBillModalIsOpen(true)}>Bill Updates</h5></Row>
      <div class='courtNewsMobile' id='bills'></div>
             {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
             {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=congressional_bills&order_by=action_date', 'bills', 
                            'Bill Updates', 'Legislative', 
                            'N/A', 'Bill', GenerateFeed)}

                            

<Modal id='fullBillModal' show={fullBillModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFullBillModalIsOpen(false)}>
                      <Modal.Header id='billModalHeader' closeButton><h3>Bill Updates</h3></Modal.Header>
                      <GenerateFeed url='&skip=0&schema=united_states_of_america&table=congressional_bills&order_by=action_date' 
                            name='Bill Updates' branch='Legislative' topic='N/A' buttonName='Bill' />
                    </Modal>
        </Col>


        
      
        </div>
       
    

        <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />

    <Modal show={modalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModalIsOpen(false)}>
      <Modal.Header id='iframeModalHeader' closeButton>Congressional Record</Modal.Header>
        <Card id='iframeColMobile'><iframe src='https://www.congress.gov/congressional-record' height='1000px' width='410px' frameBorder='0'><div id='modalFeedMobile'></div></iframe></Card></Modal>



</Carousel.Item>



<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
  <Row id = 'mobileFeedHeadingRow'><h5 id='feedName' onClick={() => setCongressionalHearingsModalIsOpen(true)}>Congressional Hearings</h5></Row>
  <div class='courtNewsMobile' id='congressionalHearings'></div>

                     {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=congressional_hearings&order_by=created_at', 'congressionalHearings', 
                            'Congressional Hearings', 'Legislative', 
                            'N/A', 'Hearing', GenerateFeed)}

<Modal id='fullBillModal' show={congressionalHearingsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
centered onHide={() => setCongressionalHearingsModalIsOpen(false)}>
                      <Modal.Header id='billModalHeader' closeButton><h3>Congressional Hearings</h3></Modal.Header>
                      <GenerateFeed url='&skip=0&schema=united_states_of_america&table=congressional_hearings&order_by=created_at' 
                            name='Congressional Hearings' branch='Legislative' topic='N/A' buttonName='Hearing' />
                    </Modal>
    </Col>



  
    </div>
   



</Carousel.Item>


  <Carousel.Item interval={mobileCarouselInterval}>

    <div id='legislativeConMobile'>
        <Col>
        <Row id = 'mobileFeedHeadingRow'><h5 id='feedName' onClick={() => setBillsEnrolledModalIsOpen(true)}>Bills Enrolled (Sent to President)</h5></Row>
        <div class='courtNewsMobile' id='billsEnrolled'></div>
                        
        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=congressional_bills_enrolled&order_by=date_issued', 
                    'billsEnrolled', 
                    'Bills Enrolled', 'Legislative', 
                    'N/A', 'Bill', GenerateFeed)}
                                <Modal id='fullBillModal' show={billsEnrolledModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setBillsEnrolledModalIsOpen(false)}>
                      <Modal.Header id='billModalHeader' closeButton><h3>Bills Enrolled (Sent to President)</h3></Modal.Header>
                      <GenerateFeed
  url='&skip=0&schema=united_states_of_america&table=congressional_bills_enrolled&order_by=date_issued'
  name='Bills Enrolled'
  branch='Legislative'
  topic='N/A'
  buttonName='Bill'
/>
                    </Modal>
            </Col>

       
      
    </div>



    </Carousel.Item>



  <Carousel.Item interval={mobileCarouselInterval}>

    <div id='legislativeConMobile'>
      


        <Col>
        <Row id = 'mobileFeedHeadingRow'><h5 id='feedName'>Laws</h5></Row>
      
        <div class='courtNewsMobile' id='newLaws'></div>
                         
                         {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                         {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=public_and_private_laws&order_by=date_issued', 'newLaws', 
                            'Laws', 'Legislative', 
                            'N/A', 'Law', GenerateFeed)}
<Modal id='fullBillModal' show={newLawsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNewLawsModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>Laws</h3></Modal.Header>
                          <GenerateFeed url='&skip=0&schema=united_states_of_america&table=public_and_private_laws&order_by=date_issued' 
                            name='Laws' branch='Legislative' topic='N/A' buttonName='Law' />
                        </Modal>
          </Col>





   

       
     
    </div>



  </Carousel.Item>

  <Carousel.Item interval={mobileCarouselInterval}>

    <div id='legislativeConMobile'>
     
  

        <Col>
        <Row id = 'mobileFeedHeadingRow'><h5 id='feedName'>Government Accountability Office Reports</h5></Row>
        <div class='courtNewsMobile' id='gaoReports'></div>
                         
        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=government_accountability_office_reports&order_by=created_at', 'gaoReports', 'Government Accountability Office Reports', 'Legislative', 'N/A', GAOReports)}
                        
            </Col>

        
    </div>


    <Modal id='mobileFullBillModal' show={goaReportsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setGAOReportsModalIsOpen(false)}>
      <Modal.Header id='mobileBillModalHeader' closeButton><h3>Government Accountability Office Reports</h3></Modal.Header>
      <GAOReports />
    </Modal>




   



  </Carousel.Item>

  <Carousel.Item interval={mobileCarouselInterval}>

    <div id='legislativeConMobile'>
      


        <Col>
        <Row id = 'mobileFeedHeadingRow'> <h5 id='feedName'>Congressional Reports</h5></Row>
        <div class='courtNewsMobile' id='reports'></div>
                         {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                         {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=congressional_reports&order_by=created_at', 'reports', 
                            'Congressional Reports', 'Legislative', 
                            'N/A', 'Report', GenerateFeed)}   
                    

                    <Modal id='fullBillModal' show={reportModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                    centered onHide={() => setReportModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>Congressional Reports</h3></Modal.Header>
                          <GenerateFeed url='&skip=0&schema=united_states_of_america&table=congressional_reports&order_by=created_at' 
                            name='Congressional Reports' branch='Legislative' topic='N/A' buttonName='Report' />
                        </Modal>
          </Col>

        
     
    </div>



  </Carousel.Item>

  <Carousel.Item interval={mobileCarouselInterval}>

    <div id='legislativeConMobile'>

        <Col>
        <Row id = 'mobileFeedHeadingRow'><h5 id='feedName'>Congressional Budget Office</h5></Row>
        <div class='courtNewsMobile' id='congressionalBudgetOffice'></div>
                      






                       {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=supreme_court_opinions&order_by=created_at', 'congressionalBudgetOffice', 'Congressional Budget Office', 'Legislative', 'Economy', 'Document', CongressionalBudgetOffice)}
          </Col>



      

          <Modal id='fullBillModal' show={congressionalBudgetOfficeModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCongressionalBudgetOfficeModalIsOpen(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3>Congressional Budget Office</h3></Modal.Header>
            <CongressionalBudgetOffice />
          </Modal>

  
    </div>



  </Carousel.Item>

  <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/legislative' >View all Legislative news</Button>
</div>
</Col>

</div>



</Carousel.Item>


 

</Carousel>
  {/* Executive Mobile Carousel*/}
  <h5 id='branchHeadingMobile' onClick={() => window.open('/us/executive', "_self")}><People></People> Executive<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
  
    
    <Col>



    <Row id = 'mobileFeedHeadingRow'><h5 id='feedName' >White House Briefing Room</h5></Row>


    <div class='courtNewsMobile'  id='wh'></div>
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=the_white_house&order_by=created_at', 'wh', 
                            'White House Briefing Room', 'Executive', 
                            'N/A', '', GenerateFeed)}

      </Col>

  
  </div>
  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
  
    
    <Col>



    <Row id = 'mobileFeedHeadingRow'><h5 id='feedName' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>Presidential Actions</h5></Row>


    <div class='courtNewsMobile' id='pa'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                   
 {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=the_white_house&order_by=created_at&filter_column=category&filter_string=Presidential%20Actions', 'pa', 
                            'Presidential Actions', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}

                      <Modal id='whiteHouseFeedModal' show={whiteHouseFeedModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setWhiteHouseFeedModalIsOpen(false)}>
                        <Modal.Header id='whiteHouseFeedModalHeader' closeButton><h3>Presidental Actions</h3></Modal.Header>
                        <GenerateFeed url='&skip=0&schema=united_states_of_america&table=the_white_house&order_by=created_at&filter_column=category&filter_string=Presidential%20Actions' 
                        name='Presidential Actions' branch='Executive' topic='N/A' buttonName='Document' />
                      </Modal>
    
      </Col>

  
  </div>
  </Carousel.Item>

{/* <Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
  
    
    <Col>



    <Row id = 'mobileFeedHeadingRow'><h5 id='feedName' onClick={() => window.open('/us/presidents', "_self")}>President</h5></Row>
<PresidentDisplay/>
      </Col>

  
  </div>
  </Carousel.Item> */}



  <Carousel.Item interval={mobileCarouselInterval}>
    <div id='executiveConMobile' >
      
    <Col> 

    <Row id = 'mobileFeedHeadingRow'><h5 id='presidentialFeedHeadingMobileHome' onClick={() => setWhiteHouseDocsModalIsOpen(true)}>Presidential Documents</h5></Row>


    
    <div class='courtNewsMobile' id='whdocs'></div>
                        
    {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=compilation_of_presidential_documents&order_by=created_at', 'whdocs', 
                            'Presidential Documents', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}

                          <Modal id='whiteHouseDocsModal' show={whiteHouseDocsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setWhiteHouseDocsModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Presidential Documents</h3></Modal.Header>
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=compilation_of_presidential_documents&order_by=created_at' 
                            name='Presidential Documents' branch='Executive' topic='N/A' buttonName='Document' />
                          </Modal>
    </Col>

    
      

   

    </div>




      </Carousel.Item>

      {/* <Carousel.Item interval={mobileCarouselInterval}>
    <div id='executiveConMobile' >
      
    <Col>

   <Row id = 'mobileFeedHeadingRow'> <h5 id='presidentialFeedHeadingMobileHome' onClick={() => setExecutiveOrdersModalIsOpen(true)}>Executive Orders</h5></Row>

   <div class='courtNewsMobile' id='executiveOrders'></div>
      
   {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=president_executive_orders&order_by=created_at', 'executiveOrders', 
                            'Executive Orders', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}
        <Modal id='whiteHouseDocsModal' show={executiveOrdersModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() =>setExecutiveOrdersModalIsOpen(false)}>
          <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Executive Orders</h3></Modal.Header>
          <GenerateFeed url='&skip=0&schema=united_states_of_america&table=president_executive_orders&order_by=created_at' 
                            name='Executive Orders' branch='Executive' topic='N/A' buttonName='Document' />
        </Modal>
     </Col>


    

    
    </div>




      </Carousel.Item> */}

      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='executiveConMobile' >
     
    <Col >

    <Row id = 'mobileFeedHeadingRow'><h5 id='presidentialFeedHeadingMobileHome'>Presidential Proclamations</h5></Row>


   
    <div class='courtNewsMobile' id='presidentialProclomations2'></div>
      
   
    {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=presidential_proclamation&order_by=created_at', 'presidentialProclomations2', 
                            'Presidential', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}
   </Col>

    

    <Modal id='whiteHouseDocsModal' show={presidentialProclamationsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setPresidentialProclamationsModalIsOpen(false)}>
      <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Executive Orders</h3></Modal.Header>
      <PresidentialProclamations />
    </Modal>

    


    </div>




      </Carousel.Item>

      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='executiveConMobile' >

    <Col>
    <div id='viewFullPageButton'>
    <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/executive' >View all Executive news</Button>
    </div>
    </Col>

    </div>




      </Carousel.Item>



{/*
  <Carousel.Item interval={mobileCarouselInterval}>
    <div id='executiveConMobile' >
      <Container class='bgcolor'>
       

        <h5 id='presidentialFeedHeadingMobileHome'>Department of State</h5>


        <Col>

          <div class='whdocsMobile' id='stateDepartment'></div>
         
          {createDepartmentOfState()}
          </Col>


        <Button size='lg' id='whDocsViewMoreButtonMobile' onClick={() => setDepartmentOfStateModalIsOpen(true)}> <Files size={20}></Files> View More</Button>

        <Modal id='whiteHouseDocsModal' show={departmentOfStateModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfStateModalIsOpen(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of State</h3></Modal.Header>
          <DepartmentOfState />
        </Modal>



      </Container>
    </div>




  </Carousel.Item>



  <Carousel.Item interval={mobileCarouselInterval}>
    <div id='executiveConMobile' >
      <Container class='bgcolor'>
       

        <h5 id='presidentialFeedHeadingMobileHome'>Department of Treasury</h5>


        <Col>

          <div class='whdocsMobile' id='treasuryDepartment'></div>
       
          {createDepartmentOfTreasury()}
        </Col>


        <Button size='lg' id='whDocsViewMoreButtonMobile' onClick={() => setDepartmentOfTreasuryModalIsOpen(true)}> <Files size={20}></Files> View More</Button>

        <Modal id='whiteHouseDocsModal' show={departmentOfTreasuryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfTreasuryModalIsOpen(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Treasury</h3></Modal.Header>
          <DepartmentOfTreasury />
        </Modal>



      </Container>
    </div>




  </Carousel.Item>




  <Carousel.Item interval={mobileCarouselInterval}>
    <div id='executiveConMobile' >
      <Container class='bgcolor'>
       

        <h5 id='presidentialFeedHeadingMobileHome'>Department of Defense</h5>


        <Col>

          <div class='whdocsMobile' id='defenseDepartment'></div>
         
          {createDepartmentOfDefense()}
        </Col>


        <Button size='lg' id='whDocsViewMoreButtonMobile' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}> <Files size={20}></Files> View More</Button>

        <Modal id='whiteHouseDocsModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Defense</h3></Modal.Header>
          <DepartmentOfDefense />
        </Modal>



      </Container>
    </div>




  </Carousel.Item>



  <Carousel.Item interval={mobileCarouselInterval}>
    <div id='executiveConMobile' >
      <Container class='bgcolor'>
        

        <h5 id='presidentialFeedHeadingMobileHome'>Department of Justice</h5>


        <Col>

          <div class='whdocsMobile' id='justiceDepartment'></div>
         
          {createDepartmentOfJustice()}
        </Col>


        <Button size='lg' id='whDocsViewMoreButtonMobile' onClick={() => setDepartmentOfJusticeModalIsOpen(true)}> <Files size={20}></Files> View More</Button>

        <Modal id='whiteHouseDocsModal' show={departmentOfJusticeModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfJusticeModalIsOpen(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Justice</h3></Modal.Header>
          <DepartmentOfJustice />
        </Modal>



      </Container>
    </div>




  </Carousel.Item>


  <Carousel.Item interval={mobileCarouselInterval}>
    <div id='executiveConMobile' >
      <Container class='bgcolor'>
       

        <h5 id='presidentialFeedHeadingMobileHome'>Department of the Interior</h5>


        <Col>

          <div class='whdocsMobile' id='theInteriorDepartment'></div>
        
          {createDepartmentOfTheInterior()}
        </Col>


        <Button size='lg' id='whDocsViewMoreButtonMobile' onClick={() => setDepartmentOfTheInteriorModalIsOpen(true)}> <Files size={20}></Files> View More</Button>

        <Modal id='whiteHouseDocsModal' show={departmentOfTheInteriorModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfTheInteriorModalIsOpen(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of the Interior</h3></Modal.Header>
          <DepartmentOfTheInterior />
        </Modal>



      </Container>
    </div>




  </Carousel.Item>


  <Carousel.Item interval={mobileCarouselInterval}>
    <div id='executiveConMobile' >
      <Container class='bgcolor'>
        

        <h5 id='presidentialFeedHeadingMobileHome'>Department of Agriculture</h5>


        <Col>

          <div class='whdocsMobile' id='agricultureDepartment'></div>
       
          {createDepartmentOfAgriculture()}
        </Col>


        <Button size='lg' id='whDocsViewMoreButtonMobile' onClick={() => setDepartmentOfAgricultureModalIsOpen(true)}> <Files size={20}></Files> View More</Button>

        <Modal id='whiteHouseDocsModal' show={departmentOfAgricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfAgricultureModalIsOpen(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Agriculture</h3></Modal.Header>
          <DepartmentOfAgriculture />
        </Modal>



      </Container>
    </div>




  </Carousel.Item>



  <Carousel.Item interval={mobileCarouselInterval}>
    <div id='executiveConMobile' >
      <Container class='bgcolor'>
       

        <h5 id='presidentialFeedHeadingMobileHome'>Department of Commerce</h5>


        <Col>

          <div class='whdocsMobile' id='commerceDepartment'></div>
         
          {createDepartmentOfCommerce()}
        </Col>


        <Button size='lg' id='whDocsViewMoreButtonMobile' onClick={() => setDepartmentOfCommerceModalIsOpen(true)}> <Files size={20}></Files> View More</Button>

        <Modal id='whiteHouseDocsModal' show={departmentOfCommerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfCommerceModalIsOpen(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Commerce</h3></Modal.Header>
          <DepartmentOfCommerce />
        </Modal>



      </Container>
    </div>




  </Carousel.Item>



  <Carousel.Item interval={mobileCarouselInterval}>
    <div id='executiveConMobile' >
      <Container class='bgcolor'>
       

        <h5 id='presidentialFeedHeadingMobileHome'>Department of Labor</h5>


        <Col>

          <div class='whdocsMobile' id='laborDepartment'></div>
        
          {createDepartmentOfLabor()}
        </Col>


        <Button size='lg' id='whDocsViewMoreButtonMobile' onClick={() => setDepartmentOfLaborModalIsOpen(true)}> <Files size={20}></Files> View More</Button>

        <Modal id='whiteHouseDocsModal' show={departmentOfLaborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfLaborModalIsOpen(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Labor</h3></Modal.Header>
          <DepartmentOfLabor />
        </Modal>



      </Container>
    </div>




  </Carousel.Item>



  <Carousel.Item interval={mobileCarouselInterval}>
    <div id='executiveConMobile' >
      <Container class='bgcolor'>
       

        <h5 id='presidentialFeedHeadingMobileHome'>Department of Health and Human Services</h5>


        <Col>

          <div class='whdocsMobile' id='healthAndHumanServicesDepartment'></div>
         
          {createDepartmentOfHealthAndHumanServices()}
        </Col>


        <Button size='lg' id='whDocsViewMoreButtonMobile' onClick={() => setDepartmentOfHealthAndHumanServicesModalIsOpen(true)}> <Files size={20}></Files> View More</Button>

        <Modal id='whiteHouseDocsModal' show={departmentOfHealthAndHumanServicesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHealthAndHumanServicesModalIsOpen(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Health and Human Services</h3></Modal.Header>
          <DepartmentOfHealthAndHumanServices />
        </Modal>



      </Container>
    </div>




  </Carousel.Item>




  <Carousel.Item interval={mobileCarouselInterval}>
    <div id='executiveConMobile' >
      <Container class='bgcolor'>
        

        <h5 id='presidentialFeedHeadingMobileHome'>Department of Housing and Urban Development</h5>


        <Col>

          <div class='whdocsMobile' id='housingAndUrbanDevelopmentDepartment'></div>
         
          {createDepartmentOfHousingAndUrbanDevelopment()}
        </Col>


        <Button size='lg' id='whDocsViewMoreButtonMobile' onClick={() => setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen(true)}> <Files size={20}></Files> View More</Button>

        <Modal id='whiteHouseDocsModal' show={departmentOfHousingAndUrbanDevelopmentModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen(false)(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Housing and Urban Development</h3></Modal.Header>
          <DepartmentOfHousingAndUrbanDevelopment />
        </Modal>



      </Container>
    </div>




  </Carousel.Item>



  <Carousel.Item interval={mobileCarouselInterval}>
    <div id='executiveConMobile' >
      <Container class='bgcolor'>
       

        <h5 id='presidentialFeedHeadingMobileHome'>Department of Education</h5>


        <Col>

          <div class='whdocsMobile' id='educationDepartment'></div>
         
          {createDepartmentOfEducation()}
        </Col>


        <Button size='lg' id='whDocsViewMoreButtonMobile' onClick={() => setDepartmentOfEducationModalIsOpen(true)}> <Files size={20}></Files> View More</Button>

        <Modal id='whiteHouseDocsModal' show={departmentOfEducationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfEducationModalIsOpen(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Education</h3></Modal.Header>
          <DepartmentOfEducation />
        </Modal>



      </Container>
    </div>




  </Carousel.Item>



  <Carousel.Item interval={mobileCarouselInterval}>
    <div id='executiveConMobile' >
      <Container class='bgcolor'>
        

        <h5 id='presidentialFeedHeadingMobileHome'>Department of Veterans Affairs</h5>


        <Col>

          <div class='whdocsMobile' id='veteransAffairsDepartment'></div>
          
          {createDepartmentOfVeteransAffairs()}
        </Col>


        <Button size='lg' id='whDocsViewMoreButtonMobile' onClick={() => setDepartmentOfVeteransAffairsModalIsOpen(true)}> <Files size={20}></Files> View More</Button>

        <Modal id='whiteHouseDocsModal' show={departmentOfVeteransAffairsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfVeteransAffairsModalIsOpen(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Veterans Affairs</h3></Modal.Header>
          <DepartmentOfVeteranAffairs />
        </Modal>



      </Container>
    </div>




  </Carousel.Item>



  <Carousel.Item interval={mobileCarouselInterval}>
    <div id='executiveConMobile' >
      <Container class='bgcolor'>
        

        <h5 id='presidentialFeedHeadingMobileHome'>Department of Homeland Security</h5>


        <Col>

          <div class='whdocsMobile' id='homelandSecurityDepartment'></div>
        
          {createDepartmentOfHomelandSecurity()}
        </Col>


        <Button size='lg' id='whDocsViewMoreButtonMobile' onClick={() => setDepartmentOfHomelandSecurityModalIsOpen(true)}> <Files size={20}></Files> View More</Button>

        <Modal id='whiteHouseDocsModal' show={departmentOfHomelandSecurityModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHomelandSecurityModalIsOpen(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Homeland Security</h3></Modal.Header>
          <DepartmentOfHomelandSecurity />
        </Modal>



      </Container>
    </div>




  </Carousel.Item>




  <Carousel.Item interval={mobileCarouselInterval}>
    <div id='executiveConMobile' >
      <Container class='bgcolor'>
        

        <h5 id='presidentialFeedHeadingMobileHome'>EPA</h5>


        <Col>

          <div class='whdocsMobile' id='epa'></div>
        
          {createEnvironmentalProtectionAgency()}
        </Col>


        <Button size='lg' id='whDocsViewMoreButtonMobile' onClick={() => setEPAModalIsOpen(true)}> <Files size={20}></Files> View More</Button>

        <Modal id='whiteHouseDocsModal' show={epaModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setEPAModalIsOpen(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Environmental Protection Agency (EPA)</h3></Modal.Header>
          <EnvironmentalProtectionAgency />
        </Modal>



      </Container>
    </div>




  </Carousel.Item>



  <Carousel.Item interval={mobileCarouselInterval}>
    <div id='executiveConMobile' >
      <Container class='bgcolor'>
        

        <h5 id='presidentialFeedHeadingMobileHome'>NASA</h5>


        <Col>

          <div class='whdocsMobile' id='nasa'></div>
        
          {createNationalAeronauticsAndSpaceAdministration()}
        </Col>


        <Button size='lg' id='whDocsViewMoreButtonMobile' onClick={() => setNASAModalIsOpen(true)}> <Files size={20}></Files> View More</Button>

        <Modal id='whiteHouseDocsModal' show={nasaModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNASAModalIsOpen(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Aeronautics and Space Administration (NASA)</h3></Modal.Header>
          <NationalAeronauticsAndSpaceAdministration />
        </Modal>



      </Container>
    </div>





  </Carousel.Item>
  */}


      </Carousel >

  

{/* Judicial Mobile Carousel*/}
  <h5 id='branchHeadingMobile' onClick={() => window.open('/us/judicial', "_self")}><svg xmlns="http://www.w3.org/2000/svg" height="27.54px" viewBox="0 -960 960 960" width="27.54px" fill="#FFFFFF"><path d="M160-120v-80h480v80H160Zm226-194L160-540l84-86 228 226-86 86Zm254-254L414-796l86-84 226 226-86 86Zm184 408L302-682l56-56 522 522-56 56Z"/></svg>   Judicial<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>



  <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName'>U.S. Court News</h5></Row>

          
            <div class='courtNewsMobile' id='courtNews'></div>


                         
  
            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=uscourts&order_by=created_at', 'courtNews', 'U.S. Court News', 'Judicial', 'N/A', 'Docs', FullUSCourtNews)}


          </Col>

      
        
       

        <Modal id='fullUSCourtModal' show={courtNewsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtNewsModalIsOpen(false)}>

          <Modal.Header id='fullUSCourtNewsModalHeader' closeButton><h3>U.S. Court News</h3></Modal.Header>
          <FullUSCourtNews />

        </Modal>
     
      </div>
      </Carousel.Item>

  <Carousel.Item interval={mobileCarouselInterval}>
    <div id='judicalConMobile'>
    <Col>
      <Row id = 'mobileFeedHeadingRow'>  <h5 id='feedName'>U.S. Court of Appeals</h5></Row>


       

      <div class='courtNewsMobile' id='courtAppeals'></div>
                        
                        {CreateFeedItemCourtAppeals('https://www.govinfo.gov/rss/uscourts-ca13.xml', 'courtAppeals', 'U.S. Court of Appeals', 'Judicial', 'N/A', CourtAppeals)}

          </Col>
       



        <Modal id='whiteHouseDocsModal' show={courtAppealsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsModalIsOpen(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>U.S. Court of Appeals</h3></Modal.Header>
          <CourtAppeals />
        </Modal>

   
    </div>
  </Carousel.Item>

  <Carousel.Item interval={mobileCarouselInterval}>
    <div id='judicalConMobile'>
      <Col>
        
        <Row id = 'mobileFeedHeadingRow'><h5 id='feedName' onClick={() =>
                            openModal('Supreme Court Opinions',
                            <GenerateFeedOpinions url='&skip=0&schema=united_states_of_america&table=supreme_court_opinions&order_by=created_at' 
                            name='Supreme Court Opinions' branch='Judicial' topic='N/A' buttonName='Document' />)}>Supreme Court Opinions</h5></Row>
        <div id='courtOpinions'></div>
        {CreateFeedAPIOpinions('&skip=0&schema=united_states_of_america&table=supreme_court_opinions&order_by=created_at', 'courtOpinions', 
                            'Supreme Court Opinions', 'Judicial', 
                            'N/A', 'Document', GenerateFeedOpinions)}
        </Col>

       


    </div>
  </Carousel.Item>

  <Carousel.Item interval={mobileCarouselInterval}>
    <div id='judicalConMobile'>
      <Col>
       
          <Row id = 'mobileFeedHeadingRow'><h5 id='feedName' onClick={() =>
                            openModal('Supreme Court Arguments',
                            <GenerateFeedAudio url='&skip=0&schema=united_states_of_america&table=supreme_court_arguments&order_by=created_at' 
                            name='Supreme Court Arguments' branch='Judicial' topic='N/A' buttonName='Document' />)}>Supreme Court Arguments</h5></Row>


          <div id='courtArguments'></div>
          {CreateFeedAPIArguments('&skip=0&schema=united_states_of_america&table=supreme_court_arguments&order_by=created_at', 'courtArguments', 
                            'Supreme Court Arguments', 'Judicial', 
                            'N/A', 'Document', GenerateFeedAudio)}
        </Col>


      </div>
      </Carousel.Item>

      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='judicalConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/judicial' >View all Judicial news</Button>
</div>
  
  </Col>


      </div>
      </Carousel.Item>


</Carousel>

<h5 id='branchHeadingMobile' onClick={() => window.open('/us/defense', "_self")}> <ShieldShaded/> Defense<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>
  <Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'>
              <h5 id='feedName' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}>Department of Defense</h5></Row>




<div class='courtNewsMobile' id='defenseDepartment2'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=defense&order_by=created_at', 'defenseDepartment2', 'Department of Defense', 'Executive', 'Foreign Affairs', GenerateFeed)}

<Modal id='whiteHouseDocsModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Defense</h3></Modal.Header>
                  <GenerateFeed url='&skip=0&schema=united_states_of_america&table=defense&order_by=created_at' 
                            name='Department of Defense News' branch='Executive' topic='N/A' buttonName='' />
                </Modal>

          </Col>

      

     
      </div>
      </Carousel.Item>


      <Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName' onClick={() => setDefenseDocumentsModalIsOpen(true)}>Department of Defense Documents</h5></Row>


<div class='courtNewsMobile' id='frDefense'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=defense-department', 'frDefense', 'Department of Defense Documents', 'Executive', 'Foreign Affairs', FederalRegisterDefenseDepartment)}

</Col>
            
              <Modal id='whiteHouseDocsModal' show={defenseDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseDocumentsModalIsOpen(false)}>
                <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Defense Documents</h3></Modal.Header>
                <FederalRegisterDefenseDepartment />
              </Modal>



     
      </div>
      </Carousel.Item>


      <Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName' onClick={() => setHomelandDocsModalIsOpen(true)}>Department of Homeland Security Documents</h5></Row>
  <div class='courtNewsMobile' id='homelandDocs'></div>
                
                {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=homeland-security-department', 'homelandDocs', 'Homeland Security Documents', 'Executive', 'N/A', HomelandSecurityDocuments)}

 
    </Col>

    <Modal id='whiteHouseDocsModal' show={homelandDocsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandDocsModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Homeland Security Documents</h3></Modal.Header>
              <HomelandSecurityDocuments/>
            </Modal>


     
      </div>
      </Carousel.Item>


     

      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='judicalConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/defense' >View all Defense news</Button>
</div>
  
  </Col>


      </div>
      </Carousel.Item>

      </Carousel>

{/* Economy */}
<h5 id='branchHeadingMobile' onClick={() => window.open('/us/economy', "_self")}> <Coin></Coin>    Economy<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>
 


      <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName' onClick={() => setNumber1ModalIsOpen(true)}>SEC Press Releases</h5></Row>

          
            <div class='courtNewsMobile' id='secPR'></div>
            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=sec_updates&order_by=created_at', 'secPR', 
                            'SEC Press Releases', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}
                          <Modal id='whiteHouseDocsModal' show={number1ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                          centered onHide={() => setNumber1ModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>SEC Press Releases</h3></Modal.Header>
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=sec_updates&order_by=created_at' 
                            name='SEC Press Releases' branch='Executive' topic='Economy' buttonName='Document' />
                          </Modal>
          </Col>

      
     
      </div>
      </Carousel.Item>

      <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName'onClick={() => setNumber2ModalIsOpen(true)} >SEC Speeches and Statements</h5></Row>

          
            <div class='courtNewsMobile' id='sec'></div>
                      

            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=sec_speeches_statements&order_by=created_at', 'sec', 
                            'SEC Speeches and Statements', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}

                          <Modal id='whiteHouseDocsModal' show={number2ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                          centered onHide={() => setNumber2ModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>SEC Speeches and Statements</h3></Modal.Header>
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=sec_speeches_statements&order_by=created_at' 
                            name='SEC Speeches and Statementss' branch='Executive' topic='Economy' buttonName='Document' />
                          </Modal>
                   
          </Col>



     
      </div>
      </Carousel.Item>

      <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName' onClick={() => window.open('/us/departmentofcommerce', "_self")}>Department of Commerce</h5></Row>

          
            <div class='courtNewsMobile' id='commerceDepartment'></div>




{CreateFeedItem('https://www.commerce.gov/feeds/news', 'commerceDepartment', 'Department of Commerce', 'N/A', 'Economy', DepartmentOfCommerce)}
          </Col>

      

<Modal id='whiteHouseDocsModal' show={departmentOfCommerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfCommerceModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Commerce</h3></Modal.Header>
  <DepartmentOfCommerce />
</Modal>
     
      </div>
      </Carousel.Item>

      <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName' onClick={() => window.open('/us/departmentoftreasury', "_self")}>Department of Treasury</h5></Row>

          
            <div class='courtNewsMobile' id='treasuryDepartment'></div>
                      
            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=treasury&order_by=created_at', 
                                             'treasuryDepartment', 
                                               'Department of Treasury', 'Executive', 
                                               'N/A', 'Document', GenerateFeed)}
                        <Modal id='whiteHouseDocsModal' show={departmentOfTreasuryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfTreasuryModalIsOpen(false)}>
                          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Treasury</h3></Modal.Header>
                          <GenerateFeed url='&skip=0&schema=united_states_of_america&table=treasury&order_by=created_at' name='Department of Treasury' branch='Executive' topic='N/A' buttonName='Doc'
                />
                        </Modal>
     
          </Col>



     
      </div>
      </Carousel.Item>

      <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName'>Department of Labor</h5></Row>

          
            <div class='courtNewsMobile' id='laborDepartment'></div>




{CreateFeedAPI('https://www.dol.gov/rss/releases.xml', 'laborDepartment', 'Department of Labor', 'N/A', 'Economy', 'Document', DepartmentOfLabor)}
          </Col>

      
       

<Modal id='whiteHouseDocsModal' show={departmentOfLaborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfLaborModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Labor</h3></Modal.Header>
  <DepartmentOfLabor />
</Modal>
     
      </div>
      </Carousel.Item>

      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='judicalConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/economy' >View all Economy news</Button>
</div>
  
  </Col>


      </div>
      </Carousel.Item>

      </Carousel>

      <h5 id='branchHeadingMobile' onClick={() => window.open('/us/elections', "_self")}> <ReceiptCutoff></ReceiptCutoff>   Elections<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>
  <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName'>Federal Election Commission</h5></Row>



<div class='courtNewsMobile' id='fec'></div>


{CreateFeedItem('http://fetchrss.com/rss/5f8e367522b58768783d2392636be5a56ae84d1bcf0121d2.xml', 'fec', 'Federal Election Commission', 'N/A', 'Election', FederalElectionCommissionNews)}
          </Col>

      
 
<Modal id='departmentOfStateModal' show={fecModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFECModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Federal Election Commission</h3></Modal.Header>
  <FederalElectionCommissionNews />
      </Modal>
     
      </div>
      </Carousel.Item>


      <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName' onClick={() => setEACModalIsOpen(true)}>Election Assistance Commission</h5></Row>


     

<div class='courtNewsMobile' id='eac'></div>
{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=election_assistance_commission&order_by=created_at', 'eac', 
                            'Election Assistance Commission', 'Legislative', 
                            'N/A', 'Doc', GenerateFeed)}
                        <Modal id='departmentOfStateModal' show={eacModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setEACModalIsOpen(false)}>
                          <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Election Assistance Commission</h3></Modal.Header>
                          <GenerateFeed
                                url='&skip=0&schema=united_states_of_america&table=election_assistance_commission&order_by=created_at'
                                name='Election Assistance Commission News' branch='N/A' topic='Election' buttonName='Doc' />
                        </Modal>
</Col>

     
      </div>
      </Carousel.Item>


      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='judicalConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/elections' >View all Elections news</Button>
</div>
  
  </Col>


      </div>
      </Carousel.Item>

      </Carousel>

{/*Environment */}
      <h5 id='branchHeadingMobile' onClick={() => window.open('/us/environment', "_self")}> <Tree></Tree>    Environment<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>
  <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName'     onClick={() =>
                            openModal('National Weather Service Update',
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=national_weather_service_update&order_by=created_at' 
                            name='National Weather Service Update' branch='Executive' topic='Environment' buttonName='Doc' />)}>National Weather Service Update</h5></Row>

          
            <div class='courtNewsMobile' id='nws'></div>

            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=national_weather_service_update&order_by=created_at', 'nws', 
                            'National Weather Service Update', 'N/A', 
                            'Environment', 'Document', GenerateFeed)}

                            
          </Col>

     
      </div>
      </Carousel.Item>


      <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName' onClick={() => window.open('/us/epa', "_self")}>EPA <ChevronRight id='clickChevron'></ChevronRight></h5></Row>


                

            <div class='courtNewsMobile' id='epa'></div>
                      
            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=epa&order_by=created_at', 'epa', 
                            'EPA', 'N/A', 
                            'Environment', 'Document', GenerateFeed)}

                          <Modal id='whiteHouseDocsModal' show={epaModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                          centered onHide={() => setEPAModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>EPA</h3></Modal.Header>
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=epa&order_by=created_at' 
                            name='EPA' branch='N/A' topic='Environment' buttonName='Document' />
                          </Modal>
          </Col>

      </div>
      </Carousel.Item>

      <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName'>National Science Foundation Research News</h5></Row>


                

            <div class='courtNewsMobile' id='nsf'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=national_science_foundation&order_by=created_at', 'nsf', 'National Science Foundation Research News', 'Executive', 'Environment', 'Document', NationalScienceFoundation)}

          </Col>

      
        
<Modal id='departmentOfStateModal' show={nsfModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNSFModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>National Science Foundation Research News</h3></Modal.Header>
  <NationalScienceFoundation />
</Modal>
     
      </div>
      </Carousel.Item>


      <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName'>Department of Agriculture</h5></Row>


               

<div class='courtNewsMobile' id='agricultureDepartment'></div>
       
       {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=environment&order_by=created_at', 'agricultureDepartment', 'Department of Agriculture', 'Executive', 'Environment', 'Document', DepartmentOfAgriculture)}

          </Col>

      

<Modal id='whiteHouseDocsModal' show={departmentOfAgricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfAgricultureModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Agriculture</h3></Modal.Header>
  <DepartmentOfAgriculture />
</Modal>
     
      </div>
      </Carousel.Item>

      <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName'>House Committee on Agriculture</h5></Row>


            

<div class='courtNewsMobile' id='houseComitteeOnAgriculture'></div>


{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_agriculture_meeting_feed&order_by=created_at', 'houseComitteeOnAgriculture', 'House Committee On Agriculture', 'Legislative', 'Environment', HouseComitteeOnAgriculture )}
          </Col>

      
       
                         <Modal id='fullBillModal' show={houseComitteeOnAgricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseComitteeOnAgricultureModalIsOpen(false)}>
                           <Modal.Header id='billModalHeader' closeButton><h3>House Committee On Agriculture</h3></Modal.Header>
                           <HouseComitteeOnAgriculture />
</Modal>
     
      </div>
      </Carousel.Item>

      <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName' onClick={() => window.open('/us/departmentofhousingandurbandevelopment', "_self")}>Department of Housing and Urban Development</h5></Row>



<div class='courtNewsMobile' id='housingAndUrbanDevelopmentDepartment'></div>


{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=housing_and_urban_development&order_by=created_at', 'housingAndUrbanDevelopmentDepartment', 
                            'Department of Housing and Urban Development', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}



<Modal id='whiteHouseDocsModal' show={departmentOfHousingAndUrbanDevelopmentModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen(false)(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Housing and Urban Development</h3></Modal.Header>
  <GenerateFeed url='&skip=0&schema=united_states_of_america&table=housing_and_urban_development&order_by=created_at'
                              name='Department of Housing and Urban Development' branch='Executive' topic='N/A' buttonName='Document'/>
</Modal>
          </Col>

      


     
      </div>
      </Carousel.Item>


      

      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='judicalConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/environment' >View all Environment news</Button>
  </div>
  
  </Col>


      </div>
      </Carousel.Item>

      </Carousel>

{/*Foreign Affairs */}
      <h5 id='branchHeadingMobile' onClick={() => window.open('/us/foreignaffairs', "_self")}> <Globe></Globe>    Foreign Affairs<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>
  <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName' onClick={() => window.open('/us/departmentofstate', "_self")}>Department of State</h5></Row>


                
                  

<div class='courtNewsMobile' id='stateDepartment'></div>


{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=state&order_by=created_at', 
                  'stateDepartment', 'Department of State News', 'Executive', 
                            'N/A', '', GenerateFeed)}
        <Modal id='whiteHouseDocsModal' show={departmentOfStateModalIsOpen} 
        size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() =>setDepartmentOfStateModalIsOpen(false)}>
          <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>News</h3></Modal.Header>
          <GenerateFeed url='&skip=0&schema=united_states_of_america&table=department_of_state&order_by=created_at' 
                            name='Department of State News' branch='Executive' topic='N/A' buttonName='' />
        </Modal>
          </Col>

     
      </div>
      </Carousel.Item>

      <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName'>Department of Defense</h5></Row>


                

<div class='courtNewsMobile' id='defenseDepartment'></div>


{CreateFeedItem('https://www.defense.gov/DesktopModules/ArticleCS/RSS.ashx?max=10&ContentType=1&Site=945', 'defenseDepartment', 'Department Of Defense', 'Executive', 'Foreign Affairs', DepartmentOfDefense)}
          </Col>

      
      
<Modal id='fullBillModal' show={secPressModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
  <Modal.Header id='billModalHeader' closeButton><h3>Department of Defense</h3></Modal.Header>
  <DepartmentOfDefense />
</Modal>
     
      </div>
      </Carousel.Item>

      <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName'>House Committee On Intelligence</h5></Row>


             

<div class='courtNewsMobile' id='houseCommitteeOnIntelligence'></div>




{CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=IG00', 'houseCommitteeOnIntelligence', 'House Committee On Intelligence', 'N/A', 'Foreign Affairs', HouseCommitteeOnIntelligence)}
          </Col>

      

<Modal id='fullBillModal' show={houseCommitteeOnIntelligenceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnIntelligenceModalIsOpen(false)}>
  <Modal.Header id='billModalHeader' closeButton><h3>House Committee On Intelligence</h3></Modal.Header>
  <HouseCommitteeOnIntelligence/>
</Modal>
     
      </div>
      </Carousel.Item>

      <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName'>House Committee on Armed Services</h5></Row>


      

<div class='courtNewsMobile' id='houseComitteeOnArmedServices'></div>
                      
                      {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_armed_services_meeting_feed&order_by=created_at', 'houseComitteeOnArmedServices', 'House Committee on Armed Services', 'N/A', 'Foreign Affairs', HouseCommitteeOnArmedServices)};
          </Col>

      

<Modal id='whiteHouseDocsModal' show={houseCommitteeOnArmedServicesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseCommitteeOnOnArmedServicesModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>House Committee on Armed Services</h3></Modal.Header>
  <HouseCommitteeOnArmedServices />
</Modal>
     
      </div>
      </Carousel.Item>

      <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName'>United Nations News</h5></Row>




<div class='courtNewsMobile' id='unTopStories'></div>




{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=united_nations&order_by=created_at&filter_column=collection_name&filter_string=United%20Nations%20News', 'unTopStories', 'United Nations News', 'N/A', 'Foreign Affairs', UNTopStories)}

          </Col>

      
    
<Modal id='whiteHouseDocsModal' show={unTopStoriesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setUNTopStoriesModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>United Nations News</h3></Modal.Header>
  <UNTopStories />
</Modal>
     
      </div>
      </Carousel.Item>

    

      

      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='judicalConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/foreignaffairs' >View all Foreign Affairs news</Button>
</div>
  
  </Col>


      </div>
      </Carousel.Item>

      </Carousel>


{/* Health*/}
<h5 id='branchHeadingMobile' onClick={() => window.open('/us/health', "_self")}> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart-pulse" viewBox="0 0 16 16">
  <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053.918 3.995.78 5.323 1.508 7H.43c-2.128-5.697 4.165-8.83 7.394-5.857q.09.083.176.171a3 3 0 0 1 .176-.17c3.23-2.974 9.522.159 7.394 5.856h-1.078c.728-1.677.59-3.005.108-3.947C13.486.878 10.4.28 8.717 2.01zM2.212 10h1.315C4.593 11.183 6.05 12.458 8 13.795c1.949-1.337 3.407-2.612 4.473-3.795h1.315c-1.265 1.566-3.14 3.25-5.788 5-2.648-1.75-4.523-3.434-5.788-5"/>
  <path d="M10.464 3.314a.5.5 0 0 0-.945.049L7.921 8.956 6.464 5.314a.5.5 0 0 0-.88-.091L3.732 8H.5a.5.5 0 0 0 0 1H4a.5.5 0 0 0 .416-.223l1.473-2.209 1.647 4.118a.5.5 0 0 0 .945-.049l1.598-5.593 1.457 3.642A.5.5 0 0 0 12 9h3.5a.5.5 0 0 0 0-1h-3.162z"/>
</svg>  Health<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>
  <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName'>Department of Health and Human Services</h5></Row>



<div class='courtNewsMobile' id='healthAndHumanServicesDepartment'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=health_and_human_services&order_by=created_at', 'healthAndHumanServicesDepartment', 
                            'Department of Health and Human Services', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}




          </Col>

      

<Modal id='departmentOfStateModal' show={departmentOfHealthAndHumanServicesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHealthAndHumanServicesModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Health and Human Services</h3></Modal.Header>
  <DepartmentOfHealthAndHumanServices />
      </Modal>
     
      </div>
      </Carousel.Item>


      <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow' onClick={() => setCDCNewsroomModalIsOpen(true)}><h5 id='feedName'>CDC Newsroom</h5></Row>


     

<div class='courtNewsMobile' id='cdcNewsroom'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=cdc_newsroom&order_by=created_at', 'cdcNewsroom', 
                            'CDC Newsroom', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}

<Modal id='departmentOfStateModal' show={cdcNewsroomModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCDCNewsroomModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>CDC Newsroom</h3></Modal.Header>
  <GenerateFeed url='&skip=0&schema=united_states_of_america&table=cdc_newsroom&order_by=created_at'
                              name='CDC Newsroom' branch='N/A' topic='Health' buttonName='Document'/>
</Modal>
</Col>





      </div>
      </Carousel.Item>

      <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName' onClick={() => setOutbreaksModalIsOpen(true)}>CDC Outbreaks</h5></Row>


        

<div class='courtNewsMobile' id='outbreaks'></div>


{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=cdc_newsroom&order_by=created_at&filter_column=collection_name&filter_string=CDC%20Outbreaks', 'outbreaks', 'CDC Outbreaks', 'N/A', 'Health', Outbreaks)}
          </Col>

      

<Modal id='departmentOfStateModal' show={outbreaksModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setOutbreaksModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>CDC Outbreaks</h3></Modal.Header>
  <Outbreaks />
</Modal>
     
      </div>
      </Carousel.Item>

      <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName' onClick={() => setTravelNoticesModalIsOpen(true)}>CDC Travel Notices</h5></Row>



<div class='courtNewsMobile'  id='travelNotices'></div>


{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=cdc_newsroom&order_by=created_at&filter_column=collection_name&filter_string=CDC%20Travel%20Notices', 'travelNotices', 'CDC Travel Notices', 'N/A', 'Health', TravelNotices)}
          </Col>

      

<Modal id='departmentOfStateModal' show={travelNoticesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTravelNoticesModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>CDC Travel Notices</h3></Modal.Header>
  <TravelNotices />
</Modal>
     
      </div>
      </Carousel.Item>



      <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName' onClick={() => setFoodRecallsModalIsOpen(true)}>Food Recalls</h5></Row>




<div class='courtNewsMobile' id='foodRecalls'></div>


{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=food_recalls&order_by=created_at', 'foodRecalls', 
                            'Food Recalls', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}

<Modal id='departmentOfStateModal' show={foodRecallsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFoodRecallsModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Food Recalls</h3></Modal.Header>
  <GenerateFeed url='&skip=0&schema=united_states_of_america&table=food_recalls&order_by=created_at'
                              name='Food Recalls' branch='N/A' topic='Health' buttonName='Document'/>
</Modal>
          </Col>




      </div>
      </Carousel.Item>

      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='judicalConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg' href='health' >View all Health news</Button>
</div>
  
  </Col>


      </div>
      </Carousel.Item>

      </Carousel>

      <Link  id='pageLink' to="/us/immigration"><h5 id='branchHeadingMobile'>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-passport" viewBox="0 0 16 16">
  <path d="M8 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6M6 8a2 2 0 1 1 4 0 2 2 0 0 1-4 0m-.5 4a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1z"/>
  <path d="M3.232 1.776A1.5 1.5 0 0 0 2 3.252v10.95c0 .445.191.838.49 1.11.367.422.908.688 1.51.688h8a2 2 0 0 0 2-2V4a2 2 0 0 0-1-1.732v-.47A1.5 1.5 0 0 0 11.232.321l-8 1.454ZM4 3h8a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1"/>
</svg> Immigration<ChevronRight id='clickChevron'></ChevronRight></h5></Link>
<Carousel controls={false}>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<Row id = 'mobileFeedHeadingRow'><h5 id='feedName' onClick={() => openModal('ICE',
                              <GenerateFeed 
                              url='&schema=united_states_of_america&table=immigration&order_by=created_at&filter_column=agency&filter_string=U.S.%20Immigration%20and%20Customs%20Enforcement' 
                              name='ICE News' 
                              branch='N/A' 
                              topic='Immigration' 
                              buttonName='Document' 
                            />)}>News</h5></Row>


<div class='courtNewsMobile' id='ice'></div>


{CreateFeedAPI(
    '&schema=united_states_of_america&table=immigration&order_by=created_at&filter_column=agency&filter_string=U.S.%20Immigration%20and%20Customs%20Enforcement', 
    'ice', 
    'ICE News', 
    'N/A', 
    'Immigration', 
    'Document', 
    GenerateFeed
  )}
</Col>




</div>



</Carousel.Item>




    

      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='executiveConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Link  id='pageLink' to="/us/immigration"><Button id='viewFullLegislativePageButtonMobile' size='lg' >View all Immigration news</Button></Link>
</div>
  
  </Col>


      </div>
      </Carousel.Item>

      </Carousel>


      <h5 id='branchHeadingMobile' onClick={() => window.open('/us/infrastructure', "_self")}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
  <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
  <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3z"/>
</svg> Infrastructure<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>
  <Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'>
              <h5 id='feedName' onClick={() => setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen(true)}> Department of Housing and Urban Development News</h5></Row>




<div class='courtNewsMobile' id='housingAndUrbanDevelopmentDepartment2'></div>

{CreateFeedItem('https://www.hud.gov/sites/dfiles/Main/documents/hudrss.xml', 'housingAndUrbanDevelopmentDepartment2', 'Department of Housing and Urban Development', 'Executive', 'Infrastructure', DepartmentOfHousingAndUrbanDevelopment)}

<Modal id='whiteHouseDocsModal' show={departmentOfHousingAndUrbanDevelopmentModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen(false)}>
                <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Housing and Urban Development News</h3></Modal.Header>
                <DepartmentOfHousingAndUrbanDevelopment/>
              </Modal>

</Col>

      

     
      </div>
      </Carousel.Item>
      <Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName' onClick={() => setDepartmentOfHousingAndUrbanDevelopmentDocsModalIsOpen(true)}>Department of Housing and Urban Development Documents</h5></Row>


<div class='courtNewsMobile' id='housingDocs'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=housing-and-urban-development-department', 'housingDocs', 'Department of Housing and Urban Development Documents', 'Executive', 'Infrastructure', DepartmentOfHousingAndUrbanDevelopmentDocs)}

</Col>
            
<Modal id='whiteHouseDocsModal' show={departmentOfHousingAndUrbanDevelopmentDocsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHousingAndUrbanDevelopmentDocsModalIsOpen(false)}>
                <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Housing and Urban Development Documents</h3></Modal.Header>
                <DepartmentOfHousingAndUrbanDevelopmentDocs/>
              </Modal>
            
            



     
      </div>
      </Carousel.Item>
      <Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName' onClick={() => setEnforcemnetsModalIsOpen(true)}>Department of Transportation Documents</h5></Row>




<div class='courtNewsMobile' id='transDepartment'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=transportation-department', 'transDepartment', 'Department of Transporation', 'Executive', 'N/A', DepartmentOfTransportationDocs)}

<Modal id='whiteHouseDocsModal' show={enforcementsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setEnforcemnetsModalIsOpen(false)}>
                <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Transporation Documents</h3></Modal.Header>
                <DepartmentOfTransportationDocs/>
              </Modal>

</Col>


     
      </div>
      </Carousel.Item>
      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='judicalConMobile'>
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/infrastructure' >View all Infrastructure news</Button>
</div>
  </Col>
      </div>
      </Carousel.Item>
      </Carousel>

      <h5 id='branchHeadingMobile' onClick={() => window.open('/us/justice', "_self")}> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-arms-up" viewBox="0 0 16 16">
  <path d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/>
  <path d="m5.93 6.704-.846 8.451a.768.768 0 0 0 1.523.203l.81-4.865a.59.59 0 0 1 1.165 0l.81 4.865a.768.768 0 0 0 1.523-.203l-.845-8.451A1.5 1.5 0 0 1 10.5 5.5L13 2.284a.796.796 0 0 0-1.239-.998L9.634 3.84a.7.7 0 0 1-.33.235c-.23.074-.665.176-1.304.176-.64 0-1.074-.102-1.305-.176a.7.7 0 0 1-.329-.235L4.239 1.286a.796.796 0 0 0-1.24.998l2.5 3.216c.317.316.475.758.43 1.204Z"/>
</svg> Justice<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>

<Row id = 'mobileFeedHeadingRow'>
<h5 id='feedName' onClick={() => setTrendingBillsModalIsOpen(true)}>Department of Justice News</h5></Row>


<div class='courtNewsMobile' id='justiceNews'></div>


{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=justice&order_by=created_at', 'justiceNews', 
                      'Department of Justice News', 'Executive', 
                      'N/A', 'Document', GenerateFeed)}

<Modal id='whiteHouseDocsModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Justice News</h3></Modal.Header>
              <GenerateFeed
                              url='&skip=0&schema=united_states_of_america&table=justice&order_by=created_at'
                              name='Department of Justice News' branch='Executive' topic='N/A' buttonName='Document' />
            </Modal>
            </Col>
</div>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>

<Row id = 'mobileFeedHeadingRow'>
<h5 id='feedName' onClick={() => setAlchoholTabaccoModalIsOpen(true)}>Federal Bureau of Investigation</h5></Row>


<div class='courtNewsMobile' id='defFbi'></div>
{CreateFeedAPIDepartmentUS('&table_name=justice&agency=Federal%20Bureau%20of%20Investigation', 'defFbi',
'Federal Bureau of Investigation Documents', 'Executive',
'Justice', 'Doc', GenerateFeedDepartment)}

</Col>
            
              <Modal id='whiteHouseDocsModal' show={alchoholTabaccoModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAlchoholTabaccoModalIsOpen(false)}>
                <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Federal Bureau of Investigation</h3></Modal.Header>
                <GenerateFeedDepartment url='&table_name=justice&agency=Federal%20Bureau%20of%20Investigation'
      name='Federal Bureau of Investigation' branch='Executive' topic='Justice' buttonName='Doc' />
              </Modal>
</div>
</Carousel.Item>


     

<Carousel.Item interval={mobileCarouselInterval}>
    <div id='judicalConMobile'>
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/justice' >View all Justice news</Button>
</div>
  </Col>
      </div>
      </Carousel.Item>

      </Carousel>


      <h5 id='branchHeadingMobile' onClick={() => window.open('/us/science&tech', "_self")}> <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-rocket-takeoff" viewBox="0 0 16 16">
  <path d="M9.752 6.193c.599.6 1.73.437 2.528-.362.798-.799.96-1.932.362-2.531-.599-.6-1.73-.438-2.528.361-.798.8-.96 1.933-.362 2.532Z"/>
  <path d="M15.811 3.312c-.363 1.534-1.334 3.626-3.64 6.218l-.24 2.408a2.56 2.56 0 0 1-.732 1.526L8.817 15.85a.51.51 0 0 1-.867-.434l.27-1.899c.04-.28-.013-.593-.131-.956a9.42 9.42 0 0 0-.249-.657l-.082-.202c-.815-.197-1.578-.662-2.191-1.277-.614-.615-1.079-1.379-1.275-2.195l-.203-.083a9.556 9.556 0 0 0-.655-.248c-.363-.119-.675-.172-.955-.132l-1.896.27A.51.51 0 0 1 .15 7.17l2.382-2.386c.41-.41.947-.67 1.524-.734h.006l2.4-.238C9.005 1.55 11.087.582 12.623.208c.89-.217 1.59-.232 2.08-.188.244.023.435.06.57.093.067.017.12.033.16.045.184.06.279.13.351.295l.029.073a3.475 3.475 0 0 1 .157.721c.055.485.051 1.178-.159 2.065Zm-4.828 7.475.04-.04-.107 1.081a1.536 1.536 0 0 1-.44.913l-1.298 1.3.054-.38c.072-.506-.034-.993-.172-1.418a8.548 8.548 0 0 0-.164-.45c.738-.065 1.462-.38 2.087-1.006ZM5.205 5c-.625.626-.94 1.351-1.004 2.09a8.497 8.497 0 0 0-.45-.164c-.424-.138-.91-.244-1.416-.172l-.38.054 1.3-1.3c.245-.246.566-.401.91-.44l1.08-.107-.04.039Zm9.406-3.961c-.38-.034-.967-.027-1.746.163-1.558.38-3.917 1.496-6.937 4.521-.62.62-.799 1.34-.687 2.051.107.676.483 1.362 1.048 1.928.564.565 1.25.941 1.924 1.049.71.112 1.429-.067 2.048-.688 3.079-3.083 4.192-5.444 4.556-6.987.183-.771.18-1.345.138-1.713a2.835 2.835 0 0 0-.045-.283 3.078 3.078 0 0 0-.3-.041Z"/>
  <path d="M7.009 12.139a7.632 7.632 0 0 1-1.804-1.352A7.568 7.568 0 0 1 3.794 8.86c-1.102.992-1.965 5.054-1.839 5.18.125.126 3.936-.896 5.054-1.902Z"/>
</svg>  Science & Tech<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>
  <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'>
              <h5 id='feedName'>National Science Foundation</h5></Row>



              <div class='courtNewsMobile' id='nsf2'></div>

{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=national_science_foundation&order_by=created_at', 'nsf2', 'National Science Foundation Research News', 'Executive', 'Environment', 'Document', NationalScienceFoundation)}

          </Col>

      

     
      </div>
      </Carousel.Item>


      <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName'>NASA</h5></Row>


     

            <div id='nasa2'></div>
                        
                        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=nasa&order_by=created_at', 'nasa2', 'NASA', 'Executive', 'Science and Technology', 'Document', NationalAeronauticsAndSpaceAdministration)}
                
</Col>



     
      </div>
      </Carousel.Item>


      <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName'>NASA JPL News</h5></Row>


     

            <div id='nasaJPL'></div>
            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=nasa_jpl&order_by=created_at', 'nasaJPL', 'NASA JPL News', 'Executive', 'Science and Technology', 'Document', NationalAeronauticsAndSpaceAdministration)}
                

</Col>



     
      </div>
      </Carousel.Item>


      <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName'>NIST News</h5></Row>


     

            <div id='nist'></div>
                        
                        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=nist&order_by=created_at', 'nist', 'NIST News', 'N/A', 'Science and Technology', NISTNews)}


</Col>



     
      </div>
      </Carousel.Item>





      <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName'>Government Accountability Office Science and Technology</h5></Row>


     

            <div id='gaoScienceAndTech'></div>
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=government_accountability_office_reports&order_by=created_at', 'gaoScienceAndTech', 'Government Accountability Office Science and Technology', 'N/A', 'Science and Technology', GAOScienceAndTech)}


</Col>



     
      </div>
      </Carousel.Item>

      <Carousel.Item interval={mobileCarouselInterval}>

<div id='judicalConMobile'>

    <Col>
            <Row id = 'mobileFeedHeadingRow'><h5 id='feedName'>House Committee on Science, Space, and Technology</h5></Row>


     
            <div id='committeeOnScienceSpaceAndTech'></div>
                        
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_science_space_and_technology_meeting_feed&order_by=created_at', 'committeeOnScienceSpaceAndTech', 'House Committee on Science, Space, and Technology', 'N/A', 'Science and Technology', HouseCommitteeOnScienceSpaceAndTechnology)}




</Col>



     
      </div>
      </Carousel.Item>

      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='judicalConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/science&tech' >View all Science and Tech news</Button>
</div>
  
  </Col>


      </div>
      </Carousel.Item>

      </Carousel>

      <Link  id='pageLink' to="/us/spending"><h5 id='branchHeadingMobile'>
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#FFFFFF"><path d="M444-144v-80q-51-11-87.5-46T305-357l74-30q8 36 40.5 64.5T487-294q39 0 64-20t25-52q0-30-22.5-50T474-456q-78-28-114-61.5T324-604q0-50 32.5-86t87.5-47v-79h72v79q72 12 96.5 55t25.5 45l-70 29q-8-26-32-43t-53-17q-35 0-58 18t-23 44q0 26 25 44.5t93 41.5q70 23 102 60t32 94q0 57-37 96t-101 49v77h-72Z"/></svg> 
      Spending<ChevronRight id='clickChevron'></ChevronRight></h5></Link>
<Carousel controls={false}>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<Row id = 'mobileFeedHeadingRow'><h5 id='feedName' >Recent Awards</h5></Row>


<div class='courtNewsMobile' id='spendingAwards'></div>
                  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=recent_awards&order_by=start_date', 'spendingAwards', 
                            'Recent Awards', 'N/A', 
                            'Spending', 'Document', GenerateFeed)}
</Col>




</div>



</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<Row id = 'mobileFeedHeadingRow'><h5 id='feedName' >DOGE Contract Cancellations</h5></Row>


<div class='courtNewsMobile' id='dogeSavings'></div>
                  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=doge_savings&order_by=uploaded_on&filter_column=saving_type&filter_string=Contract', 'dogeSavings', 
                            'DOGE Contract Cancellations', 'N/A', 
                            'Spending', 'Document', GenerateFeed)}
</Col>




</div>



</Carousel.Item>



<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<Row id = 'mobileFeedHeadingRow'><h5 id='feedName' >DOGE Grant Cancellations</h5></Row>


<div class='courtNewsMobile' id='dogeSavings2'></div>
                  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=doge_savings&order_by=uploaded_on&filter_column=saving_type&filter_string=Grant', 'dogeSavings2', 
                            'DOGE Grant Cancellations', 'N/A', 
                            'Spending', 'Document', GenerateFeed)}
</Col>




</div>



</Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<Row id = 'mobileFeedHeadingRow'><h5 id='feedName' >DOGE Real Estate Cancellations</h5></Row>


<div class='courtNewsMobile' id='dogeSavings3'></div>
                  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=doge_savings&order_by=annual_lease&filter_column=saving_type&filter_string=Real%20Estate', 'dogeSavings3', 
                            'DOGE Real Estate Cancellations', 'N/A', 
                            'Spending', 'Document', GenerateFeed)}
</Col>




</div>



</Carousel.Item>





    

      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='executiveConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Link  id='pageLink' to="/us/spending"><Button id='viewFullLegislativePageButtonMobile' size='lg' >View all Spending news</Button></Link>
</div>
  
  </Col>


      </div>
      </Carousel.Item>

      </Carousel>

     


      <Link  id='pageLink' to="/us/regulations"><h5 id='branchHeadingMobile'>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-text" viewBox="0 0 16 16">
  <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"/>
  <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
</svg> Regulations<ChevronRight id='clickChevron'></ChevronRight></h5></Link>
<Carousel controls={false}>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<Row id = 'mobileFeedHeadingRow'><h5 id='feedName' onClick={() => openModal('News',
                              <GenerateFeed 
                              url='&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at' 
                              name='Regulations News' branch='N/A' topic='Regulations' buttonName='Document' />)}>News</h5></Row>


<div class='courtNewsMobile' id='regulations'></div>


{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at', 'regulations', 
                            'Regulations News', 'N/A', 
                            'Regulations', 'Document', GenerateFeed)}
</Col>




</div>



</Carousel.Item>



    

      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='executiveConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Link  id='pageLink' to="/us/regulations"><Button id='viewFullLegislativePageButtonMobile' size='lg' >View all Regulations news</Button></Link>
</div>
  
  </Col>


      </div>
      </Carousel.Item>

      </Carousel>





      <br></br>

      <h5 id='branchHeadingMobile2'><GeoAlt/>   States<ChevronRight id='clickChevron'></ChevronRight></h5>

      <div id='fullBillFeed'>
          <Container style={mobileStatesContainer}>

  <Col>
  

  <Row> 
<Button size='sm' id='statesModalButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/alabama', '_self')
                      }}> <Image id='stateFlagMobile'  src='alabamaFlag.png'></Image> Alabama</Button>
                        <Button size='sm' id='statesModalButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/alaska', '_self')
                      }}><Image id='stateFlagMobile'  src='alaskaFlag.png'></Image> Alaska</Button>
 
<Button size='sm' id='statesModalButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/arizona', '_self')
                      }}> <Image id='stateFlagMobile'  src='arizonaFlag.png'></Image> Arizona</Button>
 
 
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/arkansas', '_self')
                      }}><Image id='stateFlagMobile'  src='arkansasFlag.png'></Image> Arkansas</Button>
 </Row>
<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/california', '_self')
                      }}><Image id='stateFlagMobile'  src='californiaFlag.png'></Image> California</Button>
 
 
<Button size='sm' id='statesModalButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/colorado', '_self')
                      }}><Image id='stateFlagMobile'  src='coloradoFlag.png'></Image> Colorado</Button>
 
 
 
<Button size='sm' id='statesModalButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/connecticut', '_self')
                      }}><Image id='stateFlagMobile'  src='connecticutFlag.png'></Image> Connecticut</Button>
 
<Button size='sm' id='statesModalButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/delaware', '_self')
                      }}><Image id='stateFlagMobile'  src='delawareFlag.png'></Image> Delaware</Button>
 </Row>
<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/florida', '_self')
                      }}><Image id='stateFlagMobile'  src='floridaFlag.png'></Image> Florida</Button>
 
 
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/georgia', '_self')
                      }}><Image id='stateFlagMobile'  src='georgiaFlag.png'></Image> Georgia</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/hawaii', '_self')
                      }}><Image id='stateFlagMobile'  src='hawaiiFlag.png'></Image> Hawaii</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/idaho', '_self')
                      }}> <Image id='stateFlagMobile'  src='idahoFlag.png'></Image> Idaho</Button>
 </Row>
 <Row>
 
<Button size='sm' id='statesModalButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/illinois', '_self')
                      }}> <Image id='stateFlagMobile'  src='illinoisFlag.png'></Image> Illinois</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/indiana', '_self')
                      }}><Image id='stateFlagMobile'  src='indianaFlag.png'></Image> Indiana</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/iowa', '_self')
                      }}><Image id='stateFlagMobile'  src='iowaFlag.png'></Image> Iowa</Button>
 
 
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/kansas', '_self')
                      }}><Image id='stateFlagMobile'  src='kansasFlag.png'></Image> Kansas</Button>
 </Row>
<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/kentucky', '_self')
                      }}><Image id='stateFlagMobile'  src='kentuckyFlag.png'></Image> Kentucky</Button>
 
                     
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/louisiana', '_self')
                      }}><Image id='stateFlagMobile'  src='louisianaFlag.png'></Image> Louisiana</Button>
 
 
 
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/maine', '_self')
                      }}><Image id='stateFlagMobile'  src='maineFlag.png'></Image> Maine</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/maryland', '_self')
                      }}><Image id='stateFlagMobile'  src='marylandFlag.png'></Image> Maryland</Button>
</Row>
<Row> 
<Button size='sm' id='statesModalButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/massachusetts', '_self')
                      }}><Image id='stateFlagMobile'  src='massachusettsFlag.png'></Image> Massachusetts</Button>
 
 
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/michigan', '_self')
                      }}><Image id='stateFlagMobile'  src='michiganFlag.png'></Image> Michigan</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/minnesota', '_self')
                      }}><Image id='stateFlagMobile'  src='minnesotaFlag.png'></Image> Minnesota</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/mississippi', '_self')
                      }}><Image id='stateFlagMobile'  src='mississippiFlag.png'></Image> Mississippi</Button>
</Row>
<Row> 
 
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/missouri', '_self')
                      }}><Image id='stateFlagMobile'  src='missouriFlag.png'></Image> Missouri</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/montana', '_self')
                      }}><Image id='stateFlagMobile'  src='montanaFlag.png'></Image> Montana</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/nebraska', '_self')
                      }}><Image id='stateFlagMobile'  src='nebraskaFlag.png'></Image> Nebraska</Button>
 
 
                    
                      <Button size='sm' id='statesModalButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/nevada', '_self')
                      }}><Image id='stateFlagMobile'  src='nevadaFlag.png'></Image> Nevada</Button>
</Row>
<Row>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/newhampshire', '_self')
                      }}><Image id='stateFlagMobile'  src='newHampshireFlag.png'></Image> New Hampshire</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/newjersey', '_self')
                      }}><Image id='stateFlagMobile'  src='newJerseyFlag.png'></Image> New Jersey</Button>
 
 
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/newmexico', '_self')
                      }}><Image id='stateFlagMobile'  src='newMexicoFlag.png'></Image> New Mexico</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/newyork', '_self')
                      }}><Image id='stateFlagMobile'  src='newYorkFlag.png'></Image> New York</Button>
 </Row>
<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/northcarolina', '_self')
                      }}><Image id='stateFlagMobile'  src='northCarolinaFlag.png'></Image>North Carolina</Button>
 
 
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/northdakota', '_self')
                      }}><Image id='stateFlagMobile'  src='northDakotaFlag.png'></Image>North Dakota</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/ohio', '_self')
                      }}><Image id='stateFlagMobile'  src='ohioFlag.png'></Image>Ohio</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/oklahoma', '_self')
                      }}><Image id='stateFlagMobile'  src='oklahomaFlag.png'></Image> Oklahoma</Button>
 </Row>
 <Row>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/oregon', '_self')
                      }}><Image id='stateFlagMobile'  src='oregonFlag.png'></Image> Oregon</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/pennsylvania', '_self')
                      }}><Image id='stateFlagMobile'  src='pennsylvaniaFlag.png'></Image> Pennsylvania</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/rhodeisland', '_self')
                      }}><Image id='stateFlagMobile'  src='rhodeIslandFlag.png'></Image> Rhode Island</Button>
 
 
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/southcarolina', '_self')
                      }}><Image id='stateFlagMobile'  src='southCarolinaFlag.png'></Image> South Carolina</Button>
 </Row>
<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/southdakota', '_self')
                      }}><Image id='stateFlagMobile'  src='southDakotaFlag.png'></Image> South Dakota</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/tennessee', '_self')
                      }}><Image id='stateFlagMobile'  src='tennesseeFlag.png'></Image> Tennessee</Button>
 
 
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/texas', '_self')
                      }}><Image id='stateFlagMobile'  src='texasFlag.png'></Image> Texas</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/utah', '_self')
                      }}><Image id='stateFlagMobile'  src='utahFlag.png'></Image> Utah</Button>
 </Row>
<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/vermont', '_self')
                      }}><Image id='stateFlagMobile'  src='vermontFlag.png'></Image> Vermont</Button>
 
 
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/virginia', '_self')
                      }}><Image id='stateFlagMobile'  src='virginiaFlag.png'></Image> Virginia</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/washington', '_self')
                      }}><Image id='stateFlagMobile'  src='washingtonFlag.png'></Image> Washington</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/westvirginia', '_self')
                      }}><Image id='stateFlagMobile'  src='westVirginiaFlag.png'></Image> West Virginia</Button>
 </Row>
 <Row>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/wisconsin', '_self')
                      }}><Image id='stateFlagMobile'  src='wisconsinFlag.png'></Image>Wisconsin</Button>
 
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                        e.preventDefault();
                        window.open('/us/wyoming', '_self')
                      }}><Image id='stateFlagMobile'  src='wyomingFlag.png'></Image>Wyoming</Button>
</Row>
 
 
 
 
 


 
 
 
 
 </Col>
 </Container>

 </div>

      <br></br>
<div className="d-flex flex-column align-items-center text-center">  
      <h5 id='branchHeadingMobile2'><GeoAlt/> Change country <ChevronRight id='clickChevron'></ChevronRight></h5>
</div>
<Col className="d-flex flex-column align-items-center">
  <Button id='selectHomeCountry' onClick={() => window.open('/canada', "_self")}>
    🇨🇦 Canada
  </Button>

  <Button id='selectHomeCountry' onClick={() => window.open('/mexico', "_self")}>
    🇲🇽 Mexico
  </Button>

  <Button id='selectHomeCountry' onClick={() => window.open('/uk', "_self")}>
    🇬🇧 United Kingdom
  </Button>

  <Button id='selectHomeCountry' onClick={() => window.open('/us', "_self")}>
    🇺🇸 United States
  </Button>
</Col>


                     <br></br>

                     <br></br>
                     <h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>
  

<Col>


            </Col>

            <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>

            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>


{/* 
<Offcanvas show={showOffCanvas} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          Some text as placeholder. In real life you can have the elements you
          have chosen. Like, text, images, lists, etc.
        </Offcanvas.Body>
      </Offcanvas>
      */}
</div>
</>
)

                }}
export default HomeMobile;
